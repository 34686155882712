import React, { createRef, useEffect, useState } from "react";
import "./NecklacePage.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";

const NecklacePage = () => {
  // const data = [
  //   { name: "Yellow Gold Necklaces", images: "/assets/image 457.png" },
  //   { name: "Bangle Necklaces", images: "/assets/image 461.png" },
  //   { name: "Rose Gold Necklaces", images: "/assets/image 459.png" },
  //   { name: "White Gold Necklaces", images: "/assets/image 460.png" },
  // ];
  const navigate=useNavigate();
  const [data, setData]=useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/11");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);


  const alignData = () => {
    return dataToDisplay2?.subsection?.map((item) => {
      return (
        <div className="nl2Div">
          <img alt="images" src={item.image} className="nl2Img" />
          <div className="nl2Text">{item.title}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleShopRing=()=>{
    sessionStorage.setItem("productPage", "pendant");
    navigate("/all-product");
  };

  return (
    <>
      <Hidden smDown>
        <div className="erContainer1">
          <img
            alt="images"
            src={dataToDisplay1?.static_image}
            loading="lazy"
            className="bpMainImg"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay1?.title}</div>
            <div className="erMainText">
              {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <div>
              <button className="erBtn1" style={{cursor:"pointer"}} onClick={()=>{handleShopRing()}}>Shop Necklaces</button>
            </div>
            <div>
              <button className="erBtn2" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-necklace")}}>Design your own necklaces</button>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="erContainer1">
          <img
            alt="images"
            src={dataToDisplay1?.mobile_image}
            loading="lazy"
            className="bpMainImg"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay1?.title}</div>
            <div className="erMainText">
            {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <div>
              <button className="erBtn1" style={{cursor:"pointer"}} onClick={()=>{handleShopRing()}}>Shop Necklaces</button>
            </div>
            <div>
              <button className="erBtn2" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-necklace")}}>Design your own necklaces</button>
            </div>
          </div>
        </div>
      </Hidden>

      <div className="nlContainer2">
        <div className="nl2MainHead">{dataToDisplay2?.title}</div>
        <Hidden smDown>
          <div className="nl2AlignData">{alignData()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {alignData()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default NecklacePage;
