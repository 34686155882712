import React, { createRef } from "react";
import "./WeddingRings5.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WeddingRings5 = (props) => {
  const { dataToDisplay7 } = props;
  // const rings = [
  //   { name: "White Gold", images: "/assets/image 177.svg" },
  //   { name: "Yellow Gold", images: "/assets/image 178.svg" },
  //   { name: "Rose Gold", images: "/assets/image 179.svg" },
  //   { name: "Platinum", images: "/assets/image 180.svg" },
  // ];

  const getRings = () => {
    return dataToDisplay7?.subsection.map((rings, index) => {
      return (
        <div className="wr5RingDivSize" key={index}>
          <img alt="images" src={rings.image} className="wr5Img" />
          <div className="wr5RingName">{rings.title}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="wr5Container">
        <div className="wr5heading">{dataToDisplay7?.title}</div>
        <Hidden smDown>
          <div className="wr5AlignRingData">{getRings()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getRings()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default WeddingRings5;
