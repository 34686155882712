import React, { useEffect, useState } from "react";
import "./DiamondPage1.css";
import { useNavigate } from "react-router-dom";

const DiamondPage1 = (props) => {
  const { dataToDisplay1 } = props;
  const [compval, setCompval] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const handleShopBtn=()=>{
    sessionStorage.removeItem("msn");
    sessionStorage.removeItem("prtid");
    sessionStorage.setItem("cPage", "diamond-page");
    navigate("/select-diamond");
  }
  return (
    <>
      {compval ? (
        <div className="dp1Div">
          <img
            alt="images"
            src={dataToDisplay1?.image}
            loading="lazy"
            className="dp1Img"
          />
          <div className="dp1Div1">
            <h1 className="dp1Head">
              {dataToDisplay1?.title.substring(0, 13)}
            </h1>
            <p className="dp1Text">
              {dataToDisplay1?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="dp1Btn" onClick={() => { handleShopBtn() }}>Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="dp1Div">
          <img
            alt="images"
            src={dataToDisplay1?.mobile_image}
            loading="lazy"
            className="dp1Img"
          />
          <div className="dp1Div1">
            <h1 className="dp1Head">{dataToDisplay1?.title}</h1>
            <p className="dp1Text">
              {dataToDisplay1?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="dp1Btn" onClick={() => { navigate("/select-diamond") }}>Shop Now</button>
          </div>
        </div>
      )}
    </>
  );
};

export default DiamondPage1;
