import React from "react";
import "./SelectSetting4.css";

const SelectSetting4 = (props) => {
  const { item, handleCustomrAttr, selectedAttributes } = props;

  const getDiamons = () => {
    return item.attribute_value_data.map((k, index) => {
      return (
        <div
          onClick={() =>
            handleCustomrAttr(item.custome_attribute_id, k.cust_attr_value)
          }
          className="selectSettingDiv123"
        >
          <img alt="images" src={k.attribute_image} width="80%" />

          <div
            style={{
              cursor: "pointer",
              color: "#30373e",
              fontWeight: selectedAttributes.some(
                (attr) =>
                  attr.attributeId === item.custome_attribute_id &&
                  attr.value === k.cust_attr_value
              )
                ? "bold"
                : "normal",
            }}
            // className="selectsetting4Attrvalue"
          >
            {k.cust_attr_value}
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <div className="selectSetting4Con">
        <div className="selectSetting4heading">{item.attribute_name}</div>
        <div className="selectSettingAlignDataABC">{getDiamons()}</div>
      </div>
    </>
  );
};

export default SelectSetting4;
