import React, { createRef } from "react";
import "./Rings1.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Rings1 = () => {
  const style = [
    {
      imagess: "/assets/image 400.png",
      name: "Stacking rings",
    },
    {
      imagess: "/assets/image 399.png",
      name: "Eternity rings",
    },
    {
      imagess: "/assets/image 403.png",
      name: "Anniversary rings",
    },
    {
      imagess: "/assets/image 402.png",
      name: "Fashion rings",
    },
    {
      imagess: "/assets/image 405.png",
      name: "Promise rings",
    },
    {
      imagess: "/assets/image 407.png",
      name: "Matching rings",
    },
    {
      imagess: "/assets/image 404.png",
      name: "Diamonds rings",
    },
    {
      imagess: "/assets/a1a87c63859392cb7079b86489d74a9e 1.png",
      name: "Men's rings",
    },
  ];

  const getStyle = () => {
    return style.map((item) => {
      return (
        <div className="ring1Div">
          <img alt="images" src={item.imagess} className="ring1Img" />
          <div className="rings1ImageName">{item.name}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="rings1Container">
        <div className="rings1Heading">Shop by Style</div>
        <Hidden smDown>
          <div className="alignRings1">{getStyle()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider className="alignRings1" ref={mySlider} {...settings}>
            {getStyle()}
          </Slider>
        </Hidden>
      </div>
      <Hidden smDown>
        <div className="rings1ContainerAb">
          <img
            alt="images"
            src="/assets/ring2.webp"
            loading="lazy"
            width="100%"
          />
          <div className="rings1ContainerAb1">
            <div className="rings1ContainerAb1Head">
              Design your own Diamond Ring
            </div>
            <div className="rings1ContainerAb1Text">
              Craft your unique diamond ring by starting with either the ring
              setting or the center stone selection. The decision is entirely in
              your hands.
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="rings1ContainerAb">
          <img
            alt="images"
            src="/assets/Group 1000002160.png"
            loading="lazy"
            width="100%"
          />
          <div className="rings1ContainerAb1">
            <div className="rings1ContainerAb1Head">
              Design your own Diamond Ring
            </div>
            <div className="rings1ContainerAb1Text">
              Craft your unique diamond ring by starting with either the ring
              setting or the center stone selection. The decision is entirely in
              your hands.
            </div>
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default Rings1;
