import React from "react";
import "./BuyingGuidePage2.css";

const BuyingGuidePage2 = () => {
  return (
    <>
      <div className="bg2Container">
        <div className="bg2Head">Types of Diamonds</div>
        <div className="bg2Container1">
          <div className="bg2Container-div">
            <div className="bg2MHead">Natural Diamonds</div>
            <div className="bg2MText">
              Formed naturally in the Earth, these diamonds are precious and
              rare, created through intense heat and pressure over millions of
              years.
            </div>
            <div className="bg2MHead">Lab-Grown Diamonds</div>
            <div className="bg2MText">
              Man-made in controlled environments, these diamonds share the same
              properties as natural ones, offering an ethical and sustainable
              alternative.
            </div>
            <div className="bg2MHead">DNA Diamonds</div>
            <div className="bg2MText">
              Creating DNA diamonds using your loved ones' hair, love letters,
              or any carbon-extractable item, these diamonds hold sentimental
              value, symbolizing a unique bond and essence.
            </div>
          </div>
          <div className="bg2Container-div">
            <img alt="images " src="/assets/image 387.png" className="bg2Img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default BuyingGuidePage2;
