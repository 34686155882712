import React from "react";
import "./OrderDetailsPage.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const OrderDetailsPage = (props) => {
  const { ordersDetails } = props;
  return (
    <>
      <div className="mop">
        <div className="mopH">My Account</div>
        <ArrowForwardIosIcon />
        <div className="mopH">My Orders</div>
        <ArrowForwardIosIcon />
        <div className="mopA">Details</div>
      </div>
      <div className="odpContainer">
        <h1 className="odpH">Order Details</h1>
        <h4 className="odpH2">
          {ordersDetails?.order_status === 0
            ? "Order Placed"
            : ordersDetails?.order_status === 1
            ? "Order Accepted"
            : ordersDetails?.order_status === 2
            ? "Order Rejected"
            : ordersDetails?.order_status === 3
            ? "Order Ready To Ship"
            : ordersDetails?.order_status === 4
            ? "Order Delivered"
            : ordersDetails?.order_status === 5
            ? "Order Return Request"
            : ordersDetails?.order_status === 6
            ? "Order Return Completed"
            : ordersDetails?.order_status === 7
            ? "Order Shipped"
            : ordersDetails?.order_status === 8
            ? "Order Out For Delivery"
            : ordersDetails?.order_status === 9
            ? "Order Undelivered"
            : ordersDetails?.order_status === 10
            ? "Order Picked up"
            : ""}
        </h4>
      </div>
      <div className="odpDivider"></div>
      <div className="odpmopOrders">
        <div className="odpmopOrders1">
          <div className="odpmopOrderH">
            <div>Order Placed</div>
            <div>{ordersDetails.created_at}</div>
          </div>
          <div className="odpmopOrderH">
            <div>Total</div>
            <div>{ordersDetails.grand_total_price}</div>
          </div>
          <div className="odpmopOrderH">
            <div>Ship To</div>
            <div>{ordersDetails.cust_Name}</div>
          </div>
        </div>
        <div className="odpmopOrderH">
          <div>Order</div>
          <div>#4567</div>
        </div>
      </div>
      <div className="odpmopAlignP">
        <div className="mopAlignP1">
          <img
            alt="images"
            src={ordersDetails.imgurl?.[0]}
            width="15%"
          />
          <div className="mopAlignP2">
            <div className="mopName">{ordersDetails.p_name}</div>
            <div className="mopName2">Qty: {ordersDetails.quantity}</div>
          </div>
        </div>
        <div className="odpacBtndiv">
          {/* <button className="odpmopView1">Cancel Order</button>
          <button className="odpmopView1">Return or Replace Items</button> */}
          {/* <button className="odpmopView1">Download Invoice</button> */}
          <button className="odpmopView1">Write a product Review</button>
        </div>
      </div>
      <div className="odpDivider"></div>

      <h1 className="odpH">Delivery Address</h1>
      <div className="odpAddress">
        <div>
          <p className="odpAddressCName">{ordersDetails.cust_Name}</p>
          <div className="odpAddressAdrs">
            <span>{ordersDetails.cust_address} </span>
            <span>{ordersDetails.custLandMark} </span>
            <span>{ordersDetails.custCity}</span>
          </div>
          <div className="odpAddressAdrs">
            <span>{ordersDetails.custState} </span>
            <span>{ordersDetails.custPincode}</span>
          </div>
          <p className="odpAddressAdrs">{ordersDetails.order_phone}</p>
        </div>
        <div className="odpAddressType">home</div>
      </div>

      <div className="odpDivider"></div>

      <h1 className="odpH">Order Status</h1>

      <div className="odpOrderStatus">
        <div className="odpOrderCircleContainer">
          <div className="odpOrderCircleLine">
            <div
              className="odpOrderCircle"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 0 ? "#A3E5A6" : "",
              }}></div>
            <div
              className="odpOrderLine"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 3 ? "#A3E5A6" : "",
              }}></div>
          </div>
          <div className="odpOrderStatusText">Order Placed</div>
        </div>

        <div className="odpOrderCircleContainer">
          <div className="odpOrderCircleLine">
            <div
              className="odpOrderCircle"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 3 ? "#A3E5A6" : "",
              }}></div>
            <div
              className="odpOrderLine"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 7 ? "#A3E5A6" : "",
              }}></div>
          </div>
          <div className="odpOrderStatusText">Ready for Ship</div>
        </div>

        <div className="odpOrderCircleContainer">
          <div className="odpOrderCircleLine">
            <div
              className="odpOrderCircle"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 7 ? "#A3E5A6" : "",
              }}></div>
            <div
              className="odpOrderLine"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 8 ? "#A3E5A6" : "",
              }}></div>
          </div>
          <div className="odpOrderStatusText">Shipped</div>
        </div>

        <div className="odpOrderCircleContainer">
          <div className="odpOrderCircleLine">
            <div
              className="odpOrderCircle"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 8 ? "#A3E5A6" : "",
              }}></div>
            <div
              className="odpOrderLine"
              style={{
                backgroundColor:
                  ordersDetails.order_status === 4 ? "#A3E5A6" : "",
              }}></div>
          </div>
          <div className="odpOrderStatusText">Out for Delivery</div>
        </div>

        <div className="odpOrderCircleContainer">
          <div className="odpOrderCircleLine">
            <div
              className="odpOrderCircle"
              style={{
                marginLeft: "-1px",
                marginTop: "10px",
                backgroundColor:
                  ordersDetails.order_status === 4 ? "#A3E5A6" : "",
              }}></div>
          </div>
          <div
            className="odpOrderStatusText"
            style={{ marginTop: "10px" }}>
            Delivered
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderDetailsPage;
