import React from "react";

const SelectSetting31 = () => {
  
  return (
    <>
      <div className="selectSetting2Container1">
        <div className="selectSetting2Container1Sub">
          <div
            className="selectSetting2BoxesU"
          >
            <div
              className="selectSetting2NumU"
            >
              1
            </div>
            <div
              className="selectSetting2TextU"
            >
              Select Setting
            </div>
          </div>
          <div
            className="selectSetting2BoxesU"
          >
            <div
              className="selectSetting2NumU"
            >
              2
            </div>
            <div
              className="selectSetting2TextU"
              
            >
              Choose Diamond
            </div>
          </div>
          <div
            className="selectSetting2BoxesA"
          >
            <div
              className="selectSetting2NumA"
            >
              3
            </div>
            <div
              className="selectSetting2TextA"
              
            >
              Complete Ring
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSetting31;
