import React, { useEffect, useState, createRef } from "react";
import "./DnaPage3.css";
import { Hidden } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const DnaPage3 = () => {
  const [compval, setCompval] = useState(false);

  const navigate=useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      {compval ? (
        <div className="au3Div">
          <img
            alt="images"
            width="100%"
            src="/assets/dn2.webp"
            loading="lazy"
          />
          <div className="au3Div1">
            <h2 className="au1Head">The Gift of a Lifetime</h2>
            <p className="au1Text">
              Surprise someone special with the gift of a DNA Diamond, and watch
              their eyes light up with the Knowledge that a part of their loved
              one will always be with them. It’s a gift that transcends time.
            </p>
            <button className="au1Btn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="au2Div">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 211001951.png"
            loading="lazy"
          />
          <div className="au2Div1">
            <h2 className="au2Head">The Gift of a Lifetime</h2>
            <p className="au2Text">
              Surprise someone special with the gift of a DNA Diamond, and watch
              their eyes light up with the Knowledge that a part of their loved
              one will always be with them. It’s a gift that transcends time.
            </p>
            <button className="au2Btn">Shop Now</button>
          </div>
        </div>
      )}

      <Hidden smDown>
        <div className="dn3Container">
          <div className="dn3head">DNA Diamond Pricing</div>
          <div className="alignD3ImgData">
            <img className="dn3Imgs" src="/assets/image 363.svg" alt="Image1" />
            <img className="dn3Imgs" src="/assets/image 364.svg" alt="Image2" />
            <img className="dn3Imgs" src="/assets/image 365.svg" alt="Image3" />
            <img
              src="/assets/Group 645.svg"
              alt="Image4"
              style={{ width: "15%" }}
            />
            <img className="dn3Imgs" src="/assets/image 366.svg" alt="Image5" />
            <img className="dn3Imgs" src="/assets/image 367.svg" alt="Image6" />
            <img className="dn3Imgs" src="/assets/image 368.svg" alt="Image7" />
          </div>
        </div>
      </Hidden>

      <Hidden smUp>
        <div className="dn3Container">
          <div className="dn3head">DNA Diamond Pricing</div>
          <Slider sx={{width:'100%'}} ref={mySlider} {...settings}>
            <img className="dn3Imgs" src="/assets/image 363.svg" alt="Image1" />
            <img className="dn3Imgs" src="/assets/image 364.svg" alt="Image2" />
            <img className="dn3Imgs" src="/assets/image 365.svg" alt="Image3" />
            <img
              src="/assets/Group 645.svg"
              alt="Image4"
              style={{ width: "50px" }}
            />
            <img className="dn3Imgs" src="/assets/image 366.svg" alt="Image5" />
            <img className="dn3Imgs" src="/assets/image 367.svg" alt="Image6" />
            <img className="dn3Imgs" src="/assets/image 368.svg" alt="Image7" />
          </Slider>
        </div>
      </Hidden>

      <Hidden smDown>
        <div className="dn3Container1">
          <div className="dna3Headabc">
            <ArrowBackIcon
              fontSize="small"
              sx={{
                padding: "8px",
                backgroundColor: "#F9E5E9",
                borderRadius: "100%",
              }}
            />
            <div className="dna3Head">Colorless Diamond</div>
            <ArrowForwardIcon
              fontSize="small"
              sx={{
                padding: "8px",
                backgroundColor: "#F9E5E9",
                borderRadius: "100%",
              }}
            />
          </div>
          <table>
            <tr>
              <th style={{ textAlign: "right" }}>
                <img alt="images" src="/assets/diamond1.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond2.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond3.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond4.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond5.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond6.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond7.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond8.svg" />
              </th>
              <th>
                <img alt="images" src="/assets/diamond9.svg" />
              </th>
            </tr>
            <tr>
              <td className="dn3Align">
                <div className="dn3Headdd">Carat</div>
                <div className="dn3Span">0.25</div>
              </td>
              <td className="dn3Span">0.5</td>
              <td className="dn3Span">0.75</td>
              <td className="dn3Span">1</td>
              <td className="dn3Span">1.25</td>
              <td className="dn3Span">1.5</td>
              <td className="dn3Span">1.75</td>
              <td className="dn3Span">2</td>
              <td className="dn3Span">3</td>
            </tr>
            <tr>
              <td className="dn3Align">
                <div className="dn3Headdd">Prices</div>
                <div className="dn3Span">$2800</div>
              </td>
              <td className="dn3Span">$4550</td>
              <td className="dn3Span">$5600</td>
              <td className="dn3Span">$7000</td>
              <td className="dn3Span">$8750</td>
              <td className="dn3Span">$10500</td>
              <td className="dn3Span">$12250</td>
              <td className="dn3Span">$14000</td>
              <td className="dn3Span">$14000</td>
            </tr>
          </table>
          <div className="dn3Btn">
            <button className="dn3Btn1" onClick={()=>{navigate("/select-diamond1")}}>Select Diamond</button>
          </div>
        </div>
      </Hidden>

      <Hidden smUp>
        <div className="dn3Container1">
          <div className="dna3Headabc">
            <ArrowBackIcon
              fontSize="small"
              sx={{
                padding: "8px",
                backgroundColor: "#F9E5E9",
                borderRadius: "100%",
              }}
            />
            <div className="dna3Head">Colorless Diamond</div>
            <ArrowForwardIcon
              fontSize="small"
              sx={{
                padding: "8px",
                backgroundColor: "#F9E5E9",
                borderRadius: "100%",
              }}
            />
          </div>

          <div className="dn3TableContainerMain">
            <div className="dn3TableContainer">
              <div>Carat</div>
              <div>Price</div>
            </div>
            <div className="dn3TableContainer1">
              <div className="dn3TableContainerDiv">0.25</div>

              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond1.svg" width="24px" />
              </div>
              <div className="dn3TableContainerDiv">$2800</div>
            </div>
            <div className="dn3TableContainer2">
              <div className="dn3TableContainerDiv">0.5</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond2.svg" width="30px" />
              </div>
              <div className="dn3TableContainerDiv">$4550</div>
            </div>
            <div className="dn3TableContainer1">
              <div className="dn3TableContainerDiv">0.75</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond3.svg" width="36px" />
              </div>
              <div className="dn3TableContainerDiv">$5600</div>
            </div>
            <div className="dn3TableContainer2">
              <div className="dn3TableContainerDiv">1</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond4.svg" width="42px" />
              </div>
              <div className="dn3TableContainerDiv">$7000</div>
            </div>
            <div className="dn3TableContainer1">
              <div className="dn3TableContainerDiv">1.25</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond5.svg" width="48px" />
              </div>
              <div className="dn3TableContainerDiv">$8750</div>
            </div>
            <div className="dn3TableContainer2">
              <div className="dn3TableContainerDiv">1.5</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond6.svg" width="54px" />
              </div>
              <div className="dn3TableContainerDiv">$10500</div>
            </div>
            <div className="dn3TableContainer1">
              <div className="dn3TableContainerDiv">1.75</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond7.svg" width="60px" />
              </div>
              <div className="dn3TableContainerDiv">$12250</div>
            </div>
            <div className="dn3TableContainer2">
              <div className="dn3TableContainerDiv">2</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond8.svg" width="66px" />
              </div>
              <div className="dn3TableContainerDiv">$14000</div>
            </div>
            <div className="dn3TableContainer1">
              <div className="dn3TableContainerDiv">3</div>
              <div className="dn3TableContainerDiv">
                <img alt="images" src="/assets/diamond9.svg" width="72px" />
              </div>
              <div className="dn3TableContainerDiv">$14000</div>
            </div>
          </div>

          <div className="dn3Btn">
            <button className="dn3Btn1">Select Diamond</button>
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default DnaPage3;
