import React from "react";
import "./DiamondCategory.css";
// import { useNavigate } from "react-router-dom";

const DiamondCategory = (props) => {
  // const navigate = useNavigate();
  const { handleAddRing, diamonds, index } = props;

  // const [videoSelected, setVideoSelected] = useState(false);

  // const handleVideo=()=>{
  //   setVideoSelected(!videoSelected);
  // }

  // console.log(diamonds.diamond?.video);

  return (
    <>
      <div key={index} className="diamondCategoryPoducts">
        {!diamonds?.diamond?.image===null ? (
          <img
            alt="images"
            src={diamonds?.diamond?.image?.[0]}
            className="diamondCategoryPoductImg"
          />
        ) : (
          // <video src={diamonds?.diamond?.video} autoPlay loop className="diamondCategoryPoductImg" />
          <iframe src={diamonds?.diamond?.video?diamonds?.diamond?.video.replace("/500/500", "/300/300"):""} title={index} className="diamondCategoryPoductImg" style={{border:"none", overflow:"hidden", objectFit:"cover"}}></iframe>
        )}
        <div className="diamondCategoryAlignDivs">
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Shape</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.shape}</div>
          </div>
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Color</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.color}</div>
          </div>
        </div>
        <div className="diamondCategoryAlignDivs">
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Carat</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.carats}</div>
          </div>
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Clarity</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.clarity}</div>
          </div>
        </div>
        <div className="diamondCategoryAlignDivs">
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Cut</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.cut}</div>
          </div>
          <div className="diamondCategoryAlignDivsData">
            <div className="diamondCategoryhead">Cert.</div>
            <div className="diamondCategoryvalues">{diamonds?.diamond?.certificate?.lab}</div>
          </div>
        </div>
        <div className="diamondCategoryAlignDivs1">
          <div className="diamondCategoryvalues1">USD {diamonds?.price}</div>
          {/* <div style={{ cursor: "pointer" }} onClick={() => { handleVideo() }}>
            {videoSelected ? "Hide" : "View 360°"}
          </div> */}
        </div>

        <button className="diamondCategoryBtn" onClick={()=>{handleAddRing(diamonds?.diamond?.id)}} style={{cursor:"pointer"}}>
          Add To Ring
        </button>
      </div>
    </>
  );
};

export default DiamondCategory;
