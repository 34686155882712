import React, { useEffect, useState, createRef } from "react";
import "./Engagement2.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const Engagement2 = (props) => {
  const { dataToDisplay3, dataToDisplay4 } = props;
  const [compval, setCompval] = useState(false);

  const navigate=useNavigate();

  const ringsData = () => {
    return dataToDisplay3?.subsection?.map((item, index) => {
      return (
        <div key={index} className="engagement2ImagesDiv">
          <img
            alt="images"
            src={item.mobile_image}
            className="engagement2img"
          />
          <div className="engagement2Texts">{item.title}</div>
        </div>
      );
    });
  };

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="engagement2Container">
        <div className="engagement2heading">{dataToDisplay3?.title}</div>
        <Hidden smDown>
          <div className="engagement2Images">{ringsData()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {ringsData()}
          </Slider>
        </Hidden>
      </div>

      {compval ? (
        <div className="eg2Div">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay4?.static_image}
            loading="lazy"
          />
          <div className="eg2Div12">
            <h2 className="eg2Head">{dataToDisplay4?.title}</h2>
            <p className="eg2Text">{dataToDisplay4?.content.replace(/<\/?p>/g, '')}</p>
            <button className="eg2Btn" onClick={()=>{navigate("/buying-guide")}}>Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="eg2Div">
          <img alt="images" width="100%" src={dataToDisplay4?.mobile_image} />
          <div className="eg2Div12">
            <h2 className="eg2Head">{dataToDisplay4?.title}</h2>
            <p className="eg2Text">{dataToDisplay4?.content.replace(/<\/?p>/g, '')}</p>
            <button className="homeBtn" onClick={()=>{navigate("/buying-guide")}}>Shop Now</button>
          </div>
        </div>
      )}
    </>
  );
};

export default Engagement2;
