import React, { useEffect, useState } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import SelectSetting31 from "../../components/SelectSettingPage/SelectSetting31/SelectSetting31";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
// import ShoppingCard from "../../components/ShoppingBagPage/ShoppingCard";
import { getData, getDiamondData } from "../HTTPConfig/HTTPConfig";
import "./CompleteRing.css";
import diamondIcon from "./image 170.png";

const CompleteRing = () => {
  const location = useLocation();
  const { state } = location;

  // Now you can access the state data like this:
  const { diamond_id, prtid, msn } = state;

  const navigate = useNavigate();

  // Example usage of state data
  // console.log('Diamond ID:', diamond_id);
  // console.log('PRTID:', prtid);
  // console.log('MSN:', msn);

  const [reviewData, setReviewData] = useState([]);

  useEffect(() => {
    const fetchReviewData = async () => {
      const response = await getData(
        `productDetailsDetailed/${prtid}/${msn}/{diamond_id}`
      );
      setReviewData(response?.data);
      // console.log(response?.data);
    };
    fetchReviewData();
  }, [prtid, msn]);

  let authenticate_query = `{
    authenticate { 
      username_and_password(username: "testaccount@sample.com", password: "staging-nivoda-22") {
        token
      }
    }
  }
  `;

  let diamond_query = `
    query {
      get_diamond_by_id(
        diamond_id: "${diamond_id}"
      )
       {
          diamond {
            id
            video
            image
            availability
            supplierStockId
            brown
            green
            milky
            eyeClean
            mine_of_origin
            certificate {
              id
              lab
              shape
              certNumber
              cut
              carats
              clarity
              polish
              symmetry
              color
              width
              length
              depth
              girdle
              floInt
              floCol
              depthPercentage
              table
            }
        }
        price
        discount
      }
    }
  `;
  const [authenticateToken, setAuthenticateToken] = useState("");

  useEffect(() => {
    const fetchDiamondsData = async () => {
      const response = await getDiamondData(
        "diamonds",
        JSON.stringify({ query: authenticate_query })
      );
      setAuthenticateToken(
        response?.data?.authenticate?.username_and_password?.token
      );
    };
    fetchDiamondsData();
  }, [authenticate_query]);

  const [diamond, setDiamond] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const header = { Authorization: `Bearer ${authenticateToken}` };
      const result = await getDiamondData(
        "diamonds",
        JSON.stringify({ query: diamond_query }),
        header
      );
      // let { items, total_count } = result?.data?.diamonds_by_query;
      // console.log({ items, total_count });
      // setNewDia(result?.data?.diamonds_by_query?.items);
      // setDiamonds(prevDiamonds => [...prevDiamonds, newDia]);
      setDiamond(result?.data?.get_diamond_by_id);
    };
    fetchData();
  }, [authenticateToken, diamond_query]);

  const handleAddRing = async() => {
    // navigate("/diamond-product");
    // navigate("/select-setting");
    const userToken = sessionStorage.getItem("samsva_token");
    const msn=sessionStorage.getItem("msn");

    if(userToken){
        const msn=sessionStorage.getItem("msn");
    //   const prtid=sessionStorage.getItem("prtid");
      const header = {diamond:diamond};
      console.log(header);
      const response = await getData(`postMsnToCart/${msn}`, header);
      if(response){
        navigate("/shopping-bag");
      }
    
    //   navigate("/complete-ring", {state:{diamond_id:, prtid: prtid, msn: msn}});
    } else{
      const header_encode=JSON.stringify(diamond)
      const header = {diamond:header_encode}
      console.log(header);
      // console.log(header);
      const response=await getData(`guestaddtocart/${msn}`, header);
      if(response){
        navigate("/shopping-bag");
      }
    }
}

  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      <SelectSetting31 />
      <div className="mainContainer">
        <div className="imageContainer">
          <img
            src={reviewData?.productImage?.[0]}
            alt="product"
            width={"40%"}
          />
          <div className="crContainerRight">
            <div className="crHeadText">Review Your Order</div>
            <div className="crRingContainer">
            <img
              alt="images"
              src="/assets/image 169.png"
              className="reviewBagImag1"
            />
            <div>
                <div className="crText">{reviewData?.p_name}</div>
                <div className="crPriceText">USD {reviewData?.original_price}</div>
            </div>
            </div>

            <div className="crRingContainer" style={{marginTop:"20px"}}>
            <img
              alt="images"
              src={diamondIcon}
              className="reviewBagImag1"
            />
            <div>
                {/* <div className="crText">{reviewData?.diamond?.diamond_title}</div> */}
                <div className="crSubText">Cut: {diamond?.diamond?.certificate?.cut} | Color: {diamond?.diamond?.certificate?.color} | Clarity: {diamond?.diamond?.certificate?.clarity}</div>
                <div className="crPriceText">USD {diamond?.price}</div>
            </div>
            </div>
            <Divider />
            <div className="crSubText2">Total Price</div>
            <div className="crPriceText">USD {reviewData?.original_price}</div>

            <button className="crBtn1" style={{cursor:"pointer"}}>Select Ring Size</button>
            <button className="crBtn1" style={{backgroundColor:"#30373E", color:"#fff", cursor:"pointer"}} onClick={()=>{handleAddRing()}}>Add to Bag</button>
            
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteRing;
