import React, { useEffect, useState } from "react";
import "./Jewellery.css";
import Header from "../Header/Header";
import JewelleryPage1 from "../../components/JewelleryPage/JewelleryPage1/JewelleryPage1";
import JewelleryPage2 from "../../components/JewelleryPage/JewelleryPage2/JewelleryPage2";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { getData } from "../HTTPConfig/HTTPConfig";
import JewelleryFaq from "../../components/JewelleryPage/JewelleryFaq/JewelleryFaq";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
const Jewellery = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/3");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);

  return (
    <>
      <Avdert />
      <Header />
      <JewelleryPage1 dataToDisplay1={dataToDisplay1} />
      <JewelleryPage2
        compval={compval}
        dataToDisplay2={dataToDisplay2}
        dataToDisplay3={dataToDisplay3}
      />

      {compval ? (
        <div className="jw2Div">
          <img
            alt="images"
            className="jw1Img"
            src={dataToDisplay4?.image}
            loading="lazy"
          />
          <div className="jw2Div1">
            <h2 className="jw2Head">{dataToDisplay4?.title}</h2>
            <p className="jw2Text">
              {dataToDisplay4?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="jw1Btn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="jw1Div">
          <img
            alt="images"
            className="jw1Img"
            src={dataToDisplay4?.mobile_image}
            loading="lazy"
          />
          <div className="jw1Div1M">
            <h2 className="jw1Head">{dataToDisplay4?.title}</h2>
            <p className="jw1Text">
              {dataToDisplay4?.content.replace(/<\/?p>/g, '')}
            </p>
            <button className="jw1Btn">Shop Now</button>
          </div>
        </div>
      )}

      <JewelleryFaq dataToDisplay5={dataToDisplay5} />

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay6?.title}</h2>
            <p className="homeText">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.mobile_image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay6?.title}</h2>
            <p className="homeText">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      )}
      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default Jewellery;
