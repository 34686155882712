import React, { useState } from "react";
import "./MyOrderPage.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const MyOrderPage = (props) => {
  const { orders, handleOrderDetails } = props;
  const [active, setActive] = useState(false);

  const handleSelection = () => {
    setActive(true);
  };

  console.log(orders);

  return (
    <>
      <div className="mop">
        <div className="mopH">My Account</div>
        <ArrowForwardIosIcon />
        <div className="mopA">My Orders</div>
      </div>
      <div className="mopDiv">
        <div className="mopDivH">My Orders</div>
        <div className="mopDiv1">
          <div className="mopDiv2">
            <img
              alt="images"
              src="/assets/MagnifyingGlass.png"
              width={"20px"}
            />
            <input
              type="text"
              className="mopText"
              placeholder="Search All Orders"
            />
          </div>
          <button className="mopButton">Search Orders</button>
        </div>
      </div>
      <div className="mopR">
        <div className="mopAlignSub">
          <div className={active ? "mopSubHU" : "mopSubHA"}>Orders</div>

          <div
            onClick={handleSelection}
            className={active ? "mopSubHA" : "mopSubHU"}
          >
            Buy Again
          </div>

          <div
            onClick={handleSelection}
            className={active ? "mopSubHA" : "mopSubHU"}
          >
            Not Shipped Yet
          </div>
          <div
            onClick={handleSelection}
            className={active ? "mopSubHA" : "mopSubHU"}
          >
            Cancelled Orders
          </div>
        </div>
        <div style={{ width: `calc(auto)` }} className="mopDivider"></div>
      </div>

      {orders && orders?.map((item) => {
        return (
          <div key={item.order_id}>
            {/* <div className="mopOrders">
              <div className="mopOrders1">
                <div className="mopOrderH">
                  <div>Order Placed</div>
                  <div>{item.created_at}</div>
                </div>
                <div className="mopOrderH">
                  <div>Total</div>
                  <div>{item.grand_total_price}</div>
                </div>
                <div className="mopOrderH">
                  <div>Ship TO</div>
                  <div>Lorem Ipsum</div>
                </div>
              </div>
              <div>
                <div className="mopOrderH">Order</div>
                <div>#4567</div>
              </div>
            </div> */}
            <div className="mopAlignP">
              <div className="mopAlignP1">
                <img
                  alt="images"
                  src={item.image?.[0]}
                  width="35%"
                />
                <div className="mopAlignP2">
                  <div className="mopName">{item.p_name}</div>
                  <div className="mopName2">Qty: {item.quantity}</div>
                </div>
              </div>
              <button
                className="mopView"
                onClick={() => handleOrderDetails(item.order_id)}
              >
                View your Item
              </button>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MyOrderPage;
