import React, { useState } from "react";
import "./CustomerDetailsPage.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Hidden, Dialog } from "@mui/material";
import ShoppingCard from "../ShoppingBagPage/ShoppingCard";
import AddressDialog from "./AddressDialog";
import EditAddressDialog from "./EditAddressDialog";
import { postData } from "../../userInterface/HTTPConfig/HTTPConfig";
const CustomerDetailsPage = (props) => {
  const {
    userCart,
    addresses,
    handlePayment,
    handleDialog,
    handleCloseDialog,
    openDialog,
    handleDeleteAddress,
    handleAddNewAddress,
    handleSelectedAddress,
  } = props;

  const totalPrice = userCart?.map(
    (userCart) => userCart.total_price
  );

  const igst = userCart?.map((userCart) => userCart.igst);
  const discountPrice = userCart?.map((userCart) => userCart.discountMinus);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [addrId, setAddrId]=useState();

  const handleEditDialog=(id)=>{
    setAddrId(id);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog=()=>{
    setOpenEditDialog(false);
  }

  const handleEditAddress = async (
    name,
    phone,
    custAddress_id,
    pincode,
    address,
    state,
    city,
    locality,
    landmark,
    addressType
  ) => {
    setOpenEditDialog(true);
    const body = {
      name: name,
      phone: phone,
      custAddress_id:custAddress_id,
      pincode: pincode,
      address: address,
      state: state,
      city: city,
      locality: locality,
      landmark: landmark,
      address_type: addressType,
    };
    const resposne = await postData("editAddress", body);
    if (resposne.data) {
      window.location.reload(false);
      setOpenEditDialog(false);
    }
  };

  return (
    <>
      <div className="cdContainer">
        <div className="cdContainerDiv">
          <div className="cdAlignHeads">
            <div className="cdHeading">Details</div>
            <button className="cdAddNewAddressBTN" onClick={handleDialog}>
              Add New Address
            </button>
          </div>
          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  padding: "16px",
                  width: "100%",
                  maxWidth: window.innerWidth / 2, // Set your width here
                },
              },
            }}
            open={openDialog}
          >
            <AddressDialog
              handleAddNewAddress={handleAddNewAddress}
              handleCloseDialog={handleCloseDialog}
            />
          </Dialog>

          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  padding: "16px",
                  width: "100%",
                  maxWidth: window.innerWidth / 2, // Set your width here
                },
              },
            }}
            open={openEditDialog}
          >
            <EditAddressDialog
            handleCloseDialog={handleCloseEditDialog}
            handleEditAddress={handleEditAddress}
            addressId={addrId}
            addrData={addresses}
          />
          </Dialog>
          <div>
            {addresses?.map((address) => {
              return (
                <div className="cdAlignAddress" key={address.custAddress_id}>
                  <input
                    onChange={() => handleSelectedAddress(address.custAddress_id)}
                    style={{ accentColor: "#30373E" }}
                    type="radio"
                  />
                  <div className="cdAlignAddress1">
                    <div>
                      <div className="cdAddressName">{address.cust_Name}</div>
                      <div className="cdAddressDetails">
                        {address.cust_address} {address.custLandMark}
                      </div>
                      <div className="cdAddressDetails">
                        {address.custCity}, {address.custState} -{" "}
                        {address.custPincode}
                      </div>
                      <div className="cdAddressDetailsNumber">
                        Mobile: {address.order_phone}
                      </div>
                    </div>
                    <div className="acdAlignOtherBTN">
                      <div className="acdAlignOtherBTN1" onClick={() => { handleEditDialog(address.custAddress_id) }}>Edit</div>
                      <div
                        onClick={() =>
                          handleDeleteAddress(address.custAddress_id)
                        }
                        className="acdAlignOtherBTN2"
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="cdBtn">
            <button onClick={() => handlePayment()} className="cdBtns">
              Continue to Payment Method
            </button>
          </div>
        </div>
        <Hidden smDown>
          <div className="cdDivider">
            <div className="cdDivider1"></div>
          </div>
        </Hidden>
        <div className="cdContainerDiv">
          <div className="cdHeading">Order Summary</div>
          {userCart?.map((userCart) => {
            return (
              <>
                {userCart?.cartsDetails?.map((cart, index) => {
                  return (
                    <div key={index} className="shoppingBagalign">
                      <ShoppingCard cart={cart} />
                      <div className="shoppingBagalignDivs">
                        <div className="shoppingCrdM">
                          <img
                            alt="images"
                            src="/assets/image 169.png"
                            className="shoppingBagImag1"
                          />
                          <div className="shoppingCrdM1">
                            <div>
                              <div className="cdKindrea">{cart.p_name}</div>
                              <div className="cdKindreaText">
                                Colour: {cart.color} | {cart.attribute_name}:{" "}
                                {cart.cust_attr_value}
                              </div>
                            </div>
                            <div className="shoppingBagalignRingDop">
                              <div className="cdKindreaText">Ring Size</div>
                              <div className="shoppingBagDropDown">
                                <KeyboardArrowDownIcon fontSize="8px" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="cdprice">
                        USD {cart.discounted_price}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
          <div className="cdDivider2"></div>

          <div className="cdPriceDiv">
            <div className="cdPriceHead">Shipping Cost</div>
            <div className="cdPrices">FREE</div>
          </div>
          <div className="cdPriceDiv">
            <div className="cdPriceHead">Discount</div>
            <div className="">{discountPrice}</div>
          </div>
          <div className="cdPriceDiv">
            <div className="cdPriceHead">Tax</div>
            <div className="cdPrices">{igst}</div>
          </div>
          <div className="cdPriceDiv">
            <div className="cdPriceHeadM">Total Amount</div>
            <div className="cdPricesHeadM">{totalPrice}</div>
          </div>
          <div className="cdNote">Estimated delivery: Sunday, 24 Oct </div>
        </div>
      </div>
    </>
  );
};

export default CustomerDetailsPage;
