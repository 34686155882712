import axios from "axios";
const baseURL = "https://samasva-production.alphaprotocall.com/public/api/user";

const getData = async (url, customHeader) => {
  const userToken = await sessionStorage.getItem("samsva_token");
  try {
    const response = await axios.get(`${baseURL}/${url}`, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en",
        DeviceName: "Android",
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": "",
        Authorization: `Bearer ${userToken}`,
        ...customHeader, // Add custom headers
      },
    });
    const result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

const postData = async (url, body) => {
  const userToken = sessionStorage.getItem("samsva_token");
  try {
    const response = await axios.post(`${baseURL}/${url}`, body, {
      headers: {
        accept: "application/json",
        "Accept-Language": "en",
        DeviceName: "Android",
        "Content-Type": "multipart/form-data",
        "X-CSRF-TOKEN": "",
        Authorization: `Bearer ${userToken}`,
      },
    });
    const result = await response.data;
    return result;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// const diamondBaseURL = "http://wdc-intg-customer-staging.herokuapp.com/api";
// const diamondBaseURL = "https://intg-customer-staging.nivodaapi.net/api";
const diamondBaseURL = "https://integrations.nivoda.net/api";

const getDiamondData = async (url, body, customHeader) => {
  try {
    const response = await axios.post(`${diamondBaseURL}/${url}`, body, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        ...customHeader
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export { getData, postData, getDiamondData };
