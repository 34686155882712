import React, { useEffect, useState } from "react";

const AddressDialog = (props) => {
  const { handleCloseDialog, handleEditAddress, addressId, addrData } = props;
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [locality, setLocality] = useState("");
  const [address, setAddress] = useState("");
  const [addressType, setAddressType] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [landMark, setLandMark] = useState("");

  useEffect(() => {
    // Find the address data based on the provided addressId
    const selectedAddress = addrData.find((item) => item.custAddress_id  === addressId);
    console.log(selectedAddress);

    // Set state values based on the selected address data
    if (selectedAddress) {
      setName(selectedAddress.cust_Name || "");
      setMobile(selectedAddress.order_phone || "");
      setLocality(selectedAddress.locality || "");
      setAddress(selectedAddress.cust_address || "");
      setAddressType(selectedAddress.address_type || "");
      setCity(selectedAddress.custCity || "");
      setState(selectedAddress.custState || "");
      setPincode(selectedAddress.custPincode || "");
      setLandMark(selectedAddress.custLandMark || "");
    }
  }, [addressId, addrData]);

  return (
    <>
      <div className="cdHeadingA">Details</div>
      <div className="cdAlignDiv">
        <div className="cdAlignDiv1">
          <div className="cdHead">Name</div>
          <input
            onChange={(e) => setName(e.target.value)}
            type="text"
            value={name}
            className="cdInput"
          />
        </div>
        <div className="cdAlignDiv1">
          <div className="cdHead">Phone Number</div>
          <input
            onChange={(e) => setMobile(e.target.value)}
            type="text"
            value={mobile}
            className="cdInput"
            maxLength={10}
          />
        </div>
      </div>
      <div className="cdAlignDiv">
        <div className="cdAlignDiv1">
          <div className="cdHead">Pincode</div>
          <input
            onChange={(e) => setPincode(e.target.value)}
            type="text"
            value={pincode}
            className="cdInput"
          />
        </div>
        <div className="cdAlignDiv1">
          <div className="cdHead">Address</div>
          <input
            onChange={(e) => setAddress(e.target.value)}
            type="text"
            value={address}
            className="cdInput"
          />
        </div>
      </div>
      {/* <div className="cdAlignDiv2">
        <div className="cdHead">City</div>
        <input
          onChange={(e) => setCity(e.target.value)}
          type="text"
          className="cdInput"
        />
      </div> */}
      <div className="cdAlignDiv">
        <div className="cdAlignDiv1">
          <div className="cdHead">Locality</div>
          <input
            onChange={(e) => setLocality(e.target.value)}
            type="text"
            value={locality}
            className="cdInput"
          />
        </div>
        <div className="cdAlignDiv1">
          <div className="cdHead">LandMark</div>
          <input
            onChange={(e) => setLandMark(e.target.value)}
            type="text"
            value={landMark}
            className="cdInput"
          />
        </div>
      </div>
      <div className="cdAlignDiv">
        <div className="cdAlignDiv1">
          <div className="cdHead">City</div>
          <input
            onChange={(e) => setCity(e.target.value)}
            type="text"
            value={city}
            className="cdInput"
          />
        </div>
        <div className="cdAlignDiv1">
          <div className="cdHead">State</div>
          <input
            onChange={(e) => setState(e.target.value)}
            type="text"
            value={state}
            className="cdInput"
          />
        </div>
      </div>
      <div className="cdAlignDiv">
        <div className="cdAlignDiv1">
          <div className="cdHead">Address Type</div>
          <input
            onChange={(e) => setAddressType(e.target.value)}
            type="text"
            value={addressType}
            className="cdInput"
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0px 16px 0px",
        }}
      >
        <button className="cdAddNewAddressBTNB" onClick={handleCloseDialog}>
          Close
        </button>
        <button
          className="cdAddNewAddressBTNA"
          onClick={() =>
            handleEditAddress(
              name,
              mobile,
              addressId,
              pincode,
              address,
              state,
              city,
              locality,
              landMark,
              addressType
            )
          }
        >
          Save Address
        </button>
      </div>
    </>
  );
};

export default AddressDialog;
