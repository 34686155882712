import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import WeddingRings1 from "../../components/WeddingRingsPage/WeddingRings1/WeddingRings1";
import WeddingRings2 from "../../components/WeddingRingsPage/WeddingRings2/WeddingRings2";
import WeddingRings3 from "../../components/WeddingRingsPage/WeddingRings3/WeddingRings3";
import WeddingRings4 from "../../components/WeddingRingsPage/WeddingRings4/WeddingRings4";
import WeddingRings5 from "../../components/WeddingRingsPage/WeddingRings5/WeddingRings5";
import WeddingRings6 from "../../components/WeddingRingsPage/WeddingRings6/WeddingRings6";
import WeddingRings7 from "../../components/WeddingRingsPage/WeddingRings7/WeddingRings7";
import WeddingRings8 from "../../components/WeddingRingsPage/WeddingRings8/WeddingRings8";
import WeddingRings9 from "../../components/WeddingRingsPage/WeddingRings9/WeddingRings9";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import "./WeddingRings.css";
import { getData } from "../HTTPConfig/HTTPConfig";
import WeddingFaq from "../../components/WeddingRingsPage/WeddingFaq/WeddingFaq";

const WeddingRings = () => {
  const [compval, setCompval] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  useEffect(() => {
    const fetchRingPageData = async () => {
      const response = await getData("page/content/5");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchRingPageData();
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);
  const dataToDisplay7 = data.find((data) => data.section === 7);
  const dataToDisplay8 = data.find((data) => data.section === 8);
  const dataToDisplay9 = data.find((data) => data.section === 9);
  const dataToDisplay10 = data.find((data) => data.section === 10);
  const dataToDisplay11 = data.find((data) => data.section === 11);
  const dataToDisplay12 = data.find((data) => data.section === 12);
  const dataToDisplay13 = data.find((data) => data.section === 13);
  const dataToDisplay14 = data.find((data) => data.section === 14);

  return (
    <>
      <Avdert />
      <Header />
      <WeddingRings1 dataToDisplay1={dataToDisplay1} />
      <WeddingRings2
        dataToDisplay2={dataToDisplay2}
        dataToDisplay3={dataToDisplay3}
      />
      <WeddingRings3 dataToDisplay4={dataToDisplay4} />
      <WeddingRings4 dataToDisplay5={dataToDisplay5} />
      {compval ? (
        <div className="wrDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.image}
            loading="lazy"
          />
          <div className="wrDiv12">
            <h2 className="wrHead">{dataToDisplay6?.title}</h2>
            <p className="wrText">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="wrBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="wrDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.mobile_image}
            loading="lazy"
          />
          <div className="wrDiv12">
            <h2 className="wrHead">{dataToDisplay6?.title}</h2>
            <p className="wrText">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="wrBtn">Shop Now</button>
          </div>
        </div>
      )}
      <WeddingRings5 dataToDisplay7={dataToDisplay7} />
      <WeddingRings6 dataToDisplay8={dataToDisplay8} />
      <WeddingRings7 dataToDisplay9={dataToDisplay9} />
      <WeddingRings8 dataToDisplay10={dataToDisplay10} />

      {compval ? (
        <div className="wrDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay11?.image}
            loading="lazy"
          />
          <div className="wrEDiv12">
            <h2 className="wrHead">{dataToDisplay11?.title}</h2>
            <p className="wrText">
              {dataToDisplay11?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="wrBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="wrDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay11?.mobile_image}
            loading="lazy"
          />
          <div className="wrDiv12">
            <h2 className="wrHead">{dataToDisplay11?.title}</h2>
            <p className="wrText">
              {dataToDisplay11?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="wrBtn">Shop Now</button>
          </div>
        </div>
      )}

      <WeddingRings9 dataToDisplay12={dataToDisplay12} />

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay13?.image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay13?.title}</h2>
            <p className="homeText">
              {dataToDisplay13?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay13?.mobile_image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay13?.title}</h2>
            <p className="homeText">
              {dataToDisplay13?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      )}

      <WeddingFaq dataToDisplay={dataToDisplay14} />
      <FooterPage />
    </>
  );
};

export default WeddingRings;
