import React from "react";
import "./ShoppingBagPage.css";
import { Divider, Hidden } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ShoppingCard from "./ShoppingCard";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";

const ShoppingBagPage = (props) => {
  const { guestCart, userCart, handleCheckout } = props;
  const userToken = sessionStorage.getItem("samsva_token");

  // const total = guestCart?.map(
  //   (userCart) => userCart.total_discounted_price
  // );

  console.log(guestCart);

  const totalPrice = userCart?.map(
    (userCart) => userCart.total_price
  );

  const igst = userCart?.map((userCart) => userCart.igst);
  const discountPrice = userCart?.map((userCart) => userCart.discountMinus);

  const cartMinus = async (id, qty) => {
    if (qty > 1) {
      const response = await getData(`minus/quantity/cart/${id}`);
      if (response) {
        window.location.reload();
      }
    } else {
      const response = await getData(`delete/quantity/cart/${id}`);
      if (response) {
        window.location.reload();
      }
    }
  }

  const cartGuestMinus = async (id, qty) => {
    if (qty > 1) {
      const response = await getData(`guestcartQuantityMinus/${id}`);
      if (response) {
        window.location.reload();
      }
    } else {
      const response = await getData(`removeProductguestCart/${id}`);
      if (response) {
        window.location.reload();
      }
    }
  }

  const cartAdd = async (id) => {
    const response = await getData(`add/quantity/cart/${id}`);
    if (response) {
      window.location.reload();
    }
  }

  const cartGuestAdd = async (id) => {
    const response = await getData(`guestcartQuantityAddition/${id}`);
    if (response) {
      window.location.reload();
    }
  }

  return (
    <>
      <div className="shoppingBagContainer">
        <div className="shoppingBagheading">My Shopping Bag</div>
        <div className="shoppingBagMainDiv">
          {!userToken && (
            <div className="shoppingBagDiv1">
              {guestCart?.cartsDetails?.map((cart, index) => {
                return (
                  <>
                    <div key={index} className="shoppingBagalign">
                      <ShoppingCard cart={cart} />
                      <div className="shoppingBagalignDivs">
                        <div className="shoppingCrdM">
                          <img
                            alt="images"
                            src="/assets/image 169.png"
                            className="shoppingBagImag1"
                          />
                          <div className="shoppingCrdM1">
                            <div>
                              <div className="shoppingBagPName">
                                {cart.p_name}
                              </div>
                              <div className="shoppingBagPDs">
                                Colour: {cart.color} | {cart.attribute_name}:{" "}
                                {cart.cust_attr_value}
                              </div>
                            </div>
                            {cart?.diamond&&<div className="shoppingCrdM2"> 
                            <img
                            alt="images"
                            src="/assets/image 170.png"
                            className="shoppingBagImag2"
                          />
                          {/* <div className="shoppingBagPName">{cart.diamond?.diamond_title}</div> */}
                          <div className="shoppingBagPriceDia">
                          USD {cart.diamond_price}
                        </div>
                          </div>}
                          {cart?.diamond&&<div className="shoppingBagPDsDia">
                          Cut: {cart.diamond?.diamond_cut} | Color: {cart.diamond?.diamond_color}
                                    | Clarity: {cart.diamond?.diamond_clarity}
                              </div>}
                            <div className="shoppingBagalignRingDop">
                              <div className="shoppingBagRingSize">
                                Ring Size
                              </div>
                              <div className="shoppingBagDropDown">
                                <KeyboardArrowDownIcon fontSize="small" />
                              </div>
                              <div className="shoppingBagquatitiy">
                                <button className="shoppingBagquantityButton" onClick={() => { cartGuestMinus(cart.guest_cart_id, cart.qty) }}>-</button>
                                <div className="shoppingBagquantity">{cart.qty}</div>
                                <button className="shoppingBagquantityButton" onClick={() => { cartGuestAdd(cart.guest_cart_id) }}>+</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="shoppingBagPrice">
                        USD {cart.discounted_price}
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
              <Hidden smDown>
                <Divider sx={{ marginTop: "10px", width: "100%" }} />
              </Hidden>
              <div className="shoppingBagalignPrice">
                <div className="shoppingBagalignPrice1">
                  <div className="shoppingBagTotal">Total</div>
                  <div className="shoppingBagPrice">USD {guestCart?.total_price}</div>
                </div>
              </div>
            </div>
          )}

          {userToken && (
            <div className="shoppingBagDiv1">
              {userCart?.map((userCart) => {
                return (
                  <>
                    {userCart.cartsDetails?.map((cart, index) => {
                      return (
                        <div key={index} className="shoppingBagalign">
                          <ShoppingCard cart={cart} />
                          <div className="shoppingBagalignDivs">
                            <div className="shoppingCrdM">
                              <img
                                alt="images"
                                src="/assets/image 169.png"
                                className="shoppingBagImag1"
                              />
                              <div className="shoppingCrdM1">
                                <div>
                                  <div className="shoppingBagPName">
                                    {cart.p_name}
                                  </div>
                                  <div className="shoppingBagPDs">
                                    Colour: {cart.color} | {cart.attribute_name}
                                    : {cart.cust_attr_value}
                                  </div>
                                </div>
                                {cart?.diamond&&<div className="shoppingCrdM2"> 
                            <img
                            alt="diamond"
                            src="/assets/image 170.png"
                            className="shoppingBagImag2"
                          />
                          {/* <div className="shoppingBagPName">{cart.diamond?.diamond_title}</div> */}
                          <div className="shoppingBagPriceDia">
                          USD {cart.diamond_price}
                        </div>
                          </div>}
                          {userCart?.cartsDetails?.diamond&&<div className="shoppingBagPDsDia">
                                    Cut: {cart.diamond?.diamond_cut} | Color: {cart.diamond?.diamond_color}
                                    | Clarity: {cart.diamond?.diamond_clarity}
                                  </div>}
                                <div className="shoppingBagalignRingDop">
                                  <div className="shoppingBagRingSize">
                                    Ring Size
                                  </div>
                                  <div className="shoppingBagDropDown">
                                    <KeyboardArrowDownIcon fontSize="small" />
                                  </div>
                                  <div className="shoppingBagquatitiy">
                                    <button className="shoppingBagquantityButton" onClick={() => { cartMinus(cart.cartprt_id, cart.qty) }}>-</button>
                                    <div className="shoppingBagquantity">{cart.qty}</div>
                                    <button className="shoppingBagquantityButton" onClick={() => { cartAdd(cart.cartprt_id) }}>+</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="shoppingBagPrice">
                            USD {cart.discounted_price}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                );
              })}
              <Hidden smDown>
                <Divider sx={{ marginTop: "10px", width: "100%" }} />
              </Hidden>
              <div className="shoppingBagalignPrice">
                <div className="shoppingBagalignPrice1">
                  <div className="shoppingBagTotal">Total</div>
                  <div className="shoppingBagPrice">USD {totalPrice}</div>
                </div>
              </div>
            </div>
          )}

          <div className="verticalDivider"></div>
          <div className="shoppingBagDiv2">
            <div className="shoppingBagPromo">Enter Promo Code</div>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <input type="text" className="shoppingBagpromoCode" />
              <button className="shoppingBagpromoCodeSubmit">Submit</button>
            </div>
            <div className="shoppingBagDiv2divs">
              <div className="shoppingBagaSubHead">Shipping cost</div>
              <div className="shoppingBagSubPrice">FREE</div>
            </div>
            <div className="shoppingBagDiv2divs">
              <div className="shoppingBagaSubHead">Discount</div>
              <div className="shoppingBagSubPrice">{discountPrice}</div>
            </div>
            <div className="shoppingBagDiv2divs">
              <div className="shoppingBagaSubHead">Tax</div>
              <div className="shoppingBagSubPrice">{igst}</div>
            </div>
            <div className="shoppingBagDiv2divs">
              <div style={{ fontWeight: 700 }} className="shoppingBagaSubHead">
                Total Amount
              </div>
              <div style={{ fontWeight: 700 }} className="shoppingBagSubPrice">
                {userToken ? totalPrice : guestCart?.total_price}
              </div>
            </div>
            <div
              onClick={() => handleCheckout(userCart)}
              className="shoppingBagCheckout"
            >
              Checkout
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingBagPage;
