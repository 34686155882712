import React, { createRef } from "react";
import "./Home1.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const Home1 = (props) => {
  const { dataToDisplay } = props;

  const mySlider = createRef();
  const navigate=useNavigate();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const handleClickRedirect=(id)=>{
    if(id===1){
      navigate("/diamond-page");
    } else if(id===2){
      navigate("/buy-bac");
    } else if(id===3){
      navigate("/select-setting");
    } else if(id===4){
      navigate("/diamond-dna");
    }
  };

  const getHome2data = () => {
    return dataToDisplay?.subsection?.map((item, index) => {
      return (
        <div key={index} className="home1Container1-div-div" style={{cursor:"pointer"}} onClick={()=>{handleClickRedirect(item.position)}}>
          <div className="home1ImgDiv1">
            <img alt="images" src={item.mobile_image} className="hom1Img" />
          </div>
          <div className="home1ImgDiv2">
            <div className="home1Mhead">
              {item.title}
              <span className="home1Brd">{item.str}</span>
              <span style={{ paddingLeft: "4px" }}>{item.str1}</span>
              <span className="home1CreatedBy">{item.createdBy}</span>
            </div>
            <div className="home1Desc">{item.content.replace(/<\/?p>/g, '')}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Hidden smDown>
        <div className="home1Container1-div">{getHome2data()}</div>
      </Hidden>
      <Hidden smUp>
        <Slider ref={mySlider} {...settings}>
          {getHome2data()}
        </Slider>
      </Hidden>
    </>
  );
};

export default Home1;
