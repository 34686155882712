import React, { useState } from "react";
import "./SelectSettingFilter.css";
import ReactSlider from "react-slider";
import { Hidden } from "@mui/material";

const SelectSettingFilter = (props) => {
  const { colors, handleColorId, selectedColors } = props;

  const getColors = () => {
    return colors?.map((item, index) => {
      return (
        <div
          value={item.pcolorId}
          key={index}
          className="selectSettingFilterAlignColors"
          onClick={() => handleColorId(item.pcolorId)}
        >
          <div
            className={
              selectedColors.includes(item.pcolorId)
                ? "selectSettingFilterColorsSizeA"
                : "selectSettingFilterColorsSize"
            }
            style={
              selectedColors.includes(item.pcolorId)
                ? {
                    backgroundColor: `${item.color}`,
                  }
                : {
                    backgroundColor: `${item.color}`,
                  }
            }
          ></div>
          <div className="selectSettingFilterColorsFont">{item.color}</div>
        </div>
      );
    });
  };

  const minsellingPrice = 80000;
  const maxsellingPrice = 350000;

  const [priceRange, setPriceRange] = useState([
    parseInt(minsellingPrice),
    parseInt(maxsellingPrice),
  ]);
  return (
    <>
      <div className="selectSettingFilterContainer">
        <div className="selectSettingFilterDiv">
          <div className="selectSettingFilterHeading">Shop By Metal</div>
          <div className="selectSettingFilterAlignColors1">{getColors()}</div>
        </div>
        <Hidden smDown>
        <div className="selectSettingFilterDiv">
          <div className="selectSettingFilterHeading">Shop By Price</div>
          <div className="selectSettingFilterAlignSizeSlider">
            <div className="selectSettingFilterAlignPrices">
              <div className="selectSettingFilterPriceBoxes">
              USD {priceRange[0]}
              </div>
              <div className="selectSettingFilterPriceBoxes">
              USD {priceRange[1]}
              </div>
            </div>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              defaultValue={[
                parseInt(minsellingPrice),
                parseInt(maxsellingPrice),
              ]} //put the minsellingPrice and maxsellingPrice
              min={minsellingPrice}
              max={maxsellingPrice}
              onChange={(value, index) => setPriceRange(value)}
            />
          </div>
        </div>
        </Hidden>
      </div>
    </>
  );
};

export default SelectSettingFilter;
