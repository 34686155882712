import React, { useEffect, useState } from "react";
import "./ContactUsPage.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
const ContactUsPage = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      <div className="cuContainer">
        {compval ? (
          <img
            alt="images"
            src="/assets/custm.webp"
            loading="lazy"
            className="cuImg"
          />
        ) : (
          <img
            alt="images"
            src="/assets/custm1.webp"
            loading="lazy"
            className="cuImg"
          />
        )}
        <div className="cuAlign">
          <div className="cuHead">Contact Us</div>
          <div className="cuText">
            Our team of jewelry specialists is here for you 24/7, ready to
            answer any questions you may have!
          </div>
        </div>
        <div className="cuMainHead">Send us a message</div>
        <div className="cuAlignInput">
          <div className="cuAlignInput1">
            <div className="cuName">Name</div>
            <input type="text" className="cuInput1" />
          </div>
          <div className="cuAlignInput1">
            <div className="cuName">Email</div>
            <input type="text" className="cuInput1" />
          </div>
        </div>

        <div className="cuAlignInput2">
          <div className="cuName">Subject</div>
          <input type="text" className="cuInput1" />
        </div>

        <div className="cuAlignInput2">
          <div className="cuName">Message</div>
          <textarea type="text" rows={12} className="cuTextArea" />
        </div>

        <div className="cuAlignInput3">
          <button className="cuBtnss">Send Message</button>
        </div>
      </div>

      <div className="cuContainer2">
        <div className="cuContainer2Div">
          <CallIcon
            htmlColor="#5A2D47"
            fontSize="large"
            sx={{
              padding: "16px",
              backgroundColor: "#F9E5E9",
              borderRadius: "100%",
            }}
          />
          <div className="cuContainer2Head">Contact number</div>
          <div className="cuContainer2Text">+91 00000 00000</div>
        </div>
        <div className="cuContainer2Div">
          <LocationOnIcon
            htmlColor="#5A2D47"
            fontSize="large"
            sx={{
              padding: "16px",
              backgroundColor: "#F9E5E9",
              borderRadius: "100%",
            }}
          />
          <div className="cuContainer2Head">Address</div>
          <div className="cuContainer2Text">
            Loreum ipsum near A-park Hotel Gurugram, Haryana444001
          </div>
        </div>
        <div className="cuContainer2Div">
          <EmailIcon
            htmlColor="#5A2D47"
            fontSize="large"
            sx={{
              padding: "16px",
              backgroundColor: "#F9E5E9",
              borderRadius: "100%",
            }}
          />
          <div className="cuContainer2Head">Email address</div>
          <div className="cuContainer2Text">Loreumipsum@gmail.com</div>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
