import React, { useEffect, useState } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import "./AllProduct.css";

// import image1 from "./AllProductImages/image 143.png";
// import image2 from "./AllProductImages/image 144.png";
// import image3 from "./AllProductImages/image 145.png";
// import image4 from "./AllProductImages/image 147.png";
// import image5 from "./AllProductImages/image 149.png";
// import image6 from "./AllProductImages/image 150.png";
// import image7 from './AllProductImages/image 95.png';
// import SelectSettingFilter from '../../components/SelectSettingPage/SelectSettingFilter/SelectSettingFilter';
import { Hidden } from "@mui/material";
import { getData } from "../HTTPConfig/HTTPConfig";
import AllProductFilter from "../../components/AllProductFilter/AllProductFilter";
import FooterPage from "../../components/FooterPage/FooterPage";
import { useNavigate } from "react-router-dom";
import SelectSetting4 from "../../components/SelectSettingPage/SelectSetting4/SelectSetting4";

//Necklace Images
import ncImage1 from "./NecklaceImages/chain.webp";
import ncImage2 from "./NecklaceImages/charm.webp";
import ncImage3 from "./NecklaceImages/medallion.webp";
import ncImage4 from "./NecklaceImages/nature.webp";
import ncImage5 from "./NecklaceImages/personalized.png";
import ncImage6 from "./NecklaceImages/religious.webp";
import ncImage7 from "./NecklaceImages/station.webp";
import ncImage8 from "./NecklaceImages/tennis.webp";
import ncImage9 from "./NecklaceImages/white_gold.webp";

//Earring Images
import erImage1 from "./EarringImages/drop_ir352.webp";
import erImage2 from "./EarringImages/hoop.webp";
import erImage3 from "./EarringImages/lab_diamond.webp";
import erImage4 from "./EarringImages/luxe_ir352.webp";
import erImage5 from "./EarringImages/pearl.webp";
import erImage6 from "./EarringImages/single.png";
import erImage7 from "./EarringImages/stud.webp";
import erImage8 from "./EarringImages/white_gold.webp";
import erImage9 from "./EarringImages/yellow_gold.png";

//Bracelete Images
import brImage1 from "./BraceleteImages/bangle.jpg";
import brImage2 from "./BraceleteImages/birthstone-old.jpg";
import brImage3 from "./BraceleteImages/chain-yellow.jpg";
import brImage4 from "./BraceleteImages/cuff-yellow-f8.webp";
import brImage5 from "./BraceleteImages/diamond.jpg";
import brImage6 from "./BraceleteImages/tennis.jpg";
import brImage7 from "./BraceleteImages/white-gold.jpg";
import brImage8 from "./BraceleteImages/yellow-gold.jpg";

const AllProduct = () => {
  const navigate = useNavigate();
  const [styleData, setStyleData] = useState([]);

  const [productArr, setProductArr] = useState([]);

  const [selectedColors, setSelectedColors] = useState([]);
  const [colors, setColors] = useState([]);
  // const [dataByColorId, setDataByColorId] = useState([]);
  const [checkedColors, setCheckedColors] = useState("");
  const [dataByColorId, setDataByColorId] = useState([]);
  const [productData, setProductData] = useState([]);

  const [loading, setLoading] = useState(false);

  // const [ringStyleData, setRingStyleData] = useState([]);

  const active = false;

  useEffect(() => {
    const fetchData = async () => {
      // const ringStyleData = [
      //   {
      //     title: "Solataei",
      //     imgurl: image1,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image2,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image3,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image4,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image5,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image6,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image1,
      //   },
      //   {
      //     title: "Solataei",
      //     imgurl: image5,
      //   },
      // ];

      const necklaceStyleData = [
        {
          title: "Chains",
          imgurl: ncImage1,
        },
        {
          title: "Medallion",
          imgurl: ncImage3,
        },
        {
          title: "Tennis",
          imgurl: ncImage8,
        },
        {
          title: "Personalized",
          imgurl: ncImage5,
        },
        {
          title: "Station",
          imgurl: ncImage7,
        },
        {
          title: "Classic",
          imgurl: ncImage9,
        },
        {
          title: "Nature",
          imgurl: ncImage4,
        },
        {
          title: "Religious",
          imgurl: ncImage6,
        },
        {
          title: "Charm",
          imgurl: ncImage2,
        },
      ];

      const earringStyleData = [
        {
          title: "Stud",
          imgurl: erImage7,
        },
        {
          title: "Hoop",
          imgurl: erImage2,
        },
        {
          title: "Yellow Gold",
          imgurl: erImage9,
        },
        {
          title: "Diamond",
          imgurl: erImage3,
        },
        {
          title: "Drop",
          imgurl: erImage1,
        },
        {
          title: "Pearl",
          imgurl: erImage5,
        },
        {
          title: "White Gold",
          imgurl: erImage8,
        },
        {
          title: "Luxe",
          imgurl: erImage4,
        },
        {
          title: "Single",
          imgurl: erImage6,
        },
      ];

      const braceletStyleData = [
        {
          title: "Tennis",
          imgurl: brImage6,
        },
        {
          title: "Yellow Gold",
          imgurl: brImage8,
        },
        {
          title: "Diamond",
          imgurl: brImage5,
        },
        {
          title: "Bangle",
          imgurl: brImage1,
        },
        {
          title: "White Gold",
          imgurl: brImage7,
        },
        {
          title: "Chain",
          imgurl: brImage3,
        },
        {
          title: "Silver",
          imgurl: brImage2,
        },
        {
          title: "Cuff",
          imgurl: brImage4,
        },
      ];

      const page = sessionStorage.getItem("productPage");

      // if (page === "allProduct" || page === "ring") {
      if (page === "ring") {
        const styleResponse = await getData("getallattributeandvalue");
        const response = await getData("productbycategory/Rings");
        setProductArr(Array.isArray(response?.data?.product) ? response?.data?.product : []);
        setStyleData(styleResponse?.data?.[3]?.valuedata);
        console.log("styleResponse", styleResponse?.data[3]?.valuedata);
      } else if (page === "pendant") {
        const response = await getData("productbycategory/Pendants");
        setProductArr(Array.isArray(response?.data?.product) ? response?.data?.product : []);
        setStyleData(necklaceStyleData);
      } else if (page === "earring") {
        const response = await getData("productbycategory/Earrings");
        setProductArr(Array.isArray(response?.data?.product) ? response?.data?.product : []);
        setStyleData(earringStyleData);
      } else if (page === "bracelet") {
         const response = await getData("productbycategory/Bracelets");
        setProductArr(Array.isArray(response?.data?.product) ? response?.data?.product : []);
        setStyleData(braceletStyleData);
      } else {
        const response = await getData("allproduct");
        setProductArr(Array.isArray(response?.data) ? response?.data : []);
        setStyleData([]);
      }
    };
    fetchData();
  }, []);

  const getColors = async () => {
    const result = await getData("allcolor/getcolor");
    setColors(result?.data);
  };
  const handleColorId = async (id) => {
    try {
      const isColorSelected = selectedColors?.includes(id);
      if (isColorSelected) {
        setSelectedColors(
          selectedColors.filter((selectedColor) => selectedColor !== id)
        );
      } else {
        setSelectedColors([...selectedColors, id]);
      }
      const result = await getData(`getattribute/${checkedColors}`);
      setDataByColorId(result?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [formattedString, setFormattedString] = useState("");

  useEffect(() => {
    const colrs = selectedColors.map((attr) => `${attr}`);
    setCheckedColors(colrs.join(", "));
  }, [selectedColors]);

  useEffect(() => {
    getColors();
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const response = await getData("allproduct");
  //     if (response.data) {
  //       setProductArr(response.data);
  //     } else {
  //       return null;
  //     }
  //   };
  //   fetchData();
  // }, []);

  useEffect(() => {
    const formatted = selectedAttributes?.map(
      (attr) => `${attr.attributeId}:'${attr.value}'`
    );
    setFormattedString(formatted?.join(", "));
  }, [selectedAttributes]);

  const handleCustomrAttr = async (attributeId, value) => {
    const isSelected = selectedAttributes.some(
      (attr) => attr.attributeId === attributeId && attr.value === value
    );

    if (isSelected) {
      setSelectedAttributes((prevSelected) =>
        prevSelected.filter(
          (attr) => !(attr.attributeId === attributeId && attr.value === value)
        )
      );
    } else {
      setSelectedAttributes((prevSelected) => [
        ...prevSelected,
        { attributeId, value },
      ]);
    }
  };

  useEffect(() => {
    const fetchDefaultProductData = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/{pcolorId}/{attributenameandid}`
        );
        setProductData(result?.data);
        setLoading(false);
      } catch (error) {
        console.log("error", error.response);
        setLoading(false);
      }
    };

    const fetchDefaultProductDataByColor = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/${checkedColors}/{attributenameandid}`
        );
        setProductData(result?.data);
        setLoading(false);
        console.log(productData);
      } catch (error) {
        console.log("error", error?.response);
        setLoading(false);
      }
    };
    const fetchProductData = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/${checkedColors}/${formattedString}`
        );
        setProductData(result?.data);
        setLoading(false);
      } catch (error) {
        console.log("error", error?.response);
        setLoading(false);
      }
    };
    if (formattedString) {
      fetchProductData();
    }
    if (checkedColors) {
      fetchDefaultProductDataByColor();
    } else {
      fetchDefaultProductData();
    }
  }, [checkedColors, formattedString, productData]);

  if (loading) {
    return <div>loading...</div>;
  }

  const handleProduct = (p_name, id, msn, colorId) => {
    navigate(`/product/${p_name}`, {
      state: { comp: active, id: id, msn: msn, colorId: colorId },
    });
  };
  return (
    <>
      <Avdert />
      <Header />
      <div className="allProductpageMaindiv">
        <div className="allProductSectionTitle">Shop by Style</div>
        <div
          className="allppsbsimgdiv"
          style={{ overflowX: "auto" }}>
          {styleData?.map((item, index) => {
            return (
              <div
                key={index}
                className="allppsimg">
                <img
                  src={item?.attribute_image}
                  alt="ring"
                  width={"50%"}
                />
                <div className="allppsimgtitle">{item?.cust_attr_value}</div>
              </div>
            );
          })}
        </div>
        <Hidden smDown>
          <AllProductFilter
            handleColorId={handleColorId}
            selectedColors={selectedColors}
            colors={colors}
          />
        </Hidden>
        {dataByColorId?.map((item, index) => {
          return (
            <SelectSetting4
              selectedAttributes={selectedAttributes}
              handleCustomrAttr={handleCustomrAttr}
              item={item}
            />
          );
        })}
        <div className="allppProductdiv">
          {productArr?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleProduct(
                      item.p_name,
                      item.prtid,
                      item.msn,
                      item.pcolorId
                    );
                  }}>
                  <img
                    src={item?.productImage?.[0]}
                    alt="ring"
                    width={"200px"}
                  />
                  <div className="allppProductTitle">{item.p_name.length > 20 ? item.p_name.substring(0, 20) + "..." : item.p_name}</div>
                  <div className="allppProductColor">
                    {item?.color} {`(${item?.carat})`}
                  </div>
                  <div className="allppProductPrice">
                  USD {item?.discount_price}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <FooterPage />
    </>
  );
};

export default AllProduct;
