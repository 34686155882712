import React from "react";
import "./SelectDiamond1C2.css";

const SelectDiamond1C2 = () => {
  const colorArr = [
    "Colorless Diamond",
    "Yellow Diamond",
    "Green Diamond",
    "Red Diamond",
    "Black Diamond",
    "Pink Diamond",
    "Blue Diamond",
  ];

  const getColorArr = () => {
    return colorArr.map((item) => {
      return <div className="sd2Divs">{item}</div>;
    });
  };

  return (
    <>
      <div className="sd2Container">
        <div className="sd2Head">Color</div>
        <div className="sd2AlignDiv">{getColorArr()}</div>
      </div>
    </>
  );
};

export default SelectDiamond1C2;
