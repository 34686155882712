import React, { createRef } from "react";
import "./Engagement5.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Engagement5 = (props) => {
  const { dataToDisplay8 } = props;
  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1.9,
    arrows: false,
  };

  const getHome2data = () => {
    return dataToDisplay8?.attribute[0]?.valuedata?.map((item, index) => {
      return (
        <div key={index} className="engagement5alignDivSize">
          <img
            alt="images"
            src={item.attribute_image}
            className="engagement5Img"
          />
          <div className="engagement5Text">{item.cust_attr_value}</div>
        </div>
      );
    });
  };
  return (
    <>
      <div className="engagement5Container">
        <div className="engagement5Heading">Shop By Center Stone Type</div>
        <Hidden smDown>
          <div className="engagement5alignDiv">{getHome2data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome2data()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default Engagement5;
