import CustomerDetailsPage from "../../components/CustomerDetailsPage/CustomerDetailsPage";
import Header from "../Header/Header";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getData, postData } from "../HTTPConfig/HTTPConfig";

const CustomerDetails = () => {
  const userToken = sessionStorage.getItem("samsva_token");
  const location = useLocation();
  const userCart = location.state.userCart;
  const [addresses, setAddresses] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [sessionId, setSessionId] = useState("");

  useEffect(() => {
    const fetchAllAddress = async () => {
      const response = await getData("showAddress");
      setAddresses(response?.data);
    };
    if (userToken) {
      fetchAllAddress();
    }
  }, [userToken]);

  // console.log("address", addresses);

  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteAddress = async (id) => {
    const resposne = await getData(`deleteAddress/${id}`);
    if(resposne?.data){
      window.location.reload(false);
    }
  };

  const handleAddNewAddress = async (
    name,
    phone,
    pincode,
    address,
    state,
    city,
    locality,
    landmark,
    addressType
  ) => {
    const body = {
      name: name,
      phone: phone,
      pincode: pincode,
      address: address,
      state: state,
      city: city,
      locality: locality,
      landmark: landmark,
      address_type: addressType,
    };
    const resposne = await postData("addAddress", body);
    console.log(resposne);
    window.location.reload(false);
    setOpenDialog(false);
  };

  const handleSelectedAddress = (address) => {
    setSelectedAddress(address);
  };

  const msnArray = userCart?.map((userCart) => {
    return userCart?.cartsDetails?.map((item) => item.msn);
  });

  const handleGoToPayment = async () => {
    const formData = new FormData();
    formData.append("msnArray", msnArray?.join(","));
    const response = await postData("order/create", formData);
    window.location.href = response?.data?.session?.url;
    setSessionId(response.data?.session?.id);
    // console.log(response.data.session.cancel_url);
  };

  const handleCh = async () => {
    const formData = new FormData();
    formData.append("msnArray", msnArray?.join(","));
    formData.append("addressId", selectedAddress);
    const response = await postData("address/order", formData);
    console.log(response);
  };

  const handlePayment = async () => {
    handleCh();
    handleGoToPayment();
  };

  useEffect(() => {
    localStorage.setItem("msnArray", msnArray?.join("-"));
    localStorage.setItem("checkout_session_id", sessionId);
  }, [msnArray, sessionId]);

  return (
    <>
      <Avdert />
      <Header />
      <CustomerDetailsPage
        addresses={addresses}
        userCart={userCart}
        handlePayment={handlePayment}
        handleDialog={handleDialog}
        handleCloseDialog={handleCloseDialog}
        openDialog={openDialog}
        handleDeleteAddress={handleDeleteAddress}
        handleAddNewAddress={handleAddNewAddress}
        handleSelectedAddress={handleSelectedAddress}
      />
      <FooterPage />
    </>
  );
};

export default CustomerDetails;
