import React, { useEffect, useState } from "react";
import "./LoginInterface.css";
import { useNavigate } from "react-router-dom";
import { postData } from "../HTTPConfig/HTTPConfig";
const LoginInterface = () => {
  const navigate = useNavigate();
  const initialTimer = 30;
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [showResendButton, setShowResendButton] = useState(false);
  const [timer, setTimer] = useState(initialTimer);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpError, setOtpError] = useState("");

  useEffect(() => {
    const handleMobile = async (e) => {
      const formData = new FormData();
      formData.append("phone", mobileNumber);
      const response = await postData("checking/existence", formData);
      console.log(response);
    };
    if (mobileNumber.length === 10) {
      handleMobile();
      setIsOtpSent(true);
    }
  }, [mobileNumber]);

  const handleResend = async () => {
    setShowResendButton(false);
    setIsOtpSent(true);
    setTimer(initialTimer);
    const formData = new FormData();
    formData.append("phone", mobileNumber);
    const response = await postData("checking/existence", formData);
    console.log(response);
  };

  useEffect(() => {
    let interval;
    if (timer > 0 && isOtpSent) {
      interval = setInterval(() => {
        setShowResendButton(false);
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timer, isOtpSent]);

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   const formattedSeconds = remainingSeconds.toString().padStart(2, "0");
  //   return `${formattedMinutes}:${formattedSeconds}`;
  // };

  useEffect(() => {
    if (timer === 0) {
      setShowResendButton(true);
      setIsOtpSent(false);
      setOtpError("");
    }
  }, [timer]);

  const check = sessionStorage.getItem("samasva_checkout");

  const handleLogin = async () => {
    if (otp.length === 5) {
      const formData = new FormData();
      formData.append("phone", mobileNumber);
      formData.append("otp", otp);
      const response = await postData("otp/verification", formData);
      console.log("login", response);
      sessionStorage.setItem("samsva_token", response?.data?.token);
      if(!response){
        setOtpError("Invalid OTP")
      } else{
        if (check) {
          navigate("/shopping-bag");
        } else if(response?.data?.page_name==="Register") {
          navigate("/register");
        } else{
          navigate("/");
        }
      }
    }
  };

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  return (
    <>
      {compval?(<div className="loginContainer">
        <img alt="images" src="/assets/Group 211001912.svg" width="100%" />
        <div className="loginContainerDiv">
          <div className="loginHead">
            <img
              style={{ display: "flex", textAlign: "center" }}
              alt="images"
              src="/assets/MainLogo.svg"
              width="64px"
            />
            <div>Login</div>
          </div>
          <div>
            <div className="loginHeads">Phone Number</div>
            <div className="aliggnTB">
              <input
                maxLength={10}
                onChange={(e) => setMobileNumber(e.target.value)}
                className="loginInput"
                type="text"
              />
            </div>
            {showResendButton && (
              <button onClick={handleResend} className="otpGenerateBtn">
                Resend OTP
              </button>
            )}
           
          </div>
          {isOtpSent && (
            <div>
              <div className="loginHeads">OTP</div>
              <input
                onChange={(e) => setOtp(e.target.value)}
                className="loginInput"
                type="text"
                maxLength={5}
              />
              {otpError&&(<div className="errorMessageText">{otpError}</div>)}
              <div className="loginMessageText">Resend OTP in {timer} sec</div>
            </div>
          )}
          {isOtpSent && (
            <button onClick={handleLogin} className="loginBTN">
              Submit
            </button>
          )}
        </div>
      </div>):
      <div className="loginContainer">
      <img alt="images" src="/assets/Frame 15.png" width="100%" />
      <div className="loginContainerDiv">
        <div className="loginHead">
          <img
            style={{ display: "flex", textAlign: "center" }}
            alt="images"
            src="/assets/MainLogo.svg"
            width="64px"
          />
          <div>Login</div>
        </div>
        <div>
          <div className="loginHeads">Phone Number</div>
          <div className="aliggnTB">
            <input
              maxLength={10}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="loginInput"
              type="text"
            />
          </div>
          {showResendButton && (
            <button onClick={handleResend} className="otpGenerateBtn">
              Resend OTP
            </button>
          )}
         
        </div>
        {isOtpSent && (
          <div>
            <div className="loginHeads">OTP</div>
            <input
              onChange={(e) => setOtp(e.target.value)}
              className="loginInput"
              type="text"
              maxLength={5}
            />
            {otpError&&(<div className="errorMessageText">{otpError}</div>)}
            <div className="loginMessageText">Resend OTP in {timer} sec</div>
          </div>
        )}
        {isOtpSent && (
          <button onClick={handleLogin} className="loginBTN">
            Submit
          </button>
        )}
      </div>
    </div>}
    </>
  );
};

export default LoginInterface;
