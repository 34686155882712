import React, { useState } from "react";
import "./Home10.css";
import { postData } from "../../../userInterface/HTTPConfig/HTTPConfig";

const Home10 = () => {
  const [email, setEmail]=useState("");
  const [isSubs, setIsSubs]=useState(false);

  const handleSubscribe = async()=>{
    const response = await postData("email/subscription", {email: email}, {});
    console.log(response);
    setEmail("");
    setIsSubs(true);
  }
  return (
    <>
      <div className="home10Container">
        <div className="home10Container-div1">
          <div className="home10Containertext1">Sign Up for Email</div>
          <div className="home10Containertext2">
            Get special offers, fashion tips, and more!
          </div>
        </div>
        <div className="home10Containers-div2">
          <input
            type="text"
            placeholder="Enter Your Email Address"
            className="home10Container-input"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
          />
          <button className="home10Btn" onClick={()=>{handleSubscribe()}} style={{cursor:"pointer"}}>SUBMIT</button>
        </div>
        {isSubs&&<div className="emsSubsText">Subscribed Successfully</div>}
      </div>
    </>
  );
};

export default Home10;
