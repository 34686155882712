import React, { useEffect, useState } from "react";
import "./SelectSettingView.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../../../userInterface/HTTPConfig/HTTPConfig";
const SelectSettingView = (props) => {
  // const data = [
  //   {
  //     img: "/assets/image 95.svg",
  //     pdname: "Solitaire Engagement Ring",
  //     pdText: "White Gold (18K)",
  //     price: "1,90,000",
  //   },
  //   {
  //     img: "/assets/image 96.svg",
  //     pdname: "Solitaire Engagement Ring",
  //     pdText: "White Gold (18K)",
  //     price: "1,90,000",
  //   },
  //   {
  //     img: "/assets/image 97.svg",
  //     pdname: "Solitaire Engagement Ring",
  //     pdText: "White Gold (18K)",
  //     price: "1,90,000",
  //   },
  //   {
  //     img: "/assets/image 100.svg",
  //     pdname: "Solitaire Engagement Ring",
  //     pdText: "White Gold (18K)",
  //     price: "1,90,000",
  //   },
  // ];

  const  [data, setData] = useState([]);

  useEffect(()=>{
    const fetchData=async()=>{
      const response = await getData("relatedproduct");
      if(response?.data){
        setData(response?.data);
      }
    };
    fetchData();
  },[])

  

  const mapData = () => {
    return data.map((item) => {
      return (
        <div className="selectSettingViewDiv">
          <img alt="images" src={item.productImage?.[0]} width="100%" height={"300px"} />
          <div className="selectSettingViewFont1">{item.p_name}</div>
          <div className="selectSettingViewFont2">{item.pdText}</div>
          <div className="selectSettingViewPrice">{item.discount_price}</div>
        </div>
      );
    });
  };

  // const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="selectSettingViewContainer">
        <div className="selectSettingViewHeading">You May Also Like</div>

        <Hidden smDown>
          <div className="selectSettingViewAlign">{mapData()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider
            className="selectSettingViewAlignSliderMobile"
            // ref={mySlider}
            {...settings}
          >
            {mapData()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default SelectSettingView;
