import React from "react";
import "../ShoppingBagPage/ShoppingBagPage.css";

const ShoppingCard = (props) => {
  const { cart } = props;
  const imageElements = cart.productImage
    .slice(0, 1)
    .map((imgurl, index) => (
      <img
        className="shoppingBagImag"
        key={index}
        src={imgurl}
        alt="images"
      />
    ));

  return <>{imageElements}</>;
};

export default ShoppingCard;
