import React, { createRef } from "react";
import "./JewelleryPage2.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const JewelleryPage2 = (props) => {
  const { dataToDisplay2, compval, dataToDisplay3 } = props;

  const navigate=useNavigate();

  const getHome5data = () => {
    return dataToDisplay2?.subsection.map((item, index) => {
      return (
        <div className="alignHome5ItemsDiv" onClick={()=>{handleCategory(item.title)}} style={{cursor:"pointer"}}>
          <img
            alt="images"
            src={compval ? item.image : item.mobile_image}
            className="home5ContainerImg"
          />
          <div>
            <div className="home5Mhead">{item.title}</div>
            {/* <div className="home5Cre">{item.createdBy}</div> */}
          </div>
        </div>
      );
    });
  };

  const handleCategory=(title)=>{
    if(title==="Rings"){
      sessionStorage.setItem("productPage", "ring");
      navigate("/all-product");
    } else if(title==="Pendants"){
      sessionStorage.setItem("productPage", "pendant");
      navigate("/all-product");
    } else if(title==="Earrings"){
      sessionStorage.setItem("productPage", "earring");
      navigate("/all-product");
    } else if(title==="Bracelet"){
      sessionStorage.setItem("productPage", "bracelet");
      navigate("/all-product");
    }
  };

  const handleDesignClick=(position)=>{
    if(position===1){
      navigate("/select-studs")
    } else if(position===2){
      navigate("/select-necklace");
    }
  }

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="home5Container">
        <div className="home5Heading">{dataToDisplay2?.title}</div>
        <Hidden smDown>
          <div className="alignItemsHome5">{getHome5data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome5data()}
          </Slider>
        </Hidden>
      </div>
      <div className="jw2Conat">
        <Hidden smDown>
          <div className="home3Container">
            {dataToDisplay3?.subsection.map((item, index) => {
              return (
                <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{handleDesignClick(item.position)}}>
                  <img alt="images" width="100%" src={item.image} />
                  <div
                    className={
                      index % 2 === 0
                        ? "home3ContainerDivA"
                        : "home3ContainerDivB"
                    }
                  >
                    <div className="home3ContainerH">{item.title}</div>
                    <div className="home3ContainerT">
                      {item.content.replace(/<\/?p>/g, "")}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Hidden>
        <Hidden smUp>
          <div className="home3Container">
            {dataToDisplay3?.subsection.map((item, index) => {
              return (
                <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{handleDesignClick(item.position)}}>
                  <img alt="images" width="100%" src={item.image} />
                  <div
                    className={
                      index % 2 === 0
                        ? "home3ContainerDivA"
                        : "home3ContainerDivB"
                    }
                  >
                    <div className="home3ContainerH">{item.title}</div>
                    <div className="home3ContainerT">{item.content.replace(/<\/?p>/g, '')}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Hidden>
      </div>
    </>
  );
};

export default JewelleryPage2;
