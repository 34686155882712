import React, { useEffect, useState } from "react";
import "./Home9.css";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../ContactUsDialog/ContactUsDialog";

const Home9 = (props) => {
  const { dataToDisplay } = props;

  const [openDialog, setOpenDialog]=useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    };
  },[]);

  return (
    <>
      <div className="home9Container">
        <div className="home9Container-div">
          <div className="home9Container-heading">{dataToDisplay?.title}</div>
          <div className="home9Container-sub">{dataToDisplay?.content.replace(/<\/?p>/g, '')}</div>
          <div className="home9btn" style={{cursor:"pointer"}} onClick={()=>{handleDialog()}}>Send an enquiry</div>
        </div>
        <div className="home9Container-div">
          <img alt="images" src={dataToDisplay?.static_image} width="100%" />
        </div>
      </div>
      {compval?(<Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "16px",
              width: "100%",
              maxWidth: window.innerWidth / 2,
            },
          },
        }}
        open={openDialog}
      >
        <ContactUsDialog
          handleCloseDialog={handleCloseDialog}
        />
      </Dialog>):(
        <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "16px",
              width: "100%",
              maxWidth: window.innerWidth,
            },
          },
        }}
        open={openDialog}
      >
        <ContactUsDialog
          handleCloseDialog={handleCloseDialog}
        />
      </Dialog>
      )}
    </>
  );
};

export default Home9;
