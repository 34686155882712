import React, { createRef, useEffect, useState } from "react";
import "./EarRingsPage.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";
const EarRingsPage = () => {
  const navigate = useNavigate();

  const [data, setData]=useState([]);
  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/9");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);
  // const data = [
  //   { name: "Diamonds Studs", images: "/assets/image 449.png" },
  //   { name: "Hoop Earrings", images: "/assets/image 448.png" },
  //   { name: "Gold Earrings", images: "/assets/image 447.png" },
  //   { name: "Diamond Earrings", images: "/assets/image 446.png" },
  // ];
  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);

  const alignData = () => {
    return dataToDisplay2?.subsection?.map((item) => {
      return (
        <div className="er2Div">
          <img alt="images" src={item.image} className="er2Img" />
          <div className="er2Text">{item.title}</div>
        </div>
      );
    });
  };

  const handleShopRing=()=>{
    sessionStorage.setItem("productPage", "earring");
    navigate("/all-product");
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Hidden smDown>
        <div className="erContainer1">
          <img
            alt="images"
            src={dataToDisplay1?.static_image}
            loading="lazy"
            className="bpMainImg"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay1?.title}</div>
            <div className="erMainText">
              {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <div>
              <button className="erBtn1" style={{cursor:"pointer"}} onClick={()=>{handleShopRing()}}>Shop Earrings</button>
            </div>
            <div>
              <button className="erBtn2" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-setting")}}>Design your own earrings</button>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="erContainer1">
          <img
            alt="images"
            src="/assets/Group 211001963.png"
            loading="lazy"
            className="bpMainImg"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay1?.title}</div>
            <div className="erMainText">
            {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <div>
              <button className="erBtn1">Shop Earrings Message</button>
            </div>
            <div>
              <button className="erBtn2">Design your own earrings</button>
            </div>
          </div>
        </div>
      </Hidden>

      <div className="erContainer2">
        <div className="er2MainHead">{dataToDisplay2?.title}</div>
        <Hidden smDown>
          <div className="er2AlignData">{alignData()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider className="er2AlignData" ref={mySlider} {...settings}>
            {alignData()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default EarRingsPage;
