import React, { useEffect, useState } from "react";
import "./DiamondProduct.css";
import Header from "../Header/Header";
import SelectSetting2 from "../../components/SelectSettingPage/SelectSetting2/SelectSetting2";
import DiamondProductPage from "../../components/DiamondProductPage/DiamondProductPage";
import { useNavigate } from "react-router-dom";
import Home8 from "../../components/HomePage/Home8/Home8";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
const DiamondProduct = () => {

  const navigate = useNavigate();
  const [diamondStatus, setDiamondStatus] = useState(false);

  const diamonds = [
    {
      shape: "Round",
      color: "L",
      carat: "0.90",
      clarity: "S12",
      cut: "Excellent",
      cert: "GIA",
      price: "190000",
      images: "/assets/image 151.png",
    },
    {
      shape: "Round",
      color: "L",
      carat: "0.90",
      clarity: "S12",
      cut: "Excellent",
      cert: "GIA",
      price: "190000",
      images: "/assets/image 159.png",
    },
    {
      shape: "Round",
      color: "L",
      carat: "0.90",
      clarity: "S12",
      cut: "Excellent",
      cert: "GIA",
      price: "190000",
      images: "/assets/image 153.png",
    },
    {
      shape: "Round",
      color: "L",
      carat: "0.90",
      clarity: "S12",
      cut: "Excellent",
      cert: "GIA",
      price: "190000",
      images: "/assets/image 154.png",
    },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 151.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 159.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 153.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 154.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 151.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 159.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 153.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 154.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 151.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 159.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 153.png",
    // },
    // {
    //   shape: "Round",
    //   color: "L",
    //   carat: "0.90",
    //   clarity: "S12",
    //   cut: "Excellent",
    //   cert: "GIA",
    //   price: "190000",
    //   images: "/assets/image 154.png",
    // },
  ];

  const handleDiamondStatus = () => {
    setDiamondStatus(true);
    navigate("/setting-product", { state: { diamondStatus: diamondStatus } });
  };

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      <SelectSetting2 />
      <DiamondProductPage
        diamonds={diamonds}
        handleDiamondStatus={handleDiamondStatus}
      />

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      )}

      <Home8 />
      <FooterPage />
    </>
  );
};

export default DiamondProduct;
