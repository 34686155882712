import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SelectSetting2 from "../../components/SelectSettingPage/SelectSetting2/SelectSetting2";
import SelectDiamond1C1 from "../../components/SelectDiamond1/SelectDiamond1C1/SelectDiamond1C1";
import SelectDiamond1C2 from "../../components/SelectDiamond1/SelectDiamond1C2/SelectDiamond1C2";
import SelectDiamond1C3 from "../../components/SelectDiamond1/SelectDiamond1C3/SelectDiamond1C3";
import SelectDiamond1C4 from "../../components/SelectDiamond1/SelectDiamond1C4/SelectDiamond1C4";
import SelectDiamond1C5 from "../../components/SelectDiamond1/SelectDiamond1C5/SelectDiamond1C5";
import Home8 from "../../components/HomePage/Home8/Home8";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
const SelectDiamond1 = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      <SelectSetting2 />
      <SelectDiamond1C1 />
      <SelectDiamond1C2 />
      <SelectDiamond1C3 />
      <SelectDiamond1C4 />
      <SelectDiamond1C5 />

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            src="/assets/sd1.webp"
            loading="lazy"
            width="100%"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Your One-of-a-kind Keepsake</h2>
            <p className="homeText">
              Each DNA Diamond is one-of-a-kind, as it carries the distinct
              genetic material of your loved one, making them the perfect
              representation of a truly individual connection. They are created
              using your loved one’s hair, love letters, or any
              carbon-extractable item.
            </p>
            <button className="homeBtn">Know More</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/sd1M.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Your One-of-a-kind Keepsake</h2>
            <p className="homeText">
              Each DNA Diamond is one-of-a-kind, as it carries the distinct
              genetic material of your loved one, making them the perfect
              representation of a truly individual connection. They are created
              using your loved one’s hair, love letters, or any
              carbon-extractable item.
            </p>
            <button className="homeBtn">Know More</button>
          </div>
        </div>
      )}
      <Home8 />
      {compval ? (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src="/assets/home3.webp"
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">Consult with Our Expert</h2>
              <p className="homeText">
                Seek personalized guidance and curated selections to help you
                find the perfect piece that reflects your unique style and
                story.
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        ) : (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src="/assets/Group 1000002162.png"
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">Consult with Our Expert</h2>
              <p className="homeText">
                Seek personalized guidance and curated selections to help you
                find the perfect piece that reflects your unique style and
                story.
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        )}
      <FooterPage />
    </>
  );
};

export default SelectDiamond1;
