import React, { useState } from "react";
import "./SelectDiamond1C1.css";

const SelectDiamond1C1 = () => {
  const [select1 ,setSelect1]=useState("");
  const handleSelect=(option)=>{
    setSelect1(option);
  }
  return (
    <>
      <div className="sd1Container">
        <div className="sd1Head">Shop by Centre Stone</div>
        <div className="sd1AlignDiv">
          <div className="sd1Divs" onClick={()=>{handleSelect("nat")}} style={{backgroundColor:select1==="nat"?"#30373E":"", color:select1==="nat"?"#fff":"", cursor:"pointer"}}>Natural Diamond</div>
          <div className="sd1Divs" onClick={()=>{handleSelect("lab")}} style={{backgroundColor:select1==="lab"?"#30373E":"", color:select1==="lab"?"#fff":"", cursor:"pointer"}}>Lab-Grown Diamond</div>
          <div className="sd1Divs" onClick={()=>{handleSelect("dna")}} style={{backgroundColor:select1==="dna"?"#30373E":"", color:select1==="dna"?"#fff":"", cursor:"pointer"}}>DNA Diamond</div>
        </div>
      </div>
    </>
  );
};

export default SelectDiamond1C1;
