import React, { useEffect } from "react";
import "./FooterPage.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { useNavigate } from "react-router-dom";
const FooterPage = () => {
  const navigate = useNavigate();
  const handleBG = () => {
    navigate("/buying-guide");
  };

  const handleAura = () => {
    navigate("/diamond-page");
  };

  const handleDna = () => {
    navigate("/diamond-dna");
  };

  const handleContact = () => {
    navigate("/contact-us");
  };

  const handleBuyBac = () => {
    navigate("/buy-bac");
  };

  useEffect(() => {
    // Scroll to the top of the page when component is mounted
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="footerContainer">
        <div>
          <div className="footerheading">About us</div>
          <div className="footerTexts">Our Story</div>
          <div className="footerTexts">Our Programs</div>
        </div>
        <div>
          <div className="footerheading">Education</div>
          <div onClick={handleBG} className="footerTexts">
            Diamond Buying Guide
          </div>
          <div onClick={handleAura} className="footerTexts">
            Aura Diamonds
          </div>
          <div onClick={handleDna} className="footerTexts">
            DNA Diamonds
          </div>
          <div className="footerTexts">Blog</div>
        </div>
        <div>
          <div className="footerheading">Customer Care</div>
          <div className="footerTexts">FAQ</div>
          <div onClick={handleContact} className="footerTexts">
            Contact us
          </div>
          <div onClick={handleBuyBac} className="footerTexts">
            Buy<span className="footerheading">BAC</span> Program
          </div>
          <div className="footerTexts">Consult with an expert</div>
        </div>
        <div>
          <div className="footerheading">Legal & Privacy</div>
          <div className="footerTexts">Privacy Policy</div>
          <div className="footerTexts">Terms & Conditions</div>
        </div>
      </div>

      <div className="footerAlignIcons">
        <LinkedInIcon />
        <YouTubeIcon />
        <TwitterIcon />
        <FacebookIcon />
      </div>

      <div className="footerNote">2023 - All Rights Reserved. Samasva</div>
    </>
  );
};

export default FooterPage;
