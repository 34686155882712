import React, { useCallback, useEffect, useState } from "react";
import "./ProfilePage.css";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const fetchData = useCallback(async () => {
    const response = await getData("fetchUser");
    if (response?.data) {
      setData(response?.data);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleLogout = async()=>{
    const response=await getData("logout");
    if(response.data){
      sessionStorage.clear();
      navigate("/");
      window.location.reload();
    }
  };
  return (
    <>
      <div className="ppp">
        <div className="pppContainer">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <img alt="profile" src="/assets/Mask group (4).png" width="96px" />
            <div className="ppUserName">
              <div>{data?.name}</div>
              <img src="/assets/image 480.png" alt="images" width="16px" height="16px" />
            </div>
          </div>
          <div>
            <div className="pppHead">Your Profile</div>
            <div className="pppText">
              Your profile preferences help us personalise recommendations for
              you.
            </div>

            <div className="ppDivider"></div>

            <div className="pppBg">
              <div className="pppHead1">Phone Number</div>
              <div className="pppText">{data?.phone}</div>
            </div>
            <div className="pppBg">
              <div className="pppHead1">Full Name</div>
              <div className="pppText">{data?.name}</div>
            </div>
            <div className="pppBg">
              <div className="pppHead1">Email</div>
              <div className="pppText">{data?.email}</div>
            </div>
            <div className="pppbtncontainer">
            <div className="pppAddress" onClick={()=>{navigate("/my-addresses")}}>My Addresses</div>
            <div className="pppAddress" onClick={()=>{navigate("/my-order")}}>My Orders</div>
            </div>
            {/* <div className="ppGender">
              <div className="ppGA">Male</div>
              <div className="ppGU">Female</div>
            </div>
            <div className="pppBg">
              <div className="pppHead1">BirthDate</div>
              <div className="pppText">02/02/1998</div>
            </div> */}
            <button className="logoutBTN" onClick={() => {handleLogout()}}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
