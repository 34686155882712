import React from "react";

const SelectSetting21 = (props) => {
  const { active } = props;
  const cPage = sessionStorage.getItem("cPage");
  return (
    <>
      <div className="selectSetting2Container1">
        <div className="selectSetting2Container1Sub">
          <div
            className={cPage?"selectSetting2BoxesA":"selectSetting2BoxesU"}
          >
            <div
              className={cPage?"selectSetting2NumA":"selectSetting2NumU"}
            >
              1
            </div>
            <div
              className={cPage?"selectSetting2TextA":"selectSetting2TextU"}
            >
              {cPage?"Choose Diamond":"Select Setting"}
            </div>
          </div>
          <div
            className={
              cPage ? "selectSetting2BoxesU" : "selectSetting2BoxesA"
            }
          >
            <div
              className={cPage ? "selectSetting2NumU" : "selectSetting2NumA"}
            >
              2
            </div>
            <div
              className={
                cPage ? "selectSetting2TextU" : "selectSetting2TextA"
              }
            >
              {cPage?"Select Setting":"Choose Diamond"}
            </div>
          </div>
          <div
            className={
              !active ? "selectSetting2BoxesU" : "selectSetting2BoxesA"
            }
          >
            <div
              className={!active ? "selectSetting2NumU" : "selectSetting2NumA"}
            >
              3
            </div>
            <div
              className={
                !active ? "selectSetting2TextU" : "selectSetting2TextA"
              }
            >
              Complete Ring
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSetting21;
