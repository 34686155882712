import React from "react";
import "./Avdert.css";
const Avdert = () => {
  return (
    <>
      <div className="advertContainer">
        First time in America by Samasva - Introducing BuyBAC Program, - Get 85%
        exchange value on Aura Diamonds.
      </div>
    </>
  );
};

export default Avdert;
