import React from "react";
import "./SettingProductPage.css";
// import { useNavigate } from "react-router-dom";
const SettingProductPage = (props) => {
  const { data, handleAddToCart, msn, handleColorId, handleAttr } = props;
  const { product_data, color, product_attribute } = data;
  const status = false;

  console.log("data", data);

  // const handleSetting = () => {
  //   navigate("/select-diamond");
  //   setStatus(false);
  // };

  const getColors = () => {
    return color?.map((item, index) => {
      return (
        <div key={index} className="productSettingFilterAlignColors">
          <div
            onClick={() => handleColorId(item.pcolorId, product_data?.prtid)}
            className={
              item.selected === 1
                ? "productSettingFilterColorsSize"
                : "productSettingFilterColorsSizeA"
            }
            style={{ backgroundColor: `${item.color}` }}
          ></div>
          <div className="productsSettingFilterColorsFont">{item.color}</div>
        </div>
      );
    });
  };

  const getProngs = () => {
    return product_attribute?.map((product_attribute, index) => {
      return (
        <>
          <div key={index} className="settingProductPageDiv2Heading1">
            {product_attribute?.attribute_name}
          </div>
          <div
            style={{
              display: "flex",
              gap: "16px",
              textAlign: "center",
            }}
          >
            {product_attribute?.attribute_value_data?.map((item) => {
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "16px",
                    textAlign: "center",
                    flexDirection: "column",
                  }}
                  onClick={() =>
                    handleAttr(
                      product_data?.prtid,
                      product_data?.pcolorId,
                      product_attribute?.position,
                      item.cust_attr_value
                    )
                  }
                >
                  <img alt="images" src={item?.attribute_image} width="64px" />
                  <div className="productSettingFilterAlignColors1">
                    {item?.cust_attr_value}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      );
    });
  };

  // const firstImg = imageElements[0];
  // const secondImg = imageElements[1];
  // const thirdImg = imageElements[2];
  // const lastImge = imageElements[3];

  return (
    <>
      <div className="settingProductPageContainer">
        <div className="settingProductPageDiv1">
          <div className="settingProductImgDiv1">
            {product_data?.imgurl?.map((item, index) => {
              return (
                index === 0 && (
                  <img
                    className="settingProductImg1"
                    key={index}
                    src={item}
                    alt="images"
                  />
                )
              );
            })}
          </div>
          <div className="settingProductImgDiv2">
            {product_data?.imgurl?.map((item, index) => {
              return (
                index > 0 && (
                  <img
                    className="settingProductImg2"
                    key={index}
                    src={item}
                    alt="images"
                  />
                )
              );
            })}
          </div>
        </div>

        {/* {status && (
          <div className="settingProductPageDiv2">
            <div className="settingProductPageDiv2HeadingReview">
              Review Your Order
            </div>
            <div className="alignReviewOrder">
              <img
                alt="images"
                src="/assets/image 169.png"
                className="shoppingBagImag1"
              />
              <div className="alignReviewOrderdiv">
                <div className="alignReviewOrderPName">Kindrea</div>
                <div className="alignReviewOrderPgrade">White Gold (18k)</div>
                <div className="alignReviewOrder1">
                  <div className="productSettingPrice1">INR 1,90,000</div>
                  <div>Change</div>
                </div>
              </div>
            </div>
            <div className="alignReviewOrder">
              <img
                alt="images"
                src="/assets/image 170.png"
                className="shoppingBagImag1"
              />
              <div className="alignReviewOrderdiv">
                <div className="alignReviewOrderPName">
                  0.30ct Round Cut diamond
                </div>
                <div className="alignReviewOrderPgrade">
                  Cut: Excellent | Colour: I | Clarity: SI2
                </div>
                <div className="alignReviewOrder1">
                  <div className="productSettingPrice1">INR 1,90,000</div>
                  <div>Change</div>
                </div>
              </div>
            </div>

            <div style={{ paddingLeft: "16px" }}>
              <div>Total Price</div>
              <div className="productSettingPrice">
                INR {data.original_price}
              </div>
            </div>

            <div>
              <input
                type="button"
                className="productSettingbtn"
                value="Select Ring Size"
                onClick={handleSetting}
              />
              <input
                type="button"
                className="productSettingbtn"
                value="Add To Bag"
                onClick={handleSetting}
              />
            </div>
          </div>
        )} */}

        {!status && (
          <div className="settingProductPageDiv2">
            <div className="settingProductPageDiv2Heading">
              {product_data?.p_name}
            </div>
            <div className="settingProductPageDiv2Texts">
              Infinitely scintillating. The soft curve of the Kindrea ring, set
              with cut-down diamonds, gives it seemingly endless shimmer and a
              vintage feel.
            </div>
            {/* <div className="settingProductPageDiv2Heading1">Shop By Shape</div>
            <div className="productSettingFilterAlignShapes">{getShape()}</div> */}
            <div className="settingProductPageDiv2Heading1">Shop By Color</div>
            <div className="productSettingFilterAlignColors1">
              {getColors()}
            </div>
            {getProngs()}
            <div className="productSettingPrice">
            USD {product_data?.discounted_price}
            </div>
            <div className="settingProductPageDiv2Texts1">
              Price is for setting only.
            </div>
            <div>
              <button
                onClick={() => handleAddToCart(msn, product_data?.diamond_select, product_data?.prtid)}
                className="productSettingbtn"
              >
                Select this Setting
              </button>
            </div>

            <div className="productSettingRingDetails">Ring Details</div>
            <div className="productSettingRingDetails1">
              <div className="productSettingAlignDetails">
                <div>Style</div>
                <div>Metal</div>
                <div>Prong Metal</div>
                <div>Average Width</div>
                <div>Setting</div>
              </div>
              <div className="productSettingDivider"></div>
              <div className="productSettingAlignDetails">
                <div>BE1199-18KY</div>
                <div>18K Yellow Gold</div>
                <div>14K White Gold</div>
                <div>1.5 mm</div>
                <div>Four-Prong</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SettingProductPage;
