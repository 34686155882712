import React, { useEffect, useState } from "react";
import BuyingGuidePage1 from "../../components/BuyingGuidePage/BuyingGuidePage1/BuyingGuidePage1";
import Header from "../Header/Header";
import BuyingGuidePage2 from "../../components/BuyingGuidePage/BuyingGuidePage2/BuyingGuidePage2";
import BuyingGuidePage3 from "../../components/BuyingGuidePage/BuyingGuidePage3/BuyingGuidePage3";
import BuyingGuidePage4 from "../../components/BuyingGuidePage/BuyingGuidePage4/BuyingGuidePage4";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import Home10 from "../../components/HomePage/Home10/Home10";
import Home8 from "../../components/HomePage/Home8/Home8";
import { getData } from "../HTTPConfig/HTTPConfig";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
const BuyingGuide = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/1");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const dataToDisplay = data.find((data) => data.section === 10);
  return (
    <>
      <Avdert />
      <Header />
      <BuyingGuidePage1 />
      <BuyingGuidePage2 />
      <BuyingGuidePage3 />
      <BuyingGuidePage4 />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      )}
      <Home8 dataToDisplay={dataToDisplay} />
      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};
export default BuyingGuide;
