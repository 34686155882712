import React, { useEffect, useState } from "react";
import "./Home4.css";
import { useNavigate } from "react-router-dom";

const Home4 = (props) => {
  const { dataToDisplay } = props;
  const [compval, setCompval] = useState(false);

  const navigate=useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const handleClickRedirect=(id)=>{
    if(id===1){
      navigate("/select-diamond");
    } else if(id===2){
      navigate("/select-setting");
    }
  }

  return (
    <>
      <div>
        {compval ? (
          <div className="home4Div">
            <img
              alt="images"
              className="home43Img1"
              src={dataToDisplay?.static_image}
              loading="lazy"
            />
            <div className="home4Div12">
              <h2 className="home4Head">{dataToDisplay?.title}</h2>
              <p className="home4Text">
                {dataToDisplay?.content.replace(/<\/?p>/g, "")}
              </p>
              <div>
                {dataToDisplay?.subsection?.map((item, index) => {
                  return (
                    <div key={index} className="alignHome4Div" style={{cursor:"pointer"}} onClick={()=>{handleClickRedirect(item.position)}}>
                      <img alt="images" src={item.image} className="home4Img" />
                      <div>
                        <p className="home4Head1">{item.title}</p>
                        <p className="home4Text1">
                          {item.content.replace(/<\/?p>/g, "")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div className="home4Div">
            <img
              alt="images"
              className="home43Img1"
              src={dataToDisplay?.mobile_image}
              loading="lazy"
            />
            <div className="home4Div12">
              <h2 className="home4Head">{dataToDisplay?.title}</h2>
              <p className="home4Text">
                {dataToDisplay?.content.replace(/<\/?p>/g, "")}
              </p>
              <div>
                {dataToDisplay?.subsection?.map((item, index) => {
                  return (
                    <div key={index} className="alignHome4Div" style={{cursor:"pointer"}} onClick={()=>{handleClickRedirect(item.position)}}>
                      <img alt="images" src={item.image} className="home4Img" />
                      <div>
                        <p className="home4Head1">{item.title}</p>
                        <p className="home4Text1">
                          {item.content.replace(/<\/?p>/g, "")}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Home4;
