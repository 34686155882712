import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SelectDiamondPage from "../../components/SelectDiamondPage/SelectDiamondPage";
import SelectSetting21 from "../../components/SelectSettingPage/SelectSetting21/SelectSetting21";
import { useNavigate } from "react-router-dom";
// import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import "./SelectDiamond.css";
// import { getDiamondData } from "../HTTPConfig/HTTPConfig";
import "../../components/SelectDiamondPage/SelectDiamondPage1/SelectDiamondPage1.css";
import ReactSlider from "react-slider";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
import { Audio } from "react-loader-spinner";
import axios from "axios";

const SelectDiamond = () => {
  const navigate = useNavigate();

  const [diamonds, setDiamonds] = useState([]);

  const handleAddRing = async (id) => {
    // navigate("/diamond-product");
    // navigate("/select-setting");
    // const userToken = sessionStorage.getItem("samsva_token");
    // const msn=sessionStorage.getItem("msn");

    const msn = sessionStorage.getItem("msn");
    const prtid = sessionStorage.getItem("prtid");
    if (msn && prtid) {
      navigate("/complete-ring", {
        state: { diamond_id: id, prtid: prtid, msn: msn },
      });
    } else {
      sessionStorage.setItem("diamond_id", id);
      navigate("/select-setting");
    }

    // if(userToken){
    //   // const header = {diamond_id:id};
    //   // const response = await getData(`postMsnToCart/${msn}`, header);
    //   // if(response){
    //   //   navigate("/shopping-bag");
    //   // }
    //   const msn=sessionStorage.getItem("msn");
    //   const prtid=sessionStorage.getItem("prtid");
    //   navigate("/complete-ring", {state:{diamond_id:id, prtid: prtid, msn: msn}});
    // } else{
    //   const msn=sessionStorage.getItem("msn");
    //   const prtid=sessionStorage.getItem("prtid");
    //   navigate("/complete-ring", {state:{diamond_id:id, prtid: prtid, msn: msn}});
    //   // const header ={diamond_id:id};
    //   // const response=await getData(`guestaddtocart/${msn}`, header);
    //   // if(response){
    //   //   navigate("/shopping-bag");
    //   // }
    // }
    // // const msn=sessionStorage.getItem("msn");
    // // const header ={diamond_id:id};
    // // const response=await getData(`guestaddtocart/${msn}`, header);
    // // if(response){
    // //   navigate("/shopping-bag");
    // // }
  };

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  // const filterDiamondsData = JSON.parse(sessionStorage.getItem("diamondsProductData"));
  // const [newFilterData, setNewFilterData] = useState(filterDiamondsData)
  //   useEffect(() => {
  //     const checkNewFilterData = () => {
  //     if (filterDiamondsData !== newFilterData) {
  //       setNewFilterData(filterDiamondsData);
  //     }
  //     };
  //     checkNewFilterData();
  //   }, []);
  // const [updatedFilter, setUpdatedFilter] = useState([]);

  // useEffect(() => {
  //   const filterDiamondsData = JSON.parse(sessionStorage.getItem("diamondsProductData"));
  //   const newFilterData = filterDiamondsData;
  //   if(filterDiamondsData !== newFilterData){
  //     setUpdatedFilter(newFilterData);
  //     setDiamonds(updatedFilter);
  //     console.log("upadated filter",updatedFilter);
  //   };
  // },[]);

  const [selectedShape, setSelectedShape] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedClarity, setSelectedClarity] = useState("");
  const [selectedCut, setSelectedCut] = useState("");
  const [selectedCutEnum, setSelectedCutEnum] = useState("");
  console.log(selectedCutEnum);
  const [selectedFlourscence, setSelectedFlourscence] = useState("");
  const [selectedFlourscenceEnum, setSelectedFlourscenceEnum] = useState("");
  console.log(selectedFlourscenceEnum);
  const [selectedPolish, setSelectedPolish] = useState("");
  const [selectedPolishEnum, setSelectedPolishEnum] = useState("");
  console.log(selectedPolishEnum);
  const [selectedCertificate, setSelectedCertificate] = useState("");
  const [selectedSymmetry, setSelectedSymmetry] = useState("");
  const [selectedSymmetryEnum, setSelectedSymmetryEnum] = useState("");
  console.log(selectedSymmetryEnum);

  const [checkedValues, setCheckedValues] = useState([]);
  // const [selectedCarat, setSelectedCarat] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const minsellingPrice = 300;
  const maxsellingPrice = 1000000;
  const [priceRange, setPriceRange] = useState([
    parseInt(minsellingPrice),
    parseInt(maxsellingPrice),
  ]);

  const minSize = 0.25;
  const maxSize = 10.53;
  const [sizeRange, setSizeRange] = useState([
    parseInt(minSize),
    parseInt(maxSize),
  ]);

  const minTable = 0;
  const maxTable = 100;
  const [tableRange, setTableRange] = useState([
    parseInt(minTable),
    parseInt(maxTable),
  ]);

  const minDepth = 0;
  const maxDepth = 100;
  const [depthRange, setDepthRange] = useState([
    parseInt(minDepth),
    parseInt(maxDepth),
  ]);

  const minLW = 0;
  const maxLW = 5;
  const [lwRange, setLWRange] = useState([parseInt(minLW), parseInt(maxLW)]);

  // useEffect(() => {
  //   const fetchAttributeFilter=async()=>{
  //     const header = {shape:selectedShape,color:selectedColor,clarity:selectedClarity,carat:selectedCarat,cut:selectedCut,fluorescence:selectedFlourscence,polish:selectedPolish,symmetry:selectedSymmetry,certificate:selectedCertificate,start_price:priceRange[0],end_price:priceRange[1]}
  //     const response =await getData("diamondproductfilterattribute", header);
  //     if(response?.data){
  //       setDiamonds(response?.data);
  //       console.log(header);
  //     }
  //   };
  //   fetchAttributeFilter();
  // }, [selectedShape, selectedColor, selectedClarity, selectedCut, selectedFlourscence, selectedPolish, selectedCertificate, selectedSymmetry,priceRange,selectedCarat]);

  // const userName = "neha@vijisan.com";
  // const userName1 = "testaccount@sample.com";
  // const password = "Newyork$2002";
  // const password1 = "staging-nivoda-22";

  // let authenticate_query = `{authenticate { username_and_password(username: "${userName}", password: "${password}") {token}}}`;

  const [count, setCount] = useState(0);
  const [count1, setCount1] = useState(50);

  const handleViewMore = () => {
    setCount((prevCount) => prevCount + 50);
    setCount1((prevCount1) => prevCount1 + 50);
  };

  // let diamond_query = `
  //   query {
  //     diamonds_by_query(
  //       query: {
  //         labgrown: true,
  //         shapes: [${selectedShape ? `"${selectedShape}"` : ""}],
  //         sizes: [{ from: ${sizeRange[0]}, to: ${sizeRange[1]} }],
  //         has_v360: true,
  //         has_image: true,
  //         color: [${selectedColor ? `${selectedColor}` : ""}],
  //         clarity: [${selectedClarity ? `${selectedClarity}` : ""}],
  //         cut: [${selectedCutEnum ? `${selectedCutEnum}` : ""}],
  //         flouresence: [${
  //           selectedFlourscenceEnum ? `${selectedFlourscenceEnum}` : ""
  //         }],
  //         polish: [${selectedPolishEnum ? `${selectedPolishEnum}` : ""}],
  //         certificate_lab: [${
  //           selectedCertificate ? `${selectedCertificate}` : ""
  //         }],
  //         symmetry: [${selectedSymmetryEnum ? `${selectedSymmetryEnum}` : ""}],
  //         dollar_value: { from: ${priceRange[0]}, to: ${priceRange[1]} },
  //         table_percentage: {from: ${tableRange[0]}, to: ${tableRange[1]}},
  //         depth_percentage: {from: ${depthRange[0]}, to: ${depthRange[1]}},
  //         ratio: {from: ${lwRange[0]}, to: ${lwRange[1]}}
  //       },
  //       offset: 0,
  //       limit: ${count + 20},
  //       order: { type: price, direction: ASC }
  //     ) {
  //       items {
  //         id
  //         diamond {
  //           id
  //           video
  //           image
  //           availability
  //           supplierStockId
  //           brown
  //           green
  //           milky
  //           eyeClean
  //           mine_of_origin
  //           certificate {
  //             id
  //             lab
  //             shape
  //             certNumber
  //             cut
  //             carats
  //             clarity
  //             polish
  //             symmetry
  //             color
  //             width
  //             length
  //             depth
  //             girdle
  //             floInt
  //             floCol
  //             depthPercentage
  //             table
  //           }
  //         }
  //         price
  //         discount
  //       }
  //       total_count
  //     }
  //   }
  // `;
  // const [authenticateToken, setAuthenticateToken] = useState("");

  // const [newDia, setNewDia]=useState([]);
  // const API_URL = "http://wdc-intg-customer-staging.herokuapp.com/api/diamonds";
  // const API_URL = "https://intg-customer-staging.nivodaapi.net/api/diamonds";
  // useEffect(() => {
  //   const fetchDiamondsData = async () => {
  //     // const response = await getDiamondData(
  //     //   "diamonds",
  //     //   JSON.stringify({ query: authenticate_query })
  //     // );
  //     // setAuthenticateToken(
  //     //   response?.data?.authenticate?.username_and_password?.token
  //     // );
  //     let authenticate_result = await fetch(API_URL, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ query: authenticate_query }),
  //     });
  //     let res = await authenticate_result?.json();
  //     let { token } = res?.data?.authenticate?.username_and_password;
  //     setAuthenticateToken(token && token);
  //   };
  //   fetchDiamondsData();
  // }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // setIsLoading(true);
  //     let result = await fetch(API_URL, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authenticateToken}`,
  //       },
  //       body: JSON.stringify({ query: diamond_query }),
  //     });

  //     let diamond_res = await result?.json();
  //     if (diamond_res?.data?.diamonds_by_query?.items) {
  //       setDiamonds(diamond_res?.data?.diamonds_by_query?.items);
  //     }
  //     // setIsLoading(false);
  //   };
  //   fetchData();
  // }, [authenticateToken]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `https://samasva-production.alphaprotocall.com/public/api/user/diamonddata/${count}/${count1}`
        );

        const data = response.data;
        setDiamonds(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 2 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [count, count1]);

  console.log(count, count1, "count");
  // const [color, setColor] = useState([]);
  const color = ["D", "E", "F", "G", "H", "I", "J", "K", "L"];

  const getColors = () => {
    return color?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondColorSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedColor === item ? "#30373E" : "",
            color: selectedColor === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectColor(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  const clarity = ["SI2", "SI1", "VS2", "VS1", "VVS2", "VVS1", "IF", "FL"];
  // const [clarity, setClarity] = useState([]);

  const getClarity = () => {
    return clarity?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondCalritySize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedClarity === item ? "#30373E" : "",
            color: selectedClarity === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectClarity(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [cut, setCut] = useState([]);
  const cut = [
    "Samasva ideal cut",
    "Ideal cut",
    "Good",
    "Very Good",
    "Excellent",
  ];

  const getCuts = () => {
    return cut?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondCutSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedCut === item ? "#30373E" : "",
            color: selectedCut === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectCut(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [flourscence, setFlourscence] = useState([]);
  const flourscence = ["None", "Faint", "Medium", "Strong", "Very Strong"];

  // if(flourscence === "None"){
  //   setSelectedFlourscence("NON");
  // } else if(flourscence === "Faint"){
  //   setSelectedFlourscence("FNT");
  // } else if(flourscence === "Medium"){
  //   setSelectedFlourscence("MED");
  // } else if(flourscence === "Strong"){
  //   setSelectedFlourscence("STG");
  // } else if(flourscence === "Very Strong"){
  //   setSelectedFlourscence("VST");
  // }

  const getflour = () => {
    return flourscence?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondFlourSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedFlourscence === item ? "#30373E" : "",
            color: selectedFlourscence === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectFlourscence(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [polish, setPolish] = useState([]);
  const polish = ["Fair", "Good", "Very Good", "Excellent"];

  const getPolish = () => {
    return polish?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondPolishSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedPolish === item ? "#30373E" : "",
            color: selectedPolish === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectPolish(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [symmetry, setSymmetry] = useState([]);
  const symmetry = ["Fair", "Good", "Very Good", "Excellent"];

  const getSymm = () => {
    return symmetry?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondSymmSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedSymmetry === item ? "#30373E" : "",
            color: selectedSymmetry === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectSymmetry(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [certificate, setCertificate] = useState([]);
  const certificate = ["GIA", "HRD", "IGI"];

  const getCertificate = () => {
    return certificate?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondCertificateSize"
          style={{
            cursor: "pointer",
            backgroundColor: selectedCertificate === item ? "#30373E" : "",
            color: selectedCertificate === item ? "#fff" : "",
          }}
          onClick={() => {
            handleSelectCertificate(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  // const [diamondsData, setDiamondsData] = useState([]);
  const diamondsData = [
    "ROUND",
    "PRINCESS",
    "CUSHION",
    "OVAL",
    "PEAR",
    "EMERALD",
    "HEART",
    "MARQUISE",
    "RADIANT",
    "ASSCHER",
  ];

  const getDiamons = () => {
    return diamondsData?.map((item, index) => {
      return (
        <div
          key={index}
          className="selectDiamondPage1Managedata"
          style={{
            cursor: "pointer",
            fontWeight: selectedShape === item ? "bolder" : "",
          }}
          onClick={() => {
            handleSelectShape(item);
          }}
        >
          <img
            alt="images"
            src={
              item === "ROUND"
                ? "/assets/image 113.svg"
                : item === "PRINCESS"
                ? "/assets/image 114.svg"
                : item === "CUSHION"
                ? "/assets/image 115.svg"
                : item === "OVAL"
                ? "/assets/image 116.svg"
                : item === "PEAR"
                ? "/assets/image 117.svg"
                : item === "EMERALD"
                ? "/assets/image 118.svg"
                : item === "HEART"
                ? "/assets/image 119.svg"
                : item === "MARQUISE"
                ? "/assets/image 120.svg"
                : item === "RADIANT"
                ? "/assets/image 121.svg"
                : "/assets/image 122.svg"
            }
            className="selectDiamondPage1ImgSize"
          />
          <div>{item}</div>
        </div>
      );
    });
  };

  // const [carat, setCarat] = useState([]);
  // const carat = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

  // const getCarat = () => {
  //   return carat?.map((item, index) => {
  //     return <div key={index} className="selectDiamondCertificateSize" style={{cursor:"pointer", backgroundColor:selectedCarat===item?"#30373E":"", color:selectedCarat===item?"#fff":""}} onClick={()=>{handleSelectCarat(item)}}>{item?item:"0"}</div>;
  //   });
  // };

  // useEffect(()=>{
  //   const fetchDiamondsData=async()=>{
  //     const response = await getData("diamondattribute");
  //     setDiamondsData(response?.data?.shape);
  //     setCertificate(response?.data?.certificate);
  //     setColor(response?.data?.color);
  //     setClarity(response?.data?.clarity);
  //     setCut(response?.data?.cut);
  //     setFlourscence(response?.data?.fluorescence);
  //     setPolish(response?.data?.polish);
  //     setSymmetry(response?.data?.symmetry);
  //     setCarat(response?.data?.carat);
  //     // console.log(response?.data);
  //   };
  //   fetchDiamondsData();
  // },[]);

  const handleSelectShape = (shape) => {
    if (selectedShape === shape) {
      setSelectedShape("");
    } else {
      setSelectedShape(shape);
    }
  };

  const handleSelectColor = (color) => {
    if (selectedColor === color) {
      setSelectedColor("");
    } else {
      setSelectedColor(color);
    }
  };

  const handleSelectClarity = (clarity) => {
    if (selectedClarity === clarity) {
      setSelectedClarity("");
    } else {
      setSelectedClarity(clarity);
    }
  };

  const handleSelectCut = (cut) => {
    if (selectedCut === cut) {
      setSelectedCut("");
      setSelectedCutEnum("");
    } else {
      setSelectedCut(cut);
      if (cut === "Good") {
        setSelectedCutEnum("GD");
      } else if (cut === "Very Good") {
        setSelectedCutEnum("VG");
      } else if (cut === "Excellent") {
        setSelectedCutEnum("EX");
      } else if (cut === "Ideal cut") {
        setSelectedCutEnum("ID");
      }
    }
  };

  const handleSelectFlourscence = (flourscence) => {
    if (selectedFlourscence === flourscence) {
      setSelectedFlourscence("");
      setSelectedFlourscenceEnum("");
    } else {
      setSelectedFlourscence(flourscence);
      if (flourscence === "None") {
        setSelectedFlourscenceEnum("NON");
      } else if (flourscence === "Faint") {
        setSelectedFlourscenceEnum("FNT");
      } else if (flourscence === "Medium") {
        setSelectedFlourscenceEnum("MED");
      } else if (flourscence === "Strong") {
        setSelectedFlourscenceEnum("STG");
      } else if (flourscence === "Very Strong") {
        setSelectedFlourscenceEnum("VST");
      }
    }
  };

  const handleSelectPolish = (polish) => {
    if (selectedPolish === polish) {
      setSelectedPolish("");
      setSelectedPolishEnum("");
    } else {
      setSelectedPolish(polish);
      if (polish === "Fair") {
        setSelectedPolishEnum("FR");
      } else if (polish === "Good") {
        setSelectedPolishEnum("GD");
      } else if (polish === "Very Good") {
        setSelectedPolishEnum("VG");
      } else if (polish === "Excellent") {
        setSelectedPolishEnum("EX");
      }
    }
  };

  const handleSelectCertificate = (certificate) => {
    if (selectedCertificate === certificate) {
      setSelectedCertificate("");
    } else {
      setSelectedCertificate(certificate);
    }
  };

  const handleSelectSymmetry = (symmetry) => {
    if (selectedSymmetry === symmetry) {
      setSelectedSymmetry("");
      setSelectedSymmetryEnum("");
    } else {
      setSelectedSymmetry(symmetry);
      if (symmetry === "Fair") {
        setSelectedSymmetryEnum("FR");
      } else if (symmetry === "Good") {
        setSelectedSymmetryEnum("GD");
      } else if (symmetry === "Very Good") {
        setSelectedSymmetryEnum("VG");
      } else if (symmetry === "Excellent") {
        setSelectedSymmetryEnum("EX");
      }
    }
  };

  // const handleSelectCarat = (carat) => {
  //   if(selectedCarat === carat){
  //     setSelectedCarat("");
  //   } else{
  //     setSelectedCarat(carat);
  //   };
  // };

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  function handleCheckboxChange(event) {
    const value = event.target.value;
    const checked = event.target.checked;

    if (checked) {
      // If the checkbox is checked, add its value to the array
      setCheckedValues((oldValues) => [...oldValues, value]);
    } else {
      // If the checkbox is unchecked, remove its value from the array
      setCheckedValues((oldValues) =>
        oldValues.filter((oldValue) => oldValue !== value)
      );
    }

    console.log(checkedValues);
  }

  const [moreFilters, setMoreFilters] = useState(true);
  const handleMoreFilters = () => {
    setMoreFilters(!moreFilters);
  };

  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      <SelectSetting21 />
      <div className="selectDiamondPage1Container">
        <div className="selectDiamondPage1heading">Shop By Shape</div>
        <div className="selectDiamondPage1AlignData">{getDiamons()}</div>

        <div className="selectDiamondAlignGridDivs">
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Colors</div>
            <div className="selectDiamondAlignGridDivsData">{getColors()}</div>
          </div>
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Clarity</div>
            <div className="selectDiamondAlignGridDivsData">{getClarity()}</div>
          </div>
        </div>

        <div className="selectDiamondAlignGridDivs">
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Cut</div>
            <div className="selectDiamondAlignGridDivsData">{getCuts()}</div>
          </div>
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Flourcsence</div>
            <div className="selectDiamondAlignGridDivsData">{getflour()}</div>
          </div>
        </div>

        <div className="selectDiamondAlignGridDivs">
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Polish</div>
            <div className="selectDiamondAlignGridDivsData">{getPolish()}</div>
          </div>
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Certificate</div>
            <div className="selectDiamondAlignGridDivsData">
              {getCertificate()}
            </div>
          </div>
        </div>

        <div className="selectDiamondAlignGridDivs">
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Symmetry</div>
            <div className="selectDiamondAlignGridDivsData">{getSymm()}</div>
          </div>
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Carat</div>
            {/* <div className="selectDiamondAlignGridDivsData">{getCarat()}</div> */}
            <div className="selectSettingFilterAlignSizeSlider">
              <div className="selectSettingFilterAlignPrices">
                <div className="selectSettingFilterPriceBoxes">
                  {sizeRange[0]} cr
                </div>
                <div className="selectSettingFilterPriceBoxes">
                  {sizeRange[1]} cr
                </div>
              </div>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={[parseInt(minSize), parseInt(maxSize)]}
                min={minSize}
                max={maxSize}
                step={0.01}
                onAfterChange={(value, index) => {
                  setSizeRange(value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="selectDiamondAlignGridDivsMore">
          <div className="selectDiamondAlignGridDivs1">
            <div className="selectDiamondPage1heading">Shop By Price</div>
            <div className="selectSettingFilterAlignSizeSlider">
              <div className="selectSettingFilterAlignPrices">
                <div className="selectSettingFilterPriceBoxes">
                  USD {priceRange[0]}
                </div>
                <div className="selectSettingFilterPriceBoxes">
                  USD {priceRange[1]}
                </div>
              </div>
              <ReactSlider
                className="horizontal-slider"
                thumbClassName="example-thumb"
                trackClassName="example-track"
                defaultValue={[
                  parseInt(minsellingPrice),
                  parseInt(maxsellingPrice),
                ]}
                min={minsellingPrice}
                max={maxsellingPrice}
                onAfterChange={(value, index) => setPriceRange(value)}
              />
            </div>
          </div>
          <div
            className="selectDiamondAlignGridDivs1"
            style={{ cursor: "pointer" }}
          >
            <div
              className="selectDiamondMoreBtn"
              onClick={() => {
                handleMoreFilters();
              }}
            >
              {moreFilters ? "More Filters" : "Hide Filter"}
            </div>
          </div>
        </div>

        {!moreFilters && (
          <div>
            <div className="selectDiamondAlignGridDivsMore">
              <div className="selectDiamondAlignGridDivs1">
                <div className="selectDiamondPage1heading">Table</div>
                <div className="selectSettingFilterAlignSizeSlider">
                  <div className="selectSettingFilterAlignPrices">
                    <div className="selectSettingFilterPriceBoxes">
                      {tableRange[0]}%
                    </div>
                    <div className="selectSettingFilterPriceBoxes">
                      {tableRange[1]}%
                    </div>
                  </div>
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    defaultValue={[parseInt(minTable), parseInt(maxTable)]}
                    min={minTable}
                    max={maxTable}
                    step={0.01}
                    onAfterChange={(value, index) => setTableRange(value)}
                  />
                </div>
              </div>

              <div className="selectDiamondAlignGridDivs1">
                <div className="selectDiamondPage1heading">Depth</div>
                <div className="selectSettingFilterAlignSizeSlider">
                  <div className="selectSettingFilterAlignPrices">
                    <div className="selectSettingFilterPriceBoxes">
                      {depthRange[0]}%
                    </div>
                    <div className="selectSettingFilterPriceBoxes">
                      {depthRange[1]}%
                    </div>
                  </div>
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    defaultValue={[parseInt(minDepth), parseInt(maxDepth)]}
                    min={minDepth}
                    max={maxDepth}
                    step={0.01}
                    onAfterChange={(value, index) => setDepthRange(value)}
                  />
                </div>
              </div>
            </div>

            <div className="selectDiamondAlignGridDivsMore">
              <div className="selectDiamondAlignGridDivs1">
                <div className="selectDiamondPage1heading">
                  Length/Width ratio
                </div>
                <div className="selectSettingFilterAlignSizeSlider">
                  <div className="selectSettingFilterAlignPrices">
                    <div className="selectSettingFilterPriceBoxes">
                      {lwRange[0]}
                    </div>
                    <div className="selectSettingFilterPriceBoxes">
                      {lwRange[1]}
                    </div>
                  </div>
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    defaultValue={[parseInt(minLW), parseInt(maxLW)]}
                    min={minLW}
                    max={maxLW}
                    onAfterChange={(value, index) => setLWRange(value)}
                  />
                </div>
              </div>

              <div className="selectDiamondAlignGridDivs1">
                <div className="selectDiamondPage1heading">Origin</div>
                <div className="selectSettingFilterAlignSizeSlider">
                  <div className="selectSettingFilterAlignPrices">
                    <div className="selectSettingFilterPriceBoxes">
                      <label className="sdLabelCheckText">
                        <input
                          type="checkbox"
                          name="origin"
                          value="Canada"
                          className="sdCheckBox"
                          onChange={handleCheckboxChange}
                        />
                        Canada
                      </label>
                    </div>
                    <div className="selectSettingFilterPriceBoxes">
                      <label className="sdLabelCheckText">
                        <input
                          type="checkbox"
                          name="origin"
                          value="Botswana"
                          className="sdCheckBox"
                          onChange={handleCheckboxChange}
                        />
                        Botswana
                      </label>
                    </div>
                    <div className="selectSettingFilterPriceBoxes">
                      <label className="sdLabelCheckText">
                        <input
                          type="checkbox"
                          value="America"
                          name="origin"
                          className="sdCheckBox"
                          onChange={handleCheckboxChange}
                        />
                        America
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <SelectDiamondPage diamonds={diamonds} handleAddRing={handleAddRing} />
      {diamonds?.length > 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              fontFamily: "Alike",
              fontSize: "20px",
              fontWeight: "400",
              cursor: "pointer",
              border: "1px solid #000",
              padding: "10px",
              borderRadius: "20px",
            }}
            onClick={() => handleViewMore()}
          >
            View More
          </div>
        </div>
      )}
      {isLoading && (
        <div className="spinnerDiv">
          <Audio
            height="80"
            width="80"
            radius="9"
            color="green"
            ariaLabel="loading"
            wrapperStyle
            wrapperClass
          />
        </div>
      )}
      {!isLoading && diamonds?.length === 0 && (
        <div
          style={{
            textAlign: "center",
            fontFamily: "Alike",
            fontSize: "24px",
            fontWeight: "600",
          }}
        >
          No Data Found
        </div>
      )}

      {compval ? (
        <div className="homeDivSD">
          <img alt="images" width="100%" src="/assets/Group 558.svg" />
          <div className="homeDiv12SD">
            <h2 className="homeHeadSD">Consult with Our Expert</h2>
            <p className="homeTextSD">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtnSD"
              onClick={() => {
                handleDialog();
              }}
            >
              Consult
            </button>
          </div>
        </div>
      ) : (
        <div className="homeDivSD">
          <img alt="images" width="100%" src="/assets/Group 211001871.svg" />
          <div className="homeDiv12SD">
            <h2 className="homeHeadSD">Consult with Our Expert</h2>
            <p className="homeTextSD">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtnSD"
              onClick={() => {
                handleDialog();
              }}
            >
              Consult
            </button>
          </div>
        </div>
      )}

      {/* <Home8 /> */}
      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}
        >
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}
        >
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default SelectDiamond;
