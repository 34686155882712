import React from "react";
import "../../HomePage/Home8/Home8.css";
import AddIcon from "@mui/icons-material/Add";
import { Divider } from "@mui/material";

const WeddingFaq = (props) => {
  const { dataToDisplay } = props;

  const getHome8Data = () => {
    return dataToDisplay?.faq.map((item, index) => {
      return (
        <div>
          <div className="alignHome8Div">
            <div className="home8Mhead">{item.title}</div>
            <div>
              <AddIcon />
            </div>
          </div>
          <Divider />
        </div>
      );
    });
  };

  return (
    <>
      <div className="home8Container">
        <div className="home8Head">{dataToDisplay?.title}</div>
        <div className="alignHome8Data">{getHome8Data()}</div>
      </div>
    </>
  );
};

export default WeddingFaq;
