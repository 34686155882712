import React from "react";
import "./SelectSettingPage.css";

const SelectSettingPage = (props) => {
  const { handleProduct, item } = props;

  // const imageElements = item?.imgurl?.map((imageUrl, index) => (
  //   <img className="sspImge" key={index} src={imageUrl} alt="images" />
  // ));

  // const lastImageElement = imageElements;

  return (
    <>
      <div
        onClick={() =>
          handleProduct(item?.product_url, item?.prtid, item?.msn, item?.pcolorId)
        }
        className="sspCon"
      >
        <img className="sspImge" src={item?.imgurl[0]} alt="" />
        <div className="selectSettingViewFont1">{item?.p_name}</div>
        <div className="selectSettingViewFont2">{item?.cust_attr_value}</div>
        <div className="selectSettingViewPrice">
        USD {item?.discounted_price}
        </div>
      </div>
    </>
  );
};

export default SelectSettingPage;
