import React from "react";
import "./WeddingRings7.css";

const WeddingRings7 = (props) => {
  const { dataToDisplay9 } = props;
  // const home1Data1 = [
  //   {
  //     images: "/assets/Group 504B.svg",
  //     mHead: "Aura Diamonds",
  //     createdBy: "by Samasva",
  //     str: "",
  //     desc: "Auspiciously sourced Natural & Lab-grown diamonds.",
  //   },
  //   {
  //     images: "/assets/image 208.svg",
  //     mHead: "Always ethically Sourced",
  //     createdBy: "by Samasva",
  //     str: "",
  //     desc: "Individual engraving to perpetuate the deepest feelings.",
  //   },
  //   {
  //     images: "/assets/Group 523B.svg",
  //     mHead: "Buy",
  //     str: "BAC",
  //     str1: "Program",
  //     createdBy: "by Samasva",
  //     desc: "Get 85% exchange value on auspiciously bought Aura Diamonds by Samasva.",
  //   },
  // ];

  const getHome1data1 = () => {
    return dataToDisplay9?.subsection.map((item, index) => {
      return (
        <div key={index} className="wr7Div">
          <div className="wr7ImgDiv">
            <img alt="images" src={item.image} className="wr7Img" />
          </div>
          <div className="wr7Div2">
            <div className="wr7Head">
              {item.title}
              <span className="wr7Brd">{item.str}</span>
              <span style={{ padding: "0px 4px 0px 4px" }}>{item.str1}</span>
              <span className="wr7CreatedBy">{item.createdBy}</span>
            </div>
            <div className="wr7Desc">{item.content.replace(/<\/?p>/g, "")}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="wr7Container">
        <div className="wr7AlignData">{getHome1data1()}</div>
      </div>
    </>
  );
};

export default WeddingRings7;
