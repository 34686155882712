import React from "react";
import Avdert from "../Avdert/Avdert";
import ContactUsPage from "../../components/ContactUsPage/ContactUsPage";
import Header from "../Header/Header";
import FooterPage from "../../components/FooterPage/FooterPage";
const ContactUs = () => {
  return (
    <>
      <Avdert />
      <Header />
      <ContactUsPage />
      <FooterPage />
    </>
  );
};

export default ContactUs;
