import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Rings1 from "../../components/Rings/Rings1/Rings1";
import Rings2 from "../../components/Rings/Rings2/Rings2";
import Home7 from "../../components/HomePage/Home7/Home7";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { getData } from "../HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
const RingsPage = () => {
  const navigate = useNavigate();

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/1");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const handleShopRing = () => {
    sessionStorage.setItem("productPage", "ring");
    navigate("/all-product");
  };

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const dataToDisplay7 = data.find((data) => data.section === 8);
  const dataToDisplay10 = data.find((data) => data.section === 10);

  return (
    <>
      <Avdert />
      <Header />

      {compval ? (
        <div className="erContainer1">
          <img
            alt="images"
            src="/assets/ring1.webp"
            loading="lazy"
            width="100%"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">Rings</div>
            <div className="erMainText">
              "Our earring collection includes timeless classics for daily
              elegance and stunning statement pieces for those extraordinary
              moments."
            </div>
            <div>
              <button
                className="erBtn1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleShopRing();
                }}>
                Shop Fashion Rings
              </button>
            </div>
            <div>
              <button
                className="erBtn2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/select-setting");
                }}>
                Shop Enagagement Rings
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="erContainer1">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 211001953.png"
            loading="lazy"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">Rings</div>
            <div className="erMainText">
              "Our earring collection includes timeless classics for daily
              elegance and stunning statement pieces for those extraordinary
              moments."
            </div>
            <div>
              <button className="erBtn1">Shop Fashion Rings</button>
            </div>
            <div>
              <button className="erBtn2">Shop Enagagement Rings</button>
            </div>
          </div>
        </div>
      )}

      <Rings1 />
      <Rings2 />
      <Home7 dataToDisplay={dataToDisplay7} />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      )}
      <Home8 dataToDisplay={dataToDisplay10} />
      <Home10 />
      <FooterPage />
      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default RingsPage;
