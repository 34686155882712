import React, { useEffect, useState } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import DnaPage1 from "../../components/DNAPages/DnaPage1/DnaPage1";
import DnaPage2 from "../../components/DNAPages/DnaPage2/DnaPage2";
import DnaPage3 from "../../components/DNAPages/DnaPage3/DnaPage3";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import { getData } from "../HTTPConfig/HTTPConfig";
const Dna = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/1");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const dataToDisplay = data.find((data) => data.section === 10);
  return (
    <>
      <Avdert />
      <Header />
      <DnaPage1 />
      <DnaPage2 />
      <DnaPage3 />
      {compval ? (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src="/assets/home3.webp"
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">Consult with Our Expert</h2>
              <p className="homeText">
                Seek personalized guidance and curated selections to help you
                find the perfect piece that reflects your unique style and
                story.
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        ) : (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src="/assets/Group 1000002162.png"
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">Consult with Our Expert</h2>
              <p className="homeText">
                Seek personalized guidance and curated selections to help you
                find the perfect piece that reflects your unique style and
                story.
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        )}
      <Home8 dataToDisplay={dataToDisplay} />
      <Home10 />
      <FooterPage />
    </>
  );
};

export default Dna;
