import React, { useEffect, useState } from "react";
import OrderDetailsPage from "../../components/OrderDetailsPage/OrderDetailsPage";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import { getData } from "../HTTPConfig/HTTPConfig";
import { useLocation } from "react-router-dom";
import FooterPage from "../../components/FooterPage/FooterPage";
const OrderDetails = () => {
  const [compval, setCompval] = useState(false);
  const location = useLocation();
  const orderId = location.state.id;
  const [ordersDetails, setOrdersDetails] = useState([]);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const response = await getData(`order/productShowDetails/${orderId}`);
      setOrdersDetails(response.data);
    };

    fetchOrderDetails();
  }, [orderId]);

  return (
    <>
      <Avdert />
      <Header />
      <OrderDetailsPage ordersDetails={ordersDetails} compval={compval} />
      <FooterPage />
    </>
  );
};
export default OrderDetails;
