import React from "react";
import "./SelectDiamond1C3.css";

const SelectDiamond1C3 = () => {
  const diamondsData = [
    { name: "Round", images: "/assets/image 113.svg" },
    { name: "Princess", images: "/assets/image 114.svg" },
    { name: "Cushion", images: "/assets/image 115.svg" },
    { name: "Oval", images: "/assets/image 116.svg" },
    { name: "Pear", images: "/assets/image 117.svg" },
    { name: "Emerald", images: "/assets/image 118.svg" },
    { name: "Heart", images: "/assets/image 119.svg" },
    { name: "Marquise", images: "/assets/image 120.svg" },
    { name: "Radiant", images: "/assets/image 121.svg" },
    { name: "Asscher", images: "/assets/image 122.svg" },
  ];

  const getDiamons = () => {
    return diamondsData.map((item, index) => {
      return (
        <div className="selectDiamondPage1Managedata">
          <img
            alt="images"
            src={item.images}
            className="selectDiamondPage1ImgSize"
          />
          <div>{item.name}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="sd3Container">
        <div className="sd2Head">Diamond Shape</div>
        <div className="selectDiamondPage1AlignData">{getDiamons()}</div>
      </div>
    </>
  );
};

export default SelectDiamond1C3;
