import React, { useState, useEffect } from "react";
import "./GemstonePage1.css";

const GemstonePage1 = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      {compval ? (
        <div className="gp1Div">
          <img
            alt="images"
            className="gp1Img"
            src="/assets/gm1.webp"
            loading="lazy"
          />
          <div className="gp1Div12">
            <h2 className="gp1Head">Gemstones</h2>
            <p className="gp1Text">
              From sapphire engagement rings to ruby and sapphire jewelry, our
              natural stones undergo a meticulous recutting process to maximize
              their color and brilliance, ensuring you receive the finest
              quality.
            </p>
            <button className="gp1Btn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="gp1Div">
          <img
            alt="images"
            className="gp1Img"
            src="/assets/Group 1000002151.png"
            loading="lazy"
          />
          <div className="gp1Div12">
            <h2 className="gp1Head">Gemstone</h2>
            <p className="gp1Text">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="gp1Btn">Shop Now</button>
          </div>
        </div>
      )}
    </>
  );
};

export default GemstonePage1;
