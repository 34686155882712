import React from "react";
import "./Home3.css";
import { Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Home3 = (props) => {
  const { dataToDisplay } = props;
  const navigate = useNavigate();

  const handleDesignClick=(position)=>{
    if(position===1){
      navigate("/select-studs")
    } else if(position===2){
      navigate("/select-necklace");
    }
  }

  const getHome3data = () => {
    return dataToDisplay?.subsection?.map((item, index) => {
      return (
        <div key={index} className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{handleDesignClick(item.position)}}>
          <img alt="images" width="100%" src={item.image} />
          <div
            className={
              index % 2 === 0 ? "home3ContainerDivA" : "home3ContainerDivB"
            }
          >
            <div className="home3ContainerH">{item.title}</div>
            <div className="home3ContainerT">{item.content.replace(/<\/?p>/g, '')}</div>
          </div>
        </div>
      );
    });
  };

  const getHome3data1 = () => {
    return dataToDisplay?.subsection?.map((item, index) => {
      return (
        <div key={index} className="home3ContainerDiv" onClick={()=>{handleDesignClick(item.position)}}>
          <img alt="images" width="100%" src={item.mobile_image} />
          <div
            className={
              index % 2 === 0 ? "home3ContainerDivA" : "home3ContainerDivB"
            }
          >
            <div className="home3ContainerH">{item.title}</div>
            <div className="home3ContainerT">{item.content.replace(/<\/?p>/g, '')}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <Hidden smDown>
        <div className="home3Container">{getHome3data()}</div>
      </Hidden>
      <Hidden smUp>
        <div className="home3Container">{getHome3data1()}</div>
      </Hidden>
    </>
  );
};

export default Home3;
