import React, { useState, useEffect } from "react";
import "./DnaPage1.css";

const DnaPage1 = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      {compval ? (
        <div className="dn1MainDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/dn1.webp"
            loading="lazy"
          />
          <div className="dn1MainDiv1">
            <h2 className="dn1Heads1">
              DNA Diamonds from carbon of your Loved one's
            </h2>
            <h2 className="dn1Heads2">
              Creating DNA diamonds using your loved ones' hair, love letters,
              or any carbon-extractable item, preserving their essence in
              brilliant gems.
            </h2>
          </div>
        </div>
      ) : (
        <div className="dn1MainDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/dn1M.webp"
            loading="lazy"
          />
          <div className="dn1MainDiv1">
            <h2 className="dn1Heads1">
              DNA Diamonds from carbon of your Loved one's
            </h2>
            <h2 className="dn1Heads2">
              Creating DNA diamonds using your loved ones' hair, love letters,
              or any carbon-extractable item, preserving their essence in
              brilliant gems.
            </h2>
          </div>
        </div>
      )}

      <div className="dn1Container1">
        <div className="dn1Container1-div">
          <div className="dn1M1Head">
            Crafted through Extensive Research and Development.
          </div>
          <div className="dn1M1Text">
            At Samasva, we craft a unique diamond using your loved one's hair,
            love letters, or carbon-extractable items. Each diamond preserves
            their story, eternal love, and cherished memories.
          </div>
        </div>
        <div className="dn1Container1-div">
          <img alt="images" src="/assets/image 371.png" width="100%" />
        </div>
      </div>
    </>
  );
};

export default DnaPage1;
