import React from "react";
import "./FineDialog.css";
import { useNavigate } from "react-router-dom";
const FineDialog = (props) => {
  const navigate = useNavigate();
  const { setFineDialog } = props;

  const handleRings = () => {
    navigate("/rings");
  };

  const handleNecklaces = () => {
    navigate("/necklace");
  };

  const handleBracelete = () => {
    navigate("/bracelete");
  };

  const handleEarRings = () => {
    navigate("/ear-rings");
  };

  return (
    <>
      <div onMouseLeave={() => setFineDialog(false)} className="fineDContainer">
        <div className="fineDiv1">
          <div onClick={handleRings} className="finHead1">
            Rings
          </div>
          <div onClick={handleEarRings} className="finHead2">
            Ear-Rings
          </div>
          <div onClick={handleNecklaces} className="finHead3">
            Necklaces
          </div>
          <div onClick={handleBracelete} className="finHead4">
            Bracelets
          </div>
        </div>
        <div className="fineDiv2">
          <img alt="images" src="/assets/image 473.svg" width="100%" />
        </div>
      </div>
    </>
  );
};

export default FineDialog;
