import React, { useState, useEffect } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import MyOrderPage from "../../components/MyOrderPage/MyOrderPage";
import { getData } from "../HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";
const MyOrder = () => {
  const navigate = useNavigate();
  const [compval, setCompval] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  useEffect(() => {
    const fetchAllOrders = async () => {
      const response = await getData("order/show");
      setOrders(response?.data);
    };
    fetchAllOrders();
  }, []);

  const handleOrderDetails = (order_id) => {
    navigate("/order-details", { state: { id: order_id } });
  };

  return (
    <>
      <Avdert />
      <Header />
      <MyOrderPage
        orders={orders}
        compval={compval}
        handleOrderDetails={handleOrderDetails}
      />
    </>
  );
};

export default MyOrder;
