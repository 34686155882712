import React, { useEffect, useState } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import SelectSettingPage from "../../components/SelectSettingPage/SelectSettingPage";
import { useNavigate } from "react-router-dom";
import SelectSetting2 from "../../components/SelectSettingPage/SelectSetting2/SelectSetting2";
import SelectSetting4 from "../../components/SelectSettingPage/SelectSetting4/SelectSetting4";
import SelectSettingFilter from "../../components/SelectSettingPage/SelectSettingFilter/SelectSettingFilter";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import { getData } from "../HTTPConfig/HTTPConfig";
import FooterPage from "../../components/FooterPage/FooterPage";
import "./SelectSettings.css";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, Hidden } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
// import SelectSetting21 from "../../components/SelectSettingPage/SelectSetting21/SelectSetting21";
import SelectSetting22 from "../../components/SelectSettingPage/SelectSetting22/SelectSetting22";

const SelectSetting = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [compval, setCompval] = useState(false);
  const active = false;
  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [colors, setColors] = useState([]);
  const [dataByColorId, setDataByColorId] = useState([]);
  const [productData, setProductData] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [checkedColors, setCheckedColors] = useState("");

  const getColors = async () => {
    const result = await getData("allcolor/getcolor");
    setColors(result?.data);
    setLoading(false);
  };

  useEffect(() => {
    getColors();
  }, []);

  useEffect(() => {
    const colrs = selectedColors?.map((attr) => `${attr}`);
    setCheckedColors(colrs.join(", "));
  }, [selectedColors]);

  const handleColorId = async (id) => {
    try {
      const isColorSelected = selectedColors?.includes(id);
      if (isColorSelected) {
        setSelectedColors(
          selectedColors.filter((selectedColor) => selectedColor !== id)
        );
      } else {
        setSelectedColors([...selectedColors, id]);
      }
      const result = await getData(`getattribute/${checkedColors}`);
      setDataByColorId(result?.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [formattedString, setFormattedString] = useState("");

  useEffect(() => {
    const formatted = selectedAttributes?.map(
      (attr) => `${attr.attributeId}:'${attr.value}'`
    );
    setFormattedString(formatted?.join(", "));
  }, [selectedAttributes]);

  const handleCustomrAttr = async (attributeId, value) => {
    const isSelected = selectedAttributes.some(
      (attr) => attr.attributeId === attributeId && attr.value === value
    );

    if (isSelected) {
      setSelectedAttributes((prevSelected) =>
        prevSelected.filter(
          (attr) => !(attr.attributeId === attributeId && attr.value === value)
        )
      );
    } else {
      setSelectedAttributes((prevSelected) => [
        ...prevSelected,
        { attributeId, value },
      ]);
    }
  };

  useEffect(() => {
    const fetchDefaultProductData = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/{pcolorId}/{attributenameandid}`
        );
        setProductData(result?.data);
        setLoading(false);
      } catch (error) {
        console.log("error", error.response);
        setLoading(false);
      }
    };

    const fetchDefaultProductDataByColor = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/${checkedColors}/{attributenameandid}`
        );
        setProductData(result?.data);
        setLoading(false);
      } catch (error) {
        console.log("error", error?.response);
        setLoading(false);
      }
    };
    const fetchProductData = async () => {
      try {
        const result = await getData(
          `getproductbycolororattribute/${checkedColors}/${formattedString}`
        );
        setProductData(result?.data);
        setLoading(false);
        if(!compval){
          setDrawerOpen(true);
        }
      } catch (error) {
        console.log("error", error?.response);
        setLoading(false);
      }
    };
    if (formattedString) {
      fetchProductData();
    }
    if (checkedColors) {
      fetchDefaultProductDataByColor();
      if(!compval){
        setDrawerOpen(true);
      }
    } else {
      fetchDefaultProductData();
    }
  }, [checkedColors, formattedString, compval]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  // console.log("abcd", checkedColors);
  // console.log("nvsdvf", formattedString);
  // console.log("sahghwe", productData);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const cPage = sessionStorage.getItem("cPage");

  const handleFilterDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (loading) {
    return <div>loading...</div>;
  }

  const handleProduct = (p_name, id, msn, colorId) => {
    navigate(`/product/${p_name}`, {
      state: { comp: active, id: id, msn: msn, colorId: colorId },
    });
  };

  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      {cPage?<SelectSetting22 />:<SelectSetting2 active={active} />}
      
        <SelectSettingFilter
          handleColorId={handleColorId}
          selectedColors={selectedColors}
          colors={colors}
        />
      

      <Hidden smDown>
        {dataByColorId?.map((item, index) => {
          return (
            <SelectSetting4
              selectedAttributes={selectedAttributes}
              handleCustomrAttr={handleCustomrAttr}
              item={item}
            />
          );
        })}
      </Hidden>

      <div className="alignSS2Data">
        {productData?.map((item, index) => {
          return (
            <SelectSettingPage handleProduct={handleProduct} item={item} />
          );
        })}
      </div>

      <Hidden smUp>
        {!drawerOpen && (
          <div onClick={handleFilterDrawer} className="ssFilter">
            Filters
          </div>
        )}
      </Hidden>

      <div className={`filterDrawer ${drawerOpen ? "open" : ""}`}>
        <div onClick={() => setDrawerOpen(false)} className="ssAlignFilter">
          <div className="ssFilterHeading">Filter</div>
          <div className="ssFilterClose">
            <CloseIcon />
          </div>
        </div>
        <SelectSettingFilter
          handleColorId={handleColorId}
          selectedColors={selectedColors}
          colors={colors}
        />
        {dataByColorId?.map((item, index) => {
          return (
            <SelectSetting4
              selectedAttributes={selectedAttributes}
              handleCustomrAttr={handleCustomrAttr}
              item={item}
            />
          );
        })}
      </div>

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      )}

      <Home8 />
      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default SelectSetting;
