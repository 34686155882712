import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import SelectSetting2 from "../../components/SelectSettingPage/SelectSetting2/SelectSetting2";
import SettingProductPage from "../../components/SettingProductPage/SettingProductPage";
// import Home9 from "../../components/HomePage/Home9/Home9";
// import Home8 from "../../components/HomePage/Home8/Home8";
import SelectSettingView from "../../components/SelectSettingPage/SelectSettingView/SelectSettingView";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { useLocation, useNavigate } from "react-router-dom";
import { getData } from "../HTTPConfig/HTTPConfig";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
import { Dialog } from "@mui/material";
import Home10 from "../../components/HomePage/Home10/Home10";
import SelectSetting22 from "../../components/SelectSettingPage/SelectSetting22/SelectSetting22";

const Settingproduct = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const comp = location.state.comp;
  const id = location.state.id;
  const msn = location.state.msn;
  const colorId = location.state.colorId;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [compval, setCompval] = useState(false);
  const [selectedColors, setSelectedColors] = useState([]);
  // const [checkedColors, setCheckedColors] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchProduct = async () => {
      const result = await getData(
        `productAttributeData/${id}/${colorId}/${msn}`
      );
      setData(result?.data);
      setLoading(false);
      // console.log(result?.data);
    };
    fetchProduct();
  }, [id, msn, colorId]);

  const handleColorId = async (id, prtid) => {
    const position = "{position}";
    const prevvalues = "{prevvalues}";
    const isColorSelected = selectedColors?.includes(id);
    if (isColorSelected) {
      setSelectedColors(
        selectedColors?.filter((selectedColor) => selectedColor !== id)
      );
    } else {
      setSelectedColors([...selectedColors, id]);
    }
    const result = await getData(
      `productFilterAttributeData/${prtid}/${id}/${position}/${prevvalues}`
    );
    setData(result?.data);
    setLoading(false);
  };

  const handleAttr = async (prtid, id, p, prevvalues) => {
    const result = await getData(
      `productFilterAttributeData/${prtid}/${id}/${p}/${prevvalues}`
    );
    setData((prevData) => {
      const updatedProductAttr = [
        ...prevData?.product_attribute,
        ...result?.data?.product_attribute,
      ];
      return {
        ...prevData,
        product_attribute: updatedProductAttr,
      };
    });

    setLoading(false);
  };

  // const dataa = {
  //   productAttr: [
  //     {position: 1}
  //   ]
  // }

  // const datatoApend = {
  //   productAttr: [
  //     {position: 2}
  //   ]
  // }

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  if (loading) {
    return <p>Loading data...</p>;
  }

  const cPage = sessionStorage.getItem("cPage");
  const diamond_id = sessionStorage.getItem("diamond_id");

  const userToken = sessionStorage.getItem("samsva_token");

  const handleAddToCart = async (msn, diamond, prtid) => {
    if (userToken) {
      if(diamond==="1"){
        sessionStorage.setItem("msn", msn);
        sessionStorage.setItem("prtid", prtid);
        if(cPage==="diamond-page"){
          navigate("/complete-ring", {state:{diamond_id:diamond_id, prtid: prtid, msn: msn}});
        } else{
          navigate("/select-diamond");
        }
      } else{
        // const addToGuestcart = await getData(`guestaddtocart/${msn}`);
        const addToCart = await getData(`postMsnToCart/${msn}`);
        console.log(addToCart);
        navigate("/shopping-bag");
      }
      // const addToCart = await getData(`postMsnToCart/${msn}`);
      // navigate("/shopping-bag");
      // // navigate("/select-diamond");
      // console.log(addToCart);
    } else {
      if(diamond==="1"){
        sessionStorage.setItem("msn", msn);
        sessionStorage.setItem("prtid", prtid);
        if(cPage==="diamond-page"){
          navigate("/complete-ring", {state:{diamond_id:diamond_id, prtid: prtid, msn: msn}});
        } else{
          navigate("/select-diamond");
        }
      } else{
        const addToGuestcart = await getData(`guestaddtocart/${msn}`);
        console.log(addToGuestcart);
        navigate("/shopping-bag");
      }
      // const addToGuestcart = await getData(`guestaddtocart/${msn}`);
      // navigate("/shopping-bag");
      // // navigate("/select-diamond");
      // console.log(addToGuestcart);
    }
  };

  // const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  return (
    <>
      <Avdert />
      <Header />
      <div className="dpGap"></div>
      {cPage?<SelectSetting22 />:<SelectSetting2 comp={comp} />}
      <SettingProductPage
        data={data}
        handleAddToCart={handleAddToCart}
        msn={msn}
        handleColorId={handleColorId}
        handleAttr={handleAttr}
      />
      {/* <Home9 /> */}
      <SelectSettingView />

      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      )}
       <Home10 />
      {/* <Home8 /> */}
      <FooterPage />
      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default Settingproduct;
