import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Divider } from "@mui/material";

const JewelleryFaq = (props) => {
  const { dataToDisplay5 } = props;
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSelect = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  const getHome8Data = () => {
    return dataToDisplay5?.faq.map((item, index) => {
      return (
        <div key={index}>
          <div className="alignHome8Div" onClick={() => handleSelect(index)}>
            <div className="home8Mhead">{item?.title}</div>
            <div>
              <AddIcon />
            </div>
          </div>
          {selectedItems[index] && <div className="home8Mhead">{item?.content?.replace(/<\/?p>/g, "")}</div>}
          <Divider />
        </div>
      );
    });
  };

  return (
    <div className="home8Container">
      <div className="home8Head">{dataToDisplay5?.title}</div>
      <div className="alignHome8Data">{getHome8Data()}</div>
    </div>
  );
};

export default JewelleryFaq;
