import React from "react";
import "./Engagement1.css";
import { useNavigate } from "react-router-dom";
const Engagement1 = (props) => {
  const { dataToDisplay1, dataToDisplay2 } = props;
  const navigate = useNavigate();

  const handleSelectSetting = () => {
    navigate("/select-setting");
    sessionStorage.setItem("SamasvaHeader", "select_setting");
  };

  return (
    <>
      <div className="engagement1Container">
        <div className="engagement1Container-div1">
          <div className="alignEnagement1Div1">
            <div className="engagement1Heading1">{dataToDisplay1?.title}</div>
            <div className="engagement1txt1">{dataToDisplay1?.content.replace(/<\/?p>/g, '')}</div>
            <div>
              <div>
                <button
                  onClick={handleSelectSetting}
                  className="engagement1btns"
                >
                  Start with a setting
                </button>
              </div>
              <div>
                <button className="engagement1btns1">
                  Start with a Diamond
                </button>
              </div>
            </div>
          </div>
          <img
            alt="images"
            src={dataToDisplay1?.image}
            className="engagement1Img1"
          />
        </div>
        <div className="engagement1Container-div2">
          <img
            alt="images"
            src={dataToDisplay2?.image}
            className="engagement1Img2"
          />
          <div className="alignEnagement1Div2">
            <div className="engagement1Heading2">{dataToDisplay2?.title}</div>
            <div className="engagement1txt2">{dataToDisplay2?.content.replace(/<\/?p>/g, '')}</div>
            <button className="engagement1btns1" onClick={() => { navigate("/select-setting") }}>Shop Now</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Engagement1;
