import React, { useEffect, useState } from "react";
import "./Gemstone.css";
import Header from "../Header/Header";
import GemstonePage1 from "../../components/GemstonePage/GemstonePage1/GemstonePage1";
import GemstonePage2 from "../../components/GemstonePage/GemstonePage2/GemstonePage2";
import GemstonePage3 from "../../components/GemstonePage/GemstonePage3/GemstonePage3";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import GemstoneFaq from "./GemstoneFaq";
import { getData } from "../HTTPConfig/HTTPConfig";
const Gemstone = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/1");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const dataToDisplay = data.find((data) => data.section === 10);

  return (
    <>
      <Avdert />
      <Header />
      <GemstonePage1 />
      <GemstonePage2 />
      {compval ? (
        <div className="gsContainer1">
          <img
            alt="images"
            src="/assets/gp2.webp"
            loading="lazy"
            width="100%"
          />
          <div className="gsContainer1AlignDiv">
            <div className="gsMainHead">Design your own Engagement Ring</div>
            <div className="gsMainText">
              Craft your unique engagement ring by starting with either the ring
              setting or the center stone selection.The decision is entirely in
              your hands.
            </div>
          </div>
        </div>
      ) : (
        <div className="gsContainer1">
          <img
            alt="images"
            src="/assets/Group 1000002156.png"
            loading="lazy"
            width="100%"
          />
          <div className="gsContainer1AlignDiv">
            <div className="gsMainHead">Design your own Engagement Ring</div>
            <div className="gsMainText">
              Craft your unique engagement ring by starting with either the ring
              setting or the center stone selection.The decision is entirely in
              your hands.
            </div>
          </div>
        </div>
      )}

      <GemstonePage3 />
      <GemstoneFaq dataToDisplay={dataToDisplay} />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      )}
      <Home10 />
      <FooterPage />
    </>
  );
};

export default Gemstone;
