import React, { useEffect, useState } from "react";
import "./Header.css";
import { Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";

import HeaderDialog from "../../components/HeaderDialog/HeaderDialog";
import FineDialog from "../../components/FineDialog/FineDialog";

import HeaderDrawer from "./HeaderDrawer";
const Header = () => {
  const navigate=useNavigate();
  const history = useNavigate();

  const [ringDialog, setRingDialog] = useState(false);
  const [fineDialog, setFineDialog] = useState(false);
  const [engageRings, setEngageRings] = useState(false);
  const [weddRings, setWeddRings] = useState(false);

  const handleRingDialog = () => {
    setRingDialog(true);
    setFineDialog(false);
  };

  const handleFineDialog = () => {
    setFineDialog(true);
    setRingDialog(false);
    setWeddRings(false);
    setEngageRings(false);
  };

  const handleEngageRings = () => {
    setEngageRings(true);
  };

  const handleWeddRings = () => {
    setWeddRings(true);
  };

  const handleLogo = () => {
    history("/");
    sessionStorage.setItem("SamasvaHeader", "home");
  };

  const handleEngagementRing = () => {
    history("/engagement-ring");
    sessionStorage.setItem("SamasvaHeader", "er");
  };

  const handleAboutUs = () => {
    history("/about-us");
    sessionStorage.setItem("SamasvaHeader", "au");
  };

  const buyingGuide = () => {
    history("/buying-guide");
    sessionStorage.setItem("SamasvaHeader", "bg");
  };

  const handleJewellery = () => {
    history("/jewellery");
    sessionStorage.setItem("SamasvaHeader", "jw");
  };

  const handleDiamonds = () => {
    history("/diamond-page");
    sessionStorage.setItem("SamasvaHeader", "dmn");
  };

  const handleGifts = () => {
    history("/gifts");
    sessionStorage.setItem("SamasvaHeader", "gifts");
  };

  const [rStatus, setRStatus] = useState(false);
  const [jStatus, setJStatus] = useState(false);
  const [dStatus, setDStatus] = useState(false);
  const [gStatus, setGStatus] = useState(false);
  const [bStatus, setBStatus] = useState(false);
  const [aStatus, setAStatus] = useState(false);

  const header = sessionStorage.getItem("SamasvaHeader");

  useEffect(() => {
    if (header === "er") {
      setRStatus(true);
      setJStatus(false);
      setDStatus(false);
      setGStatus(false);
      setBStatus(false);
      setAStatus(false);
    } else if (header === "au") {
      setJStatus(false);
      setRStatus(false);
      setDStatus(false);
      setGStatus(false);
      setBStatus(false);
      setAStatus(true);
    } else if (header === "bg") {
      setJStatus(false);
      setRStatus(false);
      setDStatus(false);
      setGStatus(false);
      setBStatus(true);
      setAStatus(false);
    } else if (header === "gifts") {
      setJStatus(false);
      setRStatus(false);
      setDStatus(false);
      setGStatus(true);
      setBStatus(false);
      setAStatus(false);
    } else if (header === "jw") {
      setJStatus(true);
      setRStatus(false);
      setDStatus(false);
      setGStatus(false);
      setBStatus(false);
      setAStatus(false);
    } else if (header === "dmn") {
      setJStatus(false);
      setRStatus(false);
      setDStatus(true);
      setGStatus(false);
      setBStatus(false);
      setAStatus(false);
    } else {
      setJStatus(false);
      setRStatus(false);
      setDStatus(false);
      setGStatus(false);
      setBStatus(false);
      setAStatus(false);
    }
  }, [header]);

  const [headerBackgroundColor, setHeaderBackgroundColor] =
    useState("#f8f6f4cc");

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const threshold = 1;

    if (scrollPosition > threshold) {
      setHeaderBackgroundColor("white");
    } else {
      setHeaderBackgroundColor("#f8f6f4cc");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleAbc = () => {
    setRingDialog(false);
    setFineDialog(false);
  };

  const handleLogin = () => {
    history("/login");
  };

  const handleCart = () => {
    history("/shopping-bag");
  };
  return (
    <>
      <Hidden smDown>
        <div
          style={{ backgroundColor: headerBackgroundColor }}
          className="headerContainer"
        >
          <div
            onClick={handleLogo}
            onMouseOver={() => setRingDialog(false)}
            className="mainLogo"
          >
            <img alt="images" src="/assets/samasva dummy logo.png" />
          </div>
          <div className="headerContainer-div">
            <div
              onClick={handleEngagementRing}
              onMouseOver={handleRingDialog}
              className={
                rStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              Rings
            </div>
            <div
              onMouseOver={handleFineDialog}
              onClick={handleJewellery}
              className={
                jStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              Fine Jewellery
            </div>
            <div
              onMouseOver={handleAbc}
              onClick={handleDiamonds}
              className={
                dStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              Aura Diamonds
            </div>
            <div
              onClick={handleGifts}
              onMouseOver={handleAbc}
              className={
                gStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              Gifts
            </div>
            <div
              onClick={buyingGuide}
              onMouseOver={handleAbc}
              className={
                bStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              Buying Guide
            </div>
            <div
              onClick={handleAboutUs}
              onMouseOver={handleAbc}
              className={
                aStatus ? "headerContainer-div-divA" : "headerContainer-div-div"
              }
            >
              About Us
            </div>
          </div>
          <div className="headerAlignIcons">
            <img
              alt="images"
              src="/assets/user.svg"
              className="userIcon"
              onClick={()=>{if(sessionStorage.getItem("samsva_token"))
              {navigate("/my-profile")}
            else{
              handleLogin();
            }}}
            />
            <img
              alt="images"
              src="/assets/cart.svg"
              className="userIcon"
              onClick={handleCart}
            />
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{ backgroundColor: headerBackgroundColor }}
          className="headerContainerM"
        >
          <img
            alt="images"
            src="/assets/samasva dummy logo.png"
            className="menuIconSize"
            onClick={handleLogo}
          />
          <img
            alt="images"
            src="/assets/hamburger.png"
            className="menuIconSize"
            onClick={handleDrawerOpen}
          />
        </div>
      </Hidden>

      <div>
        {/* <div className={`drawer ${drawerOpen ? "open" : ""}`}>
        <div className="drawer-content">
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                padding: "0px 16px 8px 0px",
              }}
            >
              <CloseIcon onClick={() => setDrawerOpen(false)} />
            </div>
            <div onClick={handleEngagementRing} className="drawer-align ">
              <div
                className={
                  rStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                Rings
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={handleJewellery} className="drawer-align ">
              <div
                className={
                  jStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                Fine Jewellery
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={handleDiamonds} className="drawer-align ">
              <div
                className={
                  dStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                Aura Diamonds
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={handleGifts} className="drawer-align ">
              <div
                className={
                  gStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                Gifts
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={buyingGuide} className="drawer-align ">
              <div
                className={
                  bStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                Buying Guide
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={handleAboutUs} className="drawer-align ">
              <div
                className={
                  aStatus
                    ? "headerContainer-div-divA"
                    : "headerContainer-div-div"
                }
              >
                About Us
              </div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </div>

      {ringDialog && (
        <HeaderDialog
          handleWeddRings={handleWeddRings}
          handleEngageRings={handleEngageRings}
          ringDialog={ringDialog}
          engageRings={engageRings}
          weddRings={weddRings}
          // setRingDialog={setRingDialog}
          setEngageRings={setEngageRings}
          setWeddRings={setWeddRings}
        />
      )}
      {fineDialog && <FineDialog setFineDialog={setFineDialog} />}

      <HeaderDrawer
        handleDrawerOpen={handleDrawerOpen}
        drawerOpen={drawerOpen}
        handleEngagementRing={handleEngagementRing}
        rStatus={rStatus}
        handleJewellery={handleJewellery}
        jStatus={jStatus}
        handleDiamonds={handleDiamonds}
        dStatus={dStatus}
        handleGifts={handleGifts}
        gStatus={gStatus}
        buyingGuide={buyingGuide}
        bStatus={bStatus}
        handleAboutUs={handleAboutUs}
        aStatus={aStatus}
      />
    </>
  );
};

export default Header;
