import React, { createRef } from "react";
import Engagement1 from "../Engagement1/Engagement1";
import Engagement2 from "../Engagement2/Engagement2";
import Engagement3 from "../Engagement3/Engagement3";
import Engagement5 from "../Engagement5/Engagement5";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./EngagementLanding.css";

const EngagementLanding = (props) => {
  const { data } = props;
  const mySlider = createRef();
  var settings = {
    dots: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);
  const dataToDisplay7 = data.find((data) => data.section === 7);
  const dataToDisplay8 = data.find((data) => data.section === 8);

  const getHome2data = () => {
    return dataToDisplay7?.attribute[0]?.valuedata?.map((item, index) => {
      return (
        <div key={index} className="engagement2ImagesDiv">
          <img alt="images" src={item.attribute_image} className="home2Img" />
          <div className="engagement2Texts">{item.cust_attr_value}</div>
        </div>
      );
    });
  };

  return (
    <>
      <Engagement1
        dataToDisplay1={dataToDisplay1}
        dataToDisplay2={dataToDisplay2}
      />
      <Engagement2
        dataToDisplay3={dataToDisplay3}
        dataToDisplay4={dataToDisplay4}
      />
      <Engagement3
        dataToDisplay5={dataToDisplay5}
        dataToDisplay6={dataToDisplay6}
      />
      <div className="eng4C">
        <div className="engagement2heading">{dataToDisplay7?.title}</div>
        <Hidden smDown>
          <div className="engagement2Images">{getHome2data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome2data()}
          </Slider>
        </Hidden>
      </div>
      <Engagement5 dataToDisplay8={dataToDisplay8} />
    </>
  );
};

export default EngagementLanding;
