import React, { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./MyAddresses.css";
import { getData, postData } from "../../userInterface/HTTPConfig/HTTPConfig";
import { Dialog } from "@mui/material";
import AddressDialog from "../CustomerDetailsPage/AddressDialog";
import EditAddressDialog from "../CustomerDetailsPage/EditAddressDialog";

const MyAddresses = (props) => {
  const { addresses } = props;

  const handleDeleteAddr = async (id) => {
    const response = await getData(`deleteAddress/${id}`);
    if (response.data) {
      window.location.reload();
    };
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [selectDelete, setSelectDelete]=useState(false);
  const [addrId, setAddrId]=useState();

  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };


  const handleAddNewAddress = async (
    name,
    phone,
    pincode,
    address,
    state,
    city,
    locality,
    landmark,
    addressType
  ) => {
    setSelectDelete(true);
    setOpenDialog(true);
    const body = {
      name: name,
      phone: phone,
      pincode: pincode,
      address: address,
      state: state,
      city: city,
      locality: locality,
      landmark: landmark,
      address_type: addressType,
    };
    const resposne = await postData("addAddress", body);
    if (resposne.data) {
      window.location.reload(false);
      setSelectDelete(false);
      setOpenDialog(false);
    }
  };

  const handleEditDialog=(id)=>{
    setAddrId(id);
    setOpenDialog(true);
  }

  const handleEditAddress = async (
    name,
    phone,
    custAddress_id,
    pincode,
    address,
    state,
    city,
    locality,
    landmark,
    addressType
  ) => {
    setSelectDelete(false);
    setOpenDialog(true);
    const body = {
      name: name,
      phone: phone,
      custAddress_id:custAddress_id,
      pincode: pincode,
      address: address,
      state: state,
      city: city,
      locality: locality,
      landmark: landmark,
      address_type: addressType,
    };
    const resposne = await postData("editAddress", body);
    if (resposne.data) {
      window.location.reload(false);
      setOpenDialog(false);
    }
  };

  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  return (
    <>
      <div className="mop">
        <div className="mopH">My Account</div>
        <ArrowForwardIosIcon />
        <div className="mopA">My Addresses</div>
      </div>

      <div className="mad">
        <h1 className="madHead">Address</h1>
        <button className="madBtn" onClick={() => { handleDialog() }}>Add Address</button>
      </div>

      <div className="madAlign">
        {addresses.map((addresss) => {
          return (
            <div className="madBox" key={addresss.custAddress_id}>
              <div>
                <div className="odpAddressCName">{addresss.cust_Name}</div>
                <div className="odpAddressAdrs">
                  <div>
                    {addresss.cust_address}, {addresss.custLandMark},{" "}
                    {addresss.custCity}
                  </div>
                  <div>
                    {addresss.custState} - {addresss.custPincode}
                  </div>
                </div>
                <div className="odpAddressAdrs">{addresss.order_phone}</div>
              </div>
              <div className="madAlignBtn">
                <button className="madBtn1" onClick={() => { handleEditDialog(addresss.custAddress_id) }}>Edit</button>
                <button className="madBtn2" onClick={() => { handleDeleteAddr(addresss.custAddress_id) }}>Delete</button>
              </div>
            </div>
          );
        })}
      </div>
      {compval?(<Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "16px",
              width: "100%",
              maxWidth: window.innerWidth / 2,
            },
          },
        }}
        open={openDialog}
      >
        {selectDelete?(<AddressDialog
          handleAddNewAddress={handleAddNewAddress}
          handleCloseDialog={handleCloseDialog}
        />):
        (
          <EditAddressDialog
            handleCloseDialog={handleCloseDialog}
            handleEditAddress={handleEditAddress}
            addressId={addrId}
            addrData={addresses}
          />
        )}
      </Dialog>):(
        <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              padding: "16px",
              width: "100%",
              maxWidth: window.innerWidth,
            },
          },
        }}
        open={openDialog}
      >
        {selectDelete?(<AddressDialog
          handleAddNewAddress={handleAddNewAddress}
          handleCloseDialog={handleCloseDialog}
        />):
        (
          <EditAddressDialog
            handleCloseDialog={handleCloseDialog}
            handleEditAddress={handleEditAddress}
            addressId={addrId}
            addrData={addresses}
          />
        )}
      </Dialog>
      )}
    </>
  );
};

export default MyAddresses;
