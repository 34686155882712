import React, { useEffect, useState } from "react";
import Avdert from "../Avdert/Avdert";
import DiamondPage1 from "../../components/DiamondPage/DiamondPage1/DiamondPage1";
import DiamondPage2 from "../../components/DiamondPage/DiamondPage2/DiamondPage2";
import Header from "../Header/Header";
import Home2 from "../../components/HomePage/Home2/Home2";
import DiamondPage3 from "../../components/DiamondPage/DiamondPage3/DiamondPage3";
import Engagement5 from "../../components/EngagementRingsPage/Engagement5/Engagement5";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import { Dialog, Hidden } from "@mui/material";
import { getData } from "../HTTPConfig/HTTPConfig";
import DiamondPage4 from "../../components/DiamondPage/DiamondPage4/DiamondPage4";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
import { useNavigate } from "react-router-dom";
const Diamond = () => {
  const navigate = useNavigate();
  const [compval, setCompval] = useState(false);
  const [data, setData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  useEffect(() => {
    const fetchRingPageData = async () => {
      const response = await getData("page/content/4");
      if (response?.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchRingPageData();
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);
  const dataToDisplay7 = data.find((data) => data.section === 7);
  const dataToDisplay8 = data.find((data) => data.section === 8);
  const dataToDisplay9 = data.find((data) => data.section === 9);
  const dataToDisplay10 = data.find((data) => data.section === 10);
  const dataToDisplay11 = data.find((data) => data.section === 11);

  return (
    <>
      <Avdert />
      <Header />
      <DiamondPage1 dataToDisplay1={dataToDisplay1} />
      <DiamondPage2 dataToDisplay2={dataToDisplay2} />
      <Home2 dataToDisplay={dataToDisplay3} />
      <DiamondPage3 dataToDisplay4={dataToDisplay4} />

      {compval ? (
        <div className="erContainer1">
          <img
            alt="images"
            src={dataToDisplay5?.image}
            width="100%"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay5?.title}</div>
            <div className="erMainText">
              {dataToDisplay5?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      ) : (
        <div className="erContainer1">
          <img
            alt="images"
            src={dataToDisplay5?.mobile_image}
            width="100%"
          />
          <div className="erContainer1AlignDiv">
            <div className="erMainHead">{dataToDisplay5?.title}</div>
            <div className="erMainText">
              {dataToDisplay5?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      )}

      <Hidden smDown>
        <div className="home3Container">
          <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-studs")}}>
            <img
              alt="images"
              width="100%"
              src={dataToDisplay6?.image}
            />
            <div className="home3ContainerDivA">
              <div className="home3ContainerH">{dataToDisplay6?.title}</div>
              <div className="home3ContainerT">
                {dataToDisplay6?.content?.replace(/<\/?p>/g, "")}
              </div>
            </div>
          </div>
          <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-necklace")}}>
            <img
              alt="images"
              width="100%"
              src={dataToDisplay7?.image}
            />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH"> {dataToDisplay7?.title}</div>
              <div className="home3ContainerT">
                {dataToDisplay7?.content?.replace(/<\/?p>/g, "")}
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="home3Container">
          <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-studs")}}>
            <img
              alt="images"
              width="100%"
              src={dataToDisplay6?.image}
            />
            <div className="home3ContainerDivA">
              <div className="home3ContainerH">{dataToDisplay6?.title}</div>
              <div className="home3ContainerT">
                {dataToDisplay6?.content?.replace(/<\/?p>/g, "")}
              </div>
            </div>
          </div>
          <div className="home3ContainerDiv" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-necklace")}}>
            <img
              alt="images"
              width="100%"
              src={dataToDisplay7?.image}
            />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH">{dataToDisplay7?.title}</div>
              <div className="home3ContainerT">
                {dataToDisplay7?.content?.replace(/<\/?p>/g, "")}
              </div>
            </div>
          </div>
        </div>
      </Hidden>

      <Engagement5 dataToDisplay8={dataToDisplay8} />

      <div
        style={
          compval
            ? { padding: " 72px 0px 0px 0px" }
            : { padding: "32px 0px 0px 0px" }
        }>
        {compval ? (
          <div className="eg3Div">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay9?.image}
              loading="lazy"
            />
            <div className="eg3Div12">
              <h2 className="eg3Head">{dataToDisplay9?.title}</h2>
              <p className="eg3Text">
                {dataToDisplay9?.content?.replace(/<\/?p>/g, "")}
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        ) : (
          <div className="eg3Div">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay9?.mobile_image}
              loading="lazy"
            />
            <div className="eg3Div12">
              <h2 className="eg3Head">{dataToDisplay9?.title}</h2>
              <p className="eg3Text">
                {dataToDisplay9?.content?.replace(/<\/?p>/g, "")}
              </p>
              <button className="homeBtn">Shop Now</button>
            </div>
          </div>
        )}
      </div>

      <DiamondPage4 dataToDisplay={dataToDisplay10} />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay11?.image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay11?.title}</h2>
            <p className="homeText">
              {dataToDisplay11?.content?.replace(/<\/?p>/g, "")}
            </p>
            <button
              className="homeBtn"
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay11?.mobile_image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay11?.title}</h2>
            <p className="homeText">
              {dataToDisplay11?.content?.replace(/<\/?p>/g, "")}
            </p>
            <button
              className="homeBtn"
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      )}

      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default Diamond;
