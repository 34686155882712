import React, { createRef } from "react";
import "./WeddingRings6.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const WeddingRings6 = (props) => {
  const { dataToDisplay8 } = props;
  // const rings = [
  //   { prices: "1,90,000", images: "/assets/image 202.svg" },
  //   { prices: "1,90,000", images: "/assets/image 201.svg" },
  //   { prices: "1,90,000", images: "/assets/image 200.svg" },
  //   { prices: "1,90,000", images: "/assets/image 199.svg" },
  // ];

  const getRings = () => {
    return dataToDisplay8?.subsection.map((rings, index) => {
      return (
        <div className="wr6RingDivSize" key={index}>
          <img alt="images" src={rings.image} className="wr6Img" />
          <div className="wr6RingName">{rings.title}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="wr6Container">
        <div className="wr6heading">{dataToDisplay8?.title}</div>
        <Hidden smDown>
          <div className="wr6AlignRingData">{getRings()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getRings()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default WeddingRings6;
