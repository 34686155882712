import React, { createRef } from "react";
import "./Home5.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
const Home5 = (props) => {
  const { dataToDisplay } = props;

  // const home5Data = [
  //   {
  //     images: "/assets/category 1.svg",
  //     mHead: "Engagement Rings",
  //     createdBy: "by Samasva",
  //   },
  //   {
  //     images: "/assets/category 2.svg",
  //     mHead: "Women's Wedding Ring",
  //     createdBy: "by Samasva",
  //   },
  //   {
  //     images: "/assets/category 3.svg",
  //     mHead: "Men's Wedding Ring",
  //     createdBy: "by Samasva",
  //   },
  //   {
  //     images: "/assets/category 4.svg",
  //     mHead: "Fine Jewellery",
  //     createdBy: "by Samasva",
  //   },
  // ];
  const navigate=useNavigate();

  const getHome5data = () => {
    return dataToDisplay?.subsection.map((item, index) => {
      return (
        <div key={index} className="alignHome5ItemsDiv" onClick={()=>{navigate("/select-setting")}} style={{cursor:"pointer"}}>
          <img alt="images" src={item.image} className="home5ContainerImg" />
          <div>
            <div className="home5Mhead">{item.title}</div>
            {/* <div className="home5Cre">{item.content}</div> */}
          </div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
  };

  return (
    <>
      <div className="home5Container">
        <div className="home5Heading">{dataToDisplay?.title}</div>
        <Hidden smDown>
          <div className="alignItemsHome5">{getHome5data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome5data()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default Home5;
