import React, { useState } from "react";
import './ContactUsDialog.css';
import closeIcon from './close.png';
import { postData } from "../../userInterface/HTTPConfig/HTTPConfig";
const ContactUsDialog = (props) => {

  const {handleCloseDialog}=props;

  const [name, setName]=useState('');
  const [email, setEmail]=useState('');
  const [subject, setSubject]=useState('');
  const [message, setMessage]=useState('');

  const handleSendMessage=async()=>{
    const headers={"email":email, "name":name, "subject":subject, "message":message}
    const response=await postData("customer/enquiry", headers);
    console.log(response);
    if(response.data){
      handleCloseDialog();
    }
  }
  return (
    <>
      <div className="cudContainer">
        <img src={closeIcon} alt="close" width={24} className="cudCloseIcon" onClick={()=>{handleCloseDialog()}} />
        <div className="cudMainHead">Send us a message</div>
        <div className="cudAlignInput">
          <div className="cudAlignInput1">
            <div className="cudName">Name</div>
            <input type="text" className="cudInput1" value={name} onChange={(e)=>setName(e.target.value)} />
          </div>
          <div className="cudAlignInput1">
            <div className="cudName">Email</div>
            <input type="text" className="cudInput1" value={email} onChange={(e)=>setEmail(e.target.value)} />
          </div>
        </div>

        <div className="cudAlignInput2">
          <div className="cudName">Subject</div>
          <input type="text" className="cudInput1" value={subject} onChange={(e)=>setSubject(e.target.value)} />
        </div>

        <div className="cudAlignInput2">
          <div className="cudName">Message</div>
          <textarea type="text" rows={12} className="cudTextArea" value={message} onChange={(e)=>setMessage(e.target.value)}/>
        </div>

        <div className="cudAlignInput3">
          <button className="cudBtnss" onClick={()=>{handleSendMessage()}}>Send Message</button>
        </div>
      </div>
    </>
  );
};

export default ContactUsDialog;
