import React, { createRef } from "react";
import "./WeddingRings4.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const WeddingRings4 = (props) => {
  const { dataToDisplay5 } = props;
  // const rings = [
  //   { name: "classic", images: "/assets/image 181.svg" },
  //   { name: "classic", images: "/assets/image 182.svg" },
  //   { name: "classic", images: "/assets/image 183.svg" },
  //   { name: "classic", images: "/assets/image 184.svg" },
  //   { name: "classic", images: "/assets/image 185.svg" },
  //   { name: "classic", images: "/assets/image 186.svg" },
  //   { name: "classic", images: "/assets/image 187.svg" },
  //   { name: "classic", images: "/assets/image 188.svg" },
  // ];

  const getRings = () => {
    return dataToDisplay5?.subsection.map((rings, index) => {
      return (
        <div className="wr4RingDivSize" key={index}>
          <img alt="images" src={rings.image} className="wr4Img" />
          <div className="wr4RingName">{rings.title}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="wr4Container">
        <div className="wr4heading">{dataToDisplay5?.title}</div>
        <Hidden smDown>
          <div className="wr4AlignRingData">{getRings()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getRings()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default WeddingRings4;
