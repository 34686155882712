import React, { useEffect, useState } from "react";
import "./HomePage.css";
import Home1 from "./Home1/Home1";
import Home2 from "./Home2/Home2";
import Home3 from "./Home3/Home3";
import Home5 from "./Home5/Home5";
import Home6 from "./Home6/Home6";
import Home7 from "./Home7/Home7";
import Home8 from "./Home8/Home8";
import Home9 from "./Home9/Home9";
import Home10 from "./Home10/Home10";
import Home4 from "./Home4/Home4";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../ContactUsDialog/ContactUsDialog";

const HomePage = (props) => {
  const [compval, setCompval] = useState(false);
  const { data } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
    const timeoutId = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);
  const dataToDisplay7 = data.find((data) => data.section === 7);
  const dataToDisplay8 = data.find((data) => data.section === 8);
  const dataToDisplay9 = data.find((data) => data.section === 9);
  const dataToDisplay10 = data.find((data) => data.section === 10);

  return (
    <>
      <div className="homeContainer">
        <Home6 dataToDisplay={dataToDisplay1} />
        <Home1 dataToDisplay={dataToDisplay2} />
        <Home2 dataToDisplay={dataToDisplay3} />
        <Home4 dataToDisplay={dataToDisplay4} />
        <Home3 dataToDisplay={dataToDisplay5} />
        <Home5 dataToDisplay={dataToDisplay6} />
        <Home9 dataToDisplay={dataToDisplay7} />
        <Home7 dataToDisplay={dataToDisplay8} />

        {compval ? (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay9?.image}
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">{dataToDisplay9?.title}</h2>
              <p className="homeText">
                {dataToDisplay9?.content.replace(/<\/?p>/g, "")}
              </p>
              <button
                className="homeBtn"
                onClick={() => {
                  handleDialog();
                }}>
                Consult
              </button>
            </div>
          </div>
        ) : (
          <div className="homeDiv">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay9?.mobile_image}
              loading="lazy"
            />
            <div className="homeDiv12">
              <h2 className="homeHead">{dataToDisplay9?.title}</h2>
              <p className="homeText">
                {dataToDisplay9?.content.replace(/<\/?p>/g, "")}
              </p>
              <button
                className="homeBtn"
                onClick={() => {
                  handleDialog();
                }}>
                Consult
              </button>
            </div>
          </div>
        )}

        <Home8 dataToDisplay={dataToDisplay10} />
        <Home10 />
      </div>
      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default HomePage;
