import React from "react";
import "./GemstonePage3.css";
import { Hidden } from "@mui/material";

const GemstonePage3 = () => {
  return (
    <>
      <Hidden smDown>
        <div className="home3Container">
          <div className="home3ContainerDiv">
            <img alt="images" width="100%" src="/assets/Group 211001878.svg" />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH">Design your own Studs</div>
              <div className="home3ContainerT">
                Design the perfect studs that reflect your style! Choose your
                diamonds, metals, and settings to make a statement uniquely
                yours.
              </div>
            </div>
          </div>
          <div className="home3ContainerDiv">
            <img alt="images" width="100%" src="/assets/Group 211001879.svg" />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH">Design your own Pendant</div>
              <div className="home3ContainerT">
                Design your own unique pendant that reflects your style & story.
              </div>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="home3Container">
          <div className="home3ContainerDiv">
            <img alt="images" width="100%" src="/assets/Group 211001879.svg" />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH">Design your own Earrings</div>
              <div className="home3ContainerT">
                Craft your unique engagement ring by starting with either the
                ring setting or the center stone selection.The decision is
                entirely in your hands.
              </div>
            </div>
          </div>
          <div className="home3ContainerDiv">
            <img alt="images" width="100%" src="/assets/Group 211001878.svg" />
            <div className="home3ContainerDivB">
              <div className="home3ContainerH">Design your own Pendant</div>
              <div className="home3ContainerT">
                Craft your unique engagement ring by starting with either the
                ring setting or the center stone selection.The decision is
                entirely in your hands.
              </div>
            </div>
          </div>
        </div>
      </Hidden>
    </>
  );
};

export default GemstonePage3;
