import React from "react";
import "./BuyingGuidePage1.css";
import { Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";

const BuyingGuidePage1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <Hidden smDown>
        <div className="bg1AlignData">
          <img
            alt="images"
            width="100%"
            src="/assets/bug1.webp"
            loading="lazy"
          />
          <div className="bg1AlignData1">
            <div className="bg1Head">Diamond Buying Guide</div>
            <div className="bg1Text">
              Embark on a journey to find your perfect diamond with our
              comprehensive buying guide. Discover valuable tips, insights, and
              expert advice to help you make an informed and sparkling choice.
              From understanding the 4Cs to navigating styles, let's illuminate
              your path to timeless elegance.
            </div>
            <div className="bg1AlignBTn">
              <button className="bg1BTn" onClick={()=>{navigate("/select-diamond")}} style={{cursor:"pointer"}}>Shop Natural Diamonds</button>
              <button className="bg1BTn" onClick={()=>{navigate("/select-diamond")}} style={{cursor:"pointer"}}>Shop Lab-Grown Diamonds</button>
            </div>
            <div>
              <button className="bg1BTn" onClick={()=>{navigate("/diamond-dna")}} style={{cursor:"pointer"}}>Shop DNA Diamonds</button>
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="bg1AlignData">
          <img
            alt="images"
            width="100%"
            src="/assets/bug1M.webp"
            loading="lazy"
          />
          <div className="bg1AlignData1">
            <div className="bg1Head">Diamond Buying Guide</div>
            <div className="bg1Text">
              Embark on a journey to find your perfect diamond with our
              comprehensive buying guide. Discover valuable tips, insights, and
              expert advice to help you make an informed and sparkling choice.
              From understanding the 4Cs to navigating styles, let's illuminate
              your path to timeless elegance.
            </div>
            <div className="bg1AlignBTn">
              <button className="bg1BTn" onClick={()=>{navigate("/select-diamond")}} style={{cursor:"pointer"}}>Shop Natural Diamonds</button>
              <button className="bg1BTn" onClick={()=>{navigate("/select-diamond")}} style={{cursor:"pointer"}}>Shop Lab-Grown Diamonds</button>
            </div>
            <div>
              <button className="bg1BTn" onClick={()=>{navigate("/diamond-dna")}} style={{cursor:"pointer"}}>Shop DNA Diamonds</button>
            </div>
          </div>
        </div>
      </Hidden>
    </>
  );
};
export default BuyingGuidePage1;
