import React, { useEffect, useState } from "react";
import "./HeaderDialog.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import rightIcon from "./Vector 37 (Stroke).png";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";
const HeaderDialog = (props) => {
  const {
    handleWeddRings,
    engageRings,
    weddRings,
    ringDialog,
    handleEngageRings,
    // setRingDialog,
    setEngageRings,
    setWeddRings,
  } = props;

  const navigate =useNavigate();

  const [mensDataToShow, setMensDataToShow] = useState(false);
  const [womenDataToShow, setWomenDataToShow] = useState(false);

  const [shapeData, setShapeData] = useState([]);
  const [metalData, setMetalData] = useState([]);

  // const [apiData, setApiData] = useState([]);

  const showMens = () => {
    setMensDataToShow(true);
    setWomenDataToShow(false);
  };

  const showWomens = () => {
    setWomenDataToShow(true);
    setMensDataToShow(false);
  };

  useEffect(()=>{
    const fetchData=async()=>{
      const response=await getData('getallattributeandvalue');
      setShapeData(response?.data?.[0]?.valuedata);
      setMetalData(response?.data?.[1]?.valuedata);
    };
    fetchData();
  },[]);
  return (
    <>
      {ringDialog && (
        <div
          // onMouseLeave={() => setRingDialog(false)}
          className="headerDialogContainerMain"
        >
          <div className="headerDialogMain">
           
            <div onClick={handleEngageRings} className="headerDialogMainHead">
              Engagement Rings <img src={rightIcon} alt="next" className="engaeRingNextIcon" />
            </div>
           
            <div onClick={handleWeddRings} className="headerDialogMainHead1">
              Wedding Rings <img src={rightIcon} alt="next" className="engaeRingNextIcon" />
            </div>
            
          </div>
          <div className="headerDialogMain">
            <img alt="images" src="/assets/image 381.svg" width="100%" />
          </div>
        </div>
      )}
      {engageRings && (
        <div
          onMouseLeave={() => setEngageRings(false)}
          className="headerDialogContainer"
        >
          <img src={rightIcon} alt="back" className="backIconImg" onClick={()=>setEngageRings(false)} />
          <div className="headerDialogContainerDiv">
            <div className="headerDialogContainer1">
              <div>
                <div className="headerDialogHeading">Create your own ring</div>
                <div className="headerDialogAlignDiv">
                  <div className="headerDialogAlignDiv1">
                    <img alt="images" src="/assets/h1.png" width="24px" />
                    <div className="headerDialogtexts" onClick={()=>{navigate("/select-diamond")}}>
                      Start with an Aura Diamond
                    </div>
                  </div>
                  <div className="headerDialogAlignDiv1">
                    <img alt="images" src="/assets/h2.png" width="24px" />
                    <div className="headerDialogtexts" onClick={()=>{navigate("/select-setting")}}>
                      Start with a setting
                    </div>
                  </div>
                  <div className="headerDialogAlignDiv1">
                    <img alt="images" src="/assets/h3.png" width="24px" />
                    <div className="headerDialogtexts" onClick={()=>{navigate("/diamond-dna")}}>
                      Start with a DNA Diamond
                    </div>
                  </div>
                </div>
              </div>
              <div className="headerDialogAlignDiv">
              <div className="headerDialogHeading">Shop By Metal</div>
                {metalData?.map((item, index)=>{
                  return(
                  
                <div className="headerDialogAlignDiv1" key={index}>
                  <div
                    style={{ backgroundColor: item.cust_attr_value==="White Gold"?"#EFEFEF":item.cust_attr_value==="Yellow Gold"?"#E9D590":item.cust_attr_value==="Rose Gold"?"#F5C8A9":"#C8C8C8" }}
                    className="headerDialogColorDiv"
                  ></div>
                  <div className="headerDialogtexts">{item.cust_attr_value}</div>
                </div>
             
                  )
                })}
              </div>
            </div>
            <div>
              <div className="headerDialogHeading">Shop By Shape</div>
              <div className="headerDialogAlignDivx">
                {shapeData?.map((item, index)=>{
                  return(
                    <div className="headerDialogAlignDiv1" key={index}>
                  <img alt="images" src={item.attribute_image} width="48px" />
                  <div className="headerDialogtexts">{item.cust_attr_value}</div>
                </div>
                  )
                })}
              </div>
            </div>
            <div>
              <div className="headerDialogHeading">Shop By Style</div>
              <div className="headerDialogAlignDiv">
                <div className="headerDialogtexts">Solitaire</div>
                <div className="headerDialogtexts">Halo</div>
                <div className="headerDialogtexts">Pave</div>
                <div className="headerDialogtexts">Three Stone</div>
                <div className="headerDialogtexts">Vintage</div>
                <div className="headerDialogtexts">Infinity</div>
                <div className="headerDialogtexts">Matching Sets</div>
              </div>
            </div>
          </div>
          <div className="headerDialogColorDiv1">
            <img alt="images" src="/assets/image 216.png" width="100%" />
          </div>
        </div>
      )}

      {weddRings && (
        <div
          onMouseLeave={() => setWeddRings(false)}
          className="fineDialogContainer"
        >
          <img src={rightIcon} alt="back" className="backIconImg" onClick={()=>setWeddRings(false)} />
          <div className="fineDialogContainer1">
            <div className="fineDialogAlignDiv">
              <div onClick={showMens} className="fineDialogAlignDiv1">
                <div className="fineDialogheading">Men's Wedding Ring</div>
                <div>
                  {mensDataToShow ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )}
                </div>
              </div>
              {mensDataToShow && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div className="headerDialogtexts">Classic Rings</div>
                  <div className="headerDialogtexts">Matte Rings</div>
                  <div className="headerDialogtexts">Textured Rings</div>
                  <div className="headerDialogtexts">Diamond Rings</div>
                </div>
              )}
              <div onClick={showWomens} className="fineDialogAlignDiv1">
                <div className="fineDialogheading">Women's Wedding Ring</div>
                {womenDataToShow ? (
                  <KeyboardArrowDownIcon />
                ) : (
                  <KeyboardArrowRightIcon />
                )}
              </div>
              {womenDataToShow && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <div className="headerDialogtexts">Eternity</div>
                  <div className="headerDialogtexts">Curved</div>
                  <div className="headerDialogtexts">Plain</div>
                  <div className="headerDialogtexts">Classic</div>
                  <div className="headerDialogtexts">Vintage</div>
                  <div className="headerDialogtexts">Stackable</div>
                  <div className="headerDialogtexts">Aura Lab Diamond</div>
                  <div className="headerDialogtexts">Aura Natural Diamond</div>
                </div>
              )}
              <div className="fineDialogheading">New Arrivals</div>
            </div>
            <div className="headerDialogAlignDiv">
              <div className="fineDialogheading">Shop By Metal</div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{ backgroundColor: "#EFEFEF" }}
                  className="headerDialogColorDiv"
                ></div>
                <div className="headerDialogtexts">White Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{ backgroundColor: "#E9D590" }}
                  className="headerDialogColorDiv"
                ></div>
                <div className="headerDialogtexts">Yellow Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{ backgroundColor: "#F5C8A9" }}
                  className="headerDialogColorDiv"
                ></div>
                <div className="headerDialogtexts">Rose Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{ backgroundColor: "#C8C8C8" }}
                  className="headerDialogColorDiv"
                ></div>
                <div className="headerDialogtexts">Platinum</div>
              </div>
            </div>
          </div>
          <div className="fineDialogContainer2">
            <img alt="images" src="/assets/image 290.png" width="100%" />
          </div>
        </div>
      )}
    </>
  );
};

export default HeaderDialog;
