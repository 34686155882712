import React from "react";
import "./DiamondProductPage.css";
// import { useNavigate } from "react-router-dom";
import DiamondCategory from "../SelectDiamondPage/DiamondCategory/DiamondCategory";
import { Hidden } from "@mui/material";

const DiamondProductPage = (props) => {
  const { handleDiamondStatus, diamonds } = props;
  // const navigate = useNavigate();

  return (
    <>
      <div className="diamondProductPageContainer">
        <div className="diamondProductPageDiv1">
          <div className="diamondProductImgDiv1">
            <img
              alt="images"
              src="/assets/image 165.png"
              className="diamondProductImg1"
            />
          </div>
          <div className="diamondProductImgDiv2">
            <img
              alt="images"
              src="/assets/image 165.png"
              className="diamondProductImg2"
            />
            <img
              alt="images"
              src="/assets/image 166.png"
              className="diamondProductImg2"
            />
            <img
              alt="images"
              src="/assets/image 167.png"
              className="diamondProductImg2"
            />
          </div>
        </div>
        <div className="diamondProductPageDiv2">
          <div className="diamondProductPageDiv2Heading">
            0.30 Carat Round diamond
            <div className="diamondProductPageDiv2Texts">
              This natural Cushion Cut diamond contains slight inclusions that
              are rarely seen with the naked eye and often noticeable at 10x
              magnification.
            </div>
            <div className="diamondProductPageDiv2Textss2">
              Colour I | Clarity SI2 | Cut EX
            </div>
          </div>
          <div className="diamondProductPageDiv2Textss2">
            Certified & Conflict Free
          </div>
          <div className="diamondProductPrice">USD 1,90,000</div>
          <div className="diamondProductPageDiv2Texts1">
            Price is for setting only.
          </div>

          <div>
            <button
              onClick={handleDiamondStatus}
              className="diamondProductbtn1"
            >
              Select the Diamond
            </button>
          </div>
        </div>
      </div>

      <Hidden smDown>
        <div className="diamondProductPageContainerPints">
          <div className="diamondProductPageDiv2Heading1">Diamond Details</div>
          <div className="diamondProductPageContainerPintsC">
            <div className="diamondProductCarats1">Shape: Round</div>
            <div className="diamondProductCarats1">Table: 58.0</div>
            <div className="diamondProductCarats2">Carat: 0.70 ct</div>
            <div className="diamondProductCarats2">Culet: N</div>
            <div className="diamondProductCarats1">Color: G</div>
            <div className="diamondProductCarats1">Depth: 63</div>
            <div className="diamondProductCarats2">Clarity: SI1</div>
            <div className="diamondProductCarats2">
              Gridle: Slightly Thick to Thick
            </div>
            <div className="diamondProductCarats1">Cut: Very Good</div>
            <div className="diamondProductCarats1">L/W (mm): 6.2x6.25x3.97</div>
            <div className="diamondProductCarats2">Polish: Excellent</div>
            <div className="diamondProductCarats2">L/W Ratio: 1.01</div>
            <div className="diamondProductCarats1">Symmetry: Excellent</div>
            <div className="diamondProductCarats1">Certification: GIA</div>
            <div className="diamondProductCarats2">Fluorescence: None</div>
          </div>
        </div>
      </Hidden>

      <Hidden smUp>
        <div className="diamondProductPageContainerPints">
          <div className="diamondProductPageDiv2Heading1">Diamond Details</div>
          <div className="diamondProductPageContainerPintsC">
            <div className="diamondProductCarats1">Shape: Round</div>
            <div className="diamondProductCarats2">Table: 58.0</div>
            <div className="diamondProductCarats1">Carat: 0.70 ct</div>
            <div className="diamondProductCarats2">Culet: N</div>
            <div className="diamondProductCarats1">Color: G</div>
            <div className="diamondProductCarats2">Depth: 63</div>
            <div className="diamondProductCarats1">Clarity: SI1</div>
            <div className="diamondProductCarats2">
              Gridle: Slightly Thick to Thick
            </div>
            <div className="diamondProductCarats1">Cut: Very Good</div>
            <div className="diamondProductCarats2">L/W (mm): 6.2x6.25x3.97</div>
            <div className="diamondProductCarats1">Polish: Excellent</div>
            <div className="diamondProductCarats2">L/W Ratio: 1.01</div>
            <div className="diamondProductCarats1">Symmetry: Excellent</div>
            <div className="diamondProductCarats2">Certification: GIA</div>
            <div className="diamondProductCarats1">Fluorescence: None</div>
          </div>
        </div>
      </Hidden>

      <div className="diamondProductHeaddd">You May Also Like</div>
      <div className="diamondCategoryContainer">
        {diamonds.map((diamonds, index) => {
          return <DiamondCategory key={index} diamonds={diamonds} />;
        })}
      </div>

      {/* <div className="diamondProductPageContainer1">
        <div className="diamondProductPagebg">
          <div className="diamondProductPageContainer1Head">Carat</div>
          <div className="diamondProductPageContainer1Texts">
            Diamonds are sold by the carat (ct), which is a unit of weight, not
            size. One carat weighs 200 milligrams, the world standard since
            1914. A carat is divided into 100 points, with one point
            corresponding to .01 carat.
          </div>
          <div className="diamondProductPageContainer1Head1">Your Diamond</div>
          <div className="diamondProductPageContainer1Texts1">1.01 CT</div>
          <img
            alt="images"
            src="/assets/image 176.png"
            onResize={"contain"}
            className="diamondProductImg3"
          />
        </div>
      </div> */}
    </>
  );
};

export default DiamondProductPage;
