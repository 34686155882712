import React from "react";
import "./SelectSetting2.css";

const SelectSetting2 = (props) => {
  const { active } = props;
  return (
    <>
      <div className="selectSetting2Container1">
        <div className="selectSetting2Container1Sub">
          <div
            className={active ? "selectSetting2BoxesU" : "selectSetting2BoxesA"}
          >
            <div
              className={active ? "selectSetting2NumU" : "selectSetting2NumA"}
            >
              1
            </div>
            <div
              className={active ? "selectSetting2TextU" : "selectSetting2TextA"}
            >
              Select your Setting
            </div>
          </div>
          <div
            className={
              !active ? "selectSetting2BoxesU" : "selectSetting2BoxesA"
            }
          >
            <div
              className={!active ? "selectSetting2NumU" : "selectSetting2NumA"}
            >
              2
            </div>
            <div
              className={
                !active ? "selectSetting2TextU" : "selectSetting2TextA"
              }
            >
              Choose Diamond
            </div>
          </div>
          <div
            className={
              !active ? "selectSetting2BoxesU" : "selectSetting2BoxesA"
            }
          >
            <div
              className={!active ? "selectSetting2NumU" : "selectSetting2NumA"}
            >
              3
            </div>
            <div
              className={
                !active ? "selectSetting2TextU" : "selectSetting2TextA"
              }
            >
              Complete Ring
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectSetting2;
