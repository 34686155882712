import React from "react";
import "./BuyingGuidePage4.css";

const BuyingGuidePage4 = () => {
  return (
    <>
      <div className="bg4Container">
        <div className="bg4Head">The 4 Cs</div>
        <img alt="aimages" src="/assets/Group 762.svg" width='70%' />
        <div className="bg4MHead">Diamond Cut</div>
        <div className="bg4Text">
          The cut of a diamond refers to how well it's shaped and faceted. It
          greatly impacts how light is reflected, enhancing its sparkle and
          brilliance.
        </div>
        <img alt="aimages" src="/assets/image 4231.svg" className="bg4Img" />
        <div className="bg4MHead">Diamond Color</div>
        <div className="bg4Text">
          Diamonds come in various shades, from colorless to light yellow or
          brown. The less color, the more valuable the diamond.
        </div>
        <img alt="aimages" src="/assets/image 394.png" className="bg4Img1" />
        <div className="bg4MHead">Diamond Clarity</div>
        <div className="bg4Text">
          Clarity measures the presence of any imperfections or inclusions
          within the diamond. The fewer, the higher the clarity and value.
        </div>
        <img alt="aimages" src="/assets/image 395.png" className="bg4Img" />
        <div className="bg4MHead">Diamond Carat</div>
        <div className="bg4Text">
        Carat is the unit of weight for diamonds. A higher carat weight indicates a larger diamond, which is often more valuable.
        </div>
      </div>
    </>
  );
};

export default BuyingGuidePage4;
