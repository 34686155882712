import LoginInterface from "./userInterface/LoginInterface/LoginInterface";
import DiamondProduct from "./userInterface/DiamondProduct/DiamondProduct";
import Engagement from "./userInterface/Engagement/Engagement";
import Home from "./userInterface/HomePage/Home";
import SelectDiamond from "./userInterface/SelectDiamond/SelectDiamond";
import SelectSetting from "./userInterface/SelectSetting/SelectSetting";
import Settingproduct from "./userInterface/Settingproduct/Settingproduct";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ShoppingBag from "./userInterface/ShoppingBag/ShoppingBag";
import AboutUs from "./userInterface/AboutUs/AboutUs";
import WeddingRings from "./userInterface/WeddingRings/WeddingRings";
import Diamond from "./userInterface/Diamond/Diamond";
import Gemstone from "./userInterface/Gemstone/Gemstone";
import Jewellery from "./userInterface/Jewellery/Jewellery";
import Dna from "./userInterface/DNA/Dna";
import SelectDiamond1 from "./userInterface/SelectDiamond1/SelectDiamond1";
import BuyingGuide from "./userInterface/BuyingGuide/BuyingGuide";
import CustomerDetails from "./userInterface/CustomerDetails/CustomerDetails";
import ContactUs from "./userInterface/ContactUs/ContactUs";
import RingsPage from "./userInterface/RingsPage/RingsPage";
import BuyBacPage from "./userInterface/BuyBacPage/BuyBacPage";
import Bracelete from "./userInterface/Bracelete/Bracelete";
import Necklace from "./userInterface/Necklace/Necklace";
import EarRings from "./userInterface/EarRings/EarRings";
import Gifts from "./userInterface/Gifts/Gifts";
import ThankYou from "./userInterface/ThankYou/ThankYou";
import MyOrder from "./userInterface/MyOrder/MyOrder";
import OrderDetails from "./userInterface/OrderDetails/OrderDetails";
import Addresses from "./userInterface/Addresses/Addresses";
import Profile from "./userInterface/Profile/Profile";
import Register from "./userInterface/Register/Register";
import AllProduct from "./userInterface/AllProduct/AllProduct";
import SelectNecklace from "./userInterface/SelectNecklace/SelectNecklace";
import SelectStuds from "./userInterface/SelectStuds/SelectStuds";
import CompleteRing from "./userInterface/CompleteRing/CompleteRing";
function App() {
  return (
    <Router>
      <Routes>
        <Route Component={Home} path="/" />
        <Route Component={LoginInterface} path="/login" />
        <Route Component={Engagement} path="/engagement-ring" />
        <Route Component={SelectSetting} path="/select-setting" />
        <Route Component={Settingproduct} path="/product/:slug" />
        <Route Component={SelectDiamond} path="/select-diamond" />
        <Route Component={DiamondProduct} path="/diamond-product" />
        <Route Component={ShoppingBag} path="/shopping-bag" />
        <Route Component={AboutUs} path="/about-us" />
        <Route Component={WeddingRings} path="/wedding-rings" />
        <Route Component={Diamond} path="/diamond-page" />
        <Route Component={Gemstone} path="/gemstone" />
        <Route Component={Jewellery} path="/jewellery" />
        <Route Component={Dna} path="/diamond-dna" />
        <Route Component={SelectDiamond1} path="/select-diamond1" />
        <Route Component={BuyingGuide} path="/buying-guide" />
        <Route Component={CustomerDetails} path="/customer-details" />
        <Route Component={ContactUs} path="/contact-us" />
        <Route Component={RingsPage} path="/rings" />
        <Route Component={BuyBacPage} path="/buy-bac" />
        <Route Component={Bracelete} path="/bracelete" />
        <Route Component={Necklace} path="/necklace" />
        <Route Component={EarRings} path="/ear-rings" />
        <Route Component={Gifts} path="/gifts" />
        <Route Component={ThankYou} path="/order/payment/thankYou/:slug" />
        <Route Component={MyOrder} path="/my-order" />
        <Route Component={OrderDetails} path="/order-details" />
        <Route Component={Addresses} path="/my-addresses" />
        <Route Component={Profile} path="/my-profile" />
        <Route Component={Register} path="/register" />
        <Route Component={Settingproduct} path="/setting-product" />
        <Route Component={AllProduct} path="/all-product" />
        <Route Component={SelectNecklace} path="/select-necklace" />
        <Route Component={SelectStuds} path="/select-studs" />
        <Route Component={CompleteRing} path="/complete-ring" />
      </Routes>
    </Router>
  );
}

export default App;
