import React from "react";
import "./WeddingRings1.css";
const WeddingRings1 = (props) => {
  const { dataToDisplay1 } = props;
  return (
    <>
      <div className="wr1Container">
        <div className="wr1Heading">{dataToDisplay1?.title}</div>
        <div className="wr1texts">
          {dataToDisplay1?.content.replace(/<\/?p>/g, "")}
        </div>
      </div>
    </>
  );
};

export default WeddingRings1;
