import React from "react";
import "./SelectDiamond1C5.css";

const SelectDiamond1C5 = () => {
  return (
    <>
      <div className="sd5Container">
        <div className="sd5Head">
          Select Color, Shape & carat to know the price:
        </div>
        <div className="sd5AlignDiv">
          <div className="sd5Div1">Natural Diamond</div>
          <div className="sd5Div2">Lab-Grown Diamond</div>
        </div>
      </div>
    </>
  );
};

export default SelectDiamond1C5;
