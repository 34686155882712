import React from "react";
import "./DnaPage2.css";
const DnaPage2 = () => {
  const arr = [
    {
      name: "DNA Diamond welcome kit",
      text: "Our welcome kit guides you through the simple process, using hair, love letters, or anything with extractable carbon to create our special Diamonds.",
      images: "/assets/gh.png",
    },
    {
      name: "Carbon extraction",
      text: "To create the Diamond, we isolate carbon from hair or carbon-extractable sources. Lab techs purify it in a crucible.",
      images: "/assets/image 276.png",
    },
    {
      name: "Diamond Growth",
      text: "Purified carbon is transformed into a unique Diamond, tailored to each individual's carbon content.",
      images: "/assets/bbb.png",
    },
    {
      name: "Manufacturing rough diamaond to Cut & Polish Dimaond",
      text: "After inspection, our experts hand-cut the Diamond to your requested shape, bringing it from rough to glowing",
      images: "/assets/dfgjsf.png",
    },
    {
      name: "Quality Inspection",
      text: "Now, we examine the Diamond for inclusions—natural flaws or bumps. Our inspectors pinpoint these to cut around them, ensuring an exceptional Diamond.",
      images: "/assets/image 350.png",
    },
  ];

  const getArr = () => {
    return arr.map((item) => {
      return (
        <div className="dn2Divs">
          <img alt="images" src={item.images} className="dn2Img" />
          <div className="dn2head">{item.name}</div>
          <div className="dn2Text">{item.text}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="dn2Container">
        <div className="dn2Mhead">The DNA Diamond Process</div>
        <div className="dn2Mtext">
          Throughout your journey to create a diamond with Samasva, a caring
          customer experience specialist will support you through each step.
        </div>
        <div className="dn2AlignData">{getArr()}</div>
      </div>
    </>
  );
};

export default DnaPage2;
