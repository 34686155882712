import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import BuyBac from "../../components/BuyBac/BuyBac";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
const BuyBacPage = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      <Avdert />
      <Header />
      <BuyBac />
      {compval ? (
        <div className="homeDiv">
          <img alt="images" width="100%" src="/assets/Group 558.svg" />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img alt="images" width="100%" src="/assets/Group 211001871.svg" />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      )}
      <Home8 />
      <Home10 />
      <FooterPage />
    </>
  );
};

export default BuyBacPage;
