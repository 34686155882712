import React, { useEffect, useState } from "react";
import "./BuyBac.css";

const BuyBac = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);
  return (
    <>
      {compval ? (
        <div className="bBContainer">
          <img alt="images" src="/assets/bac1.webp" width="100%" />
          <div className="bBContainerDiv">
            <div className="buyHead2">
              Unmatched Value: Samasva's{" "}
              <span className="buyHead3">
                Buy<span className="buyHead1">BAC</span> Program
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="bBContainer">
          <img alt="images" src="/assets/bac1M.webp" width="100%" />
          <div className="bBContainerDiv">
            <div className="buyHead2">
              Unmatched Value: Samasva's{" "}
              <span className="buyHead3">
                Buy<span className="buyHead1">BAC</span> Program
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="buyBacContainer">
        <div>
          <div className="buyBacHeading">
            Your Path to Flexible Jewellery Investments
          </div>
          <div className="buyBacText">
            The Samasva BuyBack Program is a unique initiative that provides our
            customers with a flexible and sustainable approach to jewelry
            ownership. We understand that preferences and circumstances may
            change over time, and our BuyBack Program is designed to adapt to
            these shifts.
          </div>
        </div>
        <div>
          <div className="buyBacHeading">How It Works</div>
          <div className="buyBacText">
            When you purchase any diamond jewelry product from Samasva, you not
            only acquire a stunning piece but also gain the freedom to consider
            its future. If at any point you wish to part with your jewelry,
            simply opt for our BuyBack Program.
          </div>
        </div>
        <div>
          <div className="buyBacHeading1">The Benefits</div>
          <div className="buyBacText">
            <div>
              1. Exchange Options: Prefer a different Samasva product? Exchange
              your jewelry and get up to 85% of on the diamond at prevailing
              market rate & Gold rate as per the current Gold rate.{" "}
            </div>
            <div>
              2. Flexible Returns: Return your purchased jewelry and receive a
              70% buyback on the diamond at prevailing market rate & Gold rate
              as per the current Gold rate.
            </div>
            <div>
              3. No Time Limit: Enjoy the liberty to make your decision at your
              pace; there's no fixed time limit for initiating a return or
              exchange.
            </div>
          </div>
        </div>
        <div>
          <div className="buyBacHeading1">
            Understanding Your Jewelry's Value
          </div>
          <div className="buyBacText">
            Mrs. Erica bought a diamond jewelry for $10,000, When Diamond price
            is $8,500 and setting for $1500. After 5 years, Mrs. Erica decides
            to return the jewelry, & she has two options.
          </div>
          <div className="buyBacText1">
            (Diamond rate after 5 years becomes $12,500 according to the market
            conditions at that time.)
          </div>

          <div className="buyBacPoints">
            <div className="buyBacPoint">1</div>
            <div className="buyBacText">
              Exchange at 85% on diamond price $12,500 x 85% = $10, 625
            </div>
          </div>
          <div className="buyBacPoints">
            <div className="buyBacPoint">2</div>
            <div className="buyBacText">
              The diamond can be returned at 70%, and the refund will be to its
              original payment method. 12,500 x 70% = $8,750
            </div>
          </div>
          <div className="buyBacPoints">
            <div className="buyBacPoint">3</div>
            <div className="buyBacText">
              Metal value will be calculated at current rate.( Gold & Platinum)
            </div>
          </div>

          <div className="buyBacText1">
            Disclaimer: This is just an example provided for understanding.
          </div>
        </div>

        <div>
          <div className="buyBacHeading">How to Initiate</div>
          <div className="buyBacText">
            Reach out to our dedicated customer service team, and they will
            guide you through the hassle-free process of initiating a return or
            exchange.
          </div>
        </div>

        <div>
          <div className="buyBacHeading">Bring a Change</div>
          <div className="buyBacText">
            Reach out to our dedicated customer service team, and they will
            guide you through the hassle-free process of initiating a return or
            exchange.
          </div>
        </div>

        <div>
          <div className="buyBacHeading">Eligibility</div>
          <div className="buyBacText">
            Our BuyBack Program is applicable to all Samasva diamond jewelry
            products, giving you a wide range of choices. Exchange & Returns not
            available on DNA Daimonds.
          </div>
        </div>

        <div>
          <div className="buyBacHeading">No Hidden Costs:</div>
          <div className="buyBacText">
            Participating in the BuyBack Program is straightforward and
            transparent; there are no additional fees or hidden charges.
          </div>
        </div>

        <div>
          <div className="buyBacHeading">Location</div>
          <div className="buyBacText">
            Currently, the program is exclusively available in the USA, making
            it a unique offering in the American jewelry market.
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyBac;
