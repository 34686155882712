import React, { useState, useEffect } from "react";
import "./JewelleryPage1.css";
import { useNavigate } from "react-router-dom";

const JewelleryPage1 = (props) => {
  const { dataToDisplay1 } = props;
  const [compval, setCompval] = useState(false);

  const navigate=useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const handleShopProduct = () =>{
    navigate("/all-product");
    sessionStorage.setItem("productPage", "allProduct");
  }

  return (
    <>
      {compval ? (
        <div className="jw1Div">
          <img
            alt="images"
            className="jw1Img"
            src={dataToDisplay1?.image}
            loading="lazy"
          />
          <div className="jw1Div1">
            <h2 className="jw1Head">{dataToDisplay1?.title}</h2>
            <p className="jw1Text">{dataToDisplay1?.content.replace(/<\/?p>/g, '')}</p>
            <button className="jw1Btn" onClick={()=>{handleShopProduct()}}>Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="jw1Div">
          <img
            alt="images"
            className="jw1Img"
            src={dataToDisplay1?.mobile_image}
            loading="lazy"
          />
          <div className="jw1Div1">
            <h2 className="jw1Head">{dataToDisplay1?.title}</h2>
            <p className="jw1Text">{dataToDisplay1?.content.replace(/<\/?p>/g, '')}</p>
            <button className="jw1Btn">Shop Now</button>
          </div>
        </div>
      )}
    </>
  );
};

export default JewelleryPage1;
