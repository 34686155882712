import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import ShoppingBagPage from "../../components/ShoppingBagPage/ShoppingBagPage";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { useNavigate } from "react-router-dom";
import { getData } from "../HTTPConfig/HTTPConfig";
const ShoppingBag = (props) => {
  const navigate = useNavigate();
  const [guestCart, setGuestCart] = useState([]);
  const [userCart, setUserCart] = useState([]);
  const userToken = sessionStorage.getItem("samsva_token");
  
  

  useEffect(() => {
    const fetchGuestCartDetails = async () => {
      const cartData = await getData("guestCartDetail");
      setGuestCart(cartData?.data);
    };

    const fetchCartDetails = async () => {
      const mainCartData = await getData("showCartDetails");
      setUserCart(mainCartData?.data);
    };

    if (userToken) {
      fetchCartDetails();
    } else {
      fetchGuestCartDetails();
    }
  }, [userToken]);

  useEffect(() => {
    const sendTCart = async () => {
      const response = await getData("guestcarttocart");
      console.log("response", response);
    };

    if (userToken) {
      sendTCart();
    }
  }, [userToken]);

  const handleCheckout = (userCart) => {
    if (userToken) {
      navigate("/customer-details", { state: { userCart: userCart } });
    } else {
      navigate("/login");
      sessionStorage.setItem("samasva_checkout", 1);
    }
  };
  return (
    <>
      <Avdert />
      <Header />
      <ShoppingBagPage
        handleCheckout={handleCheckout}
        guestCart={guestCart}
        userCart={userCart}
      />
      <FooterPage />
    </>
  );
};

export default ShoppingBag;
