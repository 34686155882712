import React, { createRef } from "react";
import "./Home7.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home7 = (props) => {
  const { dataToDisplay } = props;

  const getHome7data = () => {
    return dataToDisplay?.subsection?.map((item, index) => {
      return (
        <div key={index} className="alignItemsDiv">
          <img
            alt="images"
            src={item.image}
            className="home7ContainerImg"
          />
          <div>
            <div className="home7Mhead">{item.title}</div>
            {/* <div className="home7Cre">{item.createdBy}</div> */}
          </div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <>
      <div className="home7Container">
        <div className="home7Heading">{dataToDisplay?.title}</div>
        <Hidden smDown>
          <div className="alignItemsHome7">{getHome7data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome7data()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default Home7;
