import React, { createRef } from "react";
import "./Home2.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

const Home2 = (props) => {
  const { dataToDisplay } = props;
  const navigate = useNavigate();

  const getHome2data = () => {
    return dataToDisplay?.attribute[0]?.valuedata?.map((item, index) => {
      return (
        <div key={index} className="engagement2ImagesDiv" style={{cursor:"pointer"}} onClick={()=>{navigate("/select-diamond")}}>
          <img alt="images" src={item?.attribute_image} className="home2Img" />
          <div className="engagement2Texts">{item?.cust_attr_value}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="home2Container">
        <div className="engagement2heading">{dataToDisplay?.title}</div>
        <Hidden smDown>
          <div className="engagement2Images">{getHome2data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider ref={mySlider} {...settings}>
            {getHome2data()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default Home2;
