import React from "react";
import "./SelectDiamondPage.css";
// import SelectDiamondPage1 from "./SelectDiamondPage1/SelectDiamondPage1";
import DiamondCategory from "./DiamondCategory/DiamondCategory";

const SelectDiamondPage = (props) => {
  const { handleAddRing, diamonds } = props;
  return (
    <>
      <div className="selectDiamondContainer">
        <div className="selectDiamondFirstHeading">Shop by Centre Stone</div>
        <div className="selectDiamondAlignDiv">
          <div className="selectDiamondAlignDivs">Natural Diamond</div>
          <div className="selectDiamondAlignDivs">Lab-Grown Diamond</div>
          <div className="selectDiamondAlignDivs">Memorial Diamond</div>
        </div>
      </div>
      <div>
        {/* <SelectDiamondPage1 /> */}
        <div className="diamondCategoryContainer">
          {diamonds?.map((diamonds, index) => {
            return (
              <DiamondCategory
                diamonds={diamonds}
                index={index}
                handleAddRing={handleAddRing}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SelectDiamondPage;
