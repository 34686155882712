import React, { createRef } from "react";
import "./DiamondPage2.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Hidden } from "@mui/material";

const DiamondPage2 = (props) => {
  const { dataToDisplay2 } = props;

  const getDpData = () => {
    return dataToDisplay2?.subsection.map((item, index) => {
      return (
        <div key={index} className="dp2DataDiv">
          <img alt="images" src={item?.image} className="dp2Img" />
          <div className="dp2DataHead">{item?.title}</div>
          <div className="dp2Desc">{item?.content?.replace(/<\/?p>/g, "")}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();
  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="dp2Container">
        <div className="dp2Head">{dataToDisplay2?.title}</div>
        <div className="dp2text">
          {dataToDisplay2?.content.replace(/<\/?p>/g, "")}
        </div>
      </div>
      <Hidden smDown>
        <div className="dp2Container1">
          <div className="alignDp2Data">{getDpData()}</div>
        </div>
      </Hidden>
      <Hidden smUp>
        <Slider ref={mySlider} {...settings}>
          {getDpData()}
        </Slider>
      </Hidden>
    </>
  );
};

export default DiamondPage2;
