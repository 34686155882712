import React, { useEffect, useState } from "react";
import "./Home8.css";
import AddIcon from "@mui/icons-material/Add";
import { Divider } from "@mui/material";

const Home8 = (props) => {
  const { dataToDisplay } = props;
  const [selectedItems, setSelectedItems] = useState([]);
  const [data, setData]=useState([])
  useEffect(()=>{
    if(dataToDisplay?.subsection?.length<1){
      setData(dataToDisplay?.faq);
    } else{
      setData(dataToDisplay?.subsection);
    }
  },[dataToDisplay]);

  const handleSelect = (index) => {
    const newSelectedItems = [...selectedItems];
    newSelectedItems[index] = !newSelectedItems[index];
    setSelectedItems(newSelectedItems);
  };

  const getHome8Data = () => {
    return data?.map((item, index) => {
      return (
        <div key={index} style={{cursor:"pointer"}}>
          <div className="alignHome8Div" onClick={() => handleSelect(index)}>
            <div className="home8Mhead">{item?.title}</div>
            <div>
              <AddIcon />
            </div>
          </div>
          {selectedItems[index] && <div className="home8Mhead">{item?.content?.replace(/<\/?p>/g, "")}</div>}
          <Divider />
        </div>
      );
    });
  };

  return (
    <>
      <div className="home8Container">
        <div className="home8Head">{dataToDisplay?.title}</div>
        <div className="alignHome8Data">{getHome8Data()}</div>
      </div>
    </>
  );
};

export default Home8;
