import React, { createRef } from "react";
import "./BuyingGuidePage3.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const BuyingGuidePage3 = () => {
  const home2data = [
    { images: "/assets/round 1.svg", imgName: "Rounded" },
    { images: "/assets/oval 1.svg", imgName: "Oval" },
    { images: "/assets/cushion 1.svg", imgName: "Cushion" },
    { images: "/assets/emerald 1.svg", imgName: "Emerald" },
    { images: "/assets/princess 1.svg", imgName: "Princess" },
    { images: "/assets/radiant 1.svg", imgName: "Radiant" },
    { images: "/assets/pear 1.svg", imgName: "Pear" },
    { images: "/assets/marquise 1.svg", imgName: "Marquise" },
    { images: "/assets/asscher 1.svg", imgName: "Asscher" },
    { images: "/assets/heart 1.svg", imgName: "Heart" },
  ];

  const getHome2data = () => {
    return home2data.map((item, index) => {
      return (
        <div className="home1Container2-div">
          <img alt="images" src={item.images} className="hom1Img2" width={"100px"} />
          <div className="home1Container2-text">{item.imgName}</div>
        </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="bg3Container">
        <div className="bg3Head">Diamond Shape</div>

        <Hidden smDown>
          <div className="alignRings">{getHome2data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider className="alignRings" ref={mySlider} {...settings}>
            {getHome2data()}
          </Slider>
        </Hidden>
      </div>
    </>
  );
};

export default BuyingGuidePage3;
