import React from "react";
import "./GemstonePage2.css";
const GemstonePage2 = () => {
  const gp2Data = [
    { imageName: "/assets/image 235.svg", name: "Amethyst" },
    { imageName: "/assets/image 236.svg", name: "White Sapphire" },
    { imageName: "/assets/image 237.svg", name: "Morganite" },
    { imageName: "/assets/image 238.svg", name: "Aquamarine" },
    { imageName: "/assets/image 239.svg", name: "Black Sapphire" },
    { imageName: "/assets/image 240.svg", name: "Ruby" },
    { imageName: "/assets/image 241.svg", name: "Traditional Sapphire" },
    { imageName: "/assets/image 242.svg", name: "Yellow Sapphire" },
    { imageName: "/assets/image 243.svg", name: "Green Sapphire" },
    { imageName: "/assets/image 244.svg", name: "Pink Sapphire" },
  ];

  const getGP2Data = () => {
    return gp2Data.map((data, index) => {
      return (
        <div className="gp2Div">
          <img alt="images" src={data.imageName} className="gp2Img" />
          <div className="gp2Text">{data.name}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="gp2Container">
        <div className="gp2Head">Shop By Gemstone</div>
        <div className="gp2AlignData">{getGP2Data()}</div>
      </div>
    </>
  );
};

export default GemstonePage2;
