import React, { createRef, useEffect, useState } from "react";
import "./GiftPage.css";
import { Hidden } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getData } from "../../userInterface/HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";

const GiftPage = () => {

  const navigate=useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/8");
      if (response?.data) {
        setData(response?.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const dataToDisplay2=data.find((data)=>data.section===2);
  const dataToDisplay3=data.find((data)=>data.section===3);

  const getHome5data = () => {
    return dataToDisplay2?.subsection?.map((item, index) => {
      return (
        <div className="alignHome5ItemsDiv" onClick={()=>{handleCategory(item.title)}} style={{cursor:"pointer"}}>
          <img alt="images" src={item.image} className="home5ContainerImg" />
          <div>
            <div className="home5Mhead">{item.title}</div>
            {/* <div className="home5Cre">{item.createdBy}</div> */}
          </div>
        </div>
      );
    });
  };

  const handleCategory=(title)=>{
    if(title==="Rings"){
      sessionStorage.setItem("productPage", "ring");
      navigate("/all-product");
    } else if(title==="Pendants"){
      sessionStorage.setItem("productPage", "pendant");
      navigate("/all-product");
    } else if(title==="Earrings"){
      sessionStorage.setItem("productPage", "earring");
      navigate("/all-product");
    } else if(title==="Bracelates"){
      sessionStorage.setItem("productPage", "bracelet");
      navigate("/all-product");
    }
  };

  const handleGiftHer=()=>{
    sessionStorage.setItem("productPage","allProduct");
    navigate("/all-product");
  };

  const handleGiftHim=()=>{
    sessionStorage.setItem("productPage","allProduct");
    navigate("/all-product");
  };


  const getHome2data = () => {
    return dataToDisplay3?.subsection?.map((item, index) => {
      return (
        // <div className="giftsPrices">
        <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">{item.title}</div>
            </div>
          </div>
          // </div>
      );
    });
  };

  const mySlider = createRef();

  var settings = {
    dots: false,
    autoplay: 300,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <div className="home5Container">
        <div className="home5Heading">{dataToDisplay2?.title}</div>
        
        <Hidden smDown>
        <div className="alignItemsHome5">{getHome5data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider className="alignItemsHome5" ref={mySlider} {...settings}>
            {getHome5data()}
          </Slider>
        </Hidden>
      </div>

      <div className="giftContainer">
        <div className="giftHead">{dataToDisplay3?.title}</div>
        
        <Hidden smDown>
        <div className="giftsPrices">{getHome2data()}</div>
        </Hidden>
        <Hidden smUp>
          <Slider className="giftsPrices" ref={mySlider} {...settings}>
            {getHome2data()}
          </Slider>
        </Hidden>
      </div>
      {/* <div className="giftContainer">
        <div className="giftHead">Gift By Prices</div>
        <div className="giftsPrices">
          <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">$100</div>
            </div>
          </div>
          <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">$100</div>
            </div>
          </div>
          <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">$100</div>
            </div>
          </div>
          <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">$100</div>
            </div>
          </div>
          <div className="giftDiv">
            <img alt="images" src="/assets/Group 808.png" className="giftImg" />
            <div className="giftText">
              <div className="giftText1">Gifts Under</div>
              <div className="giftText2">$100</div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="giftContainer1">
        <div className="giftContainer1Div" onClick={()=>{handleGiftHer()}} style={{cursor:"pointer"}}>
          <img className="giftImg1" alt="images" src="/assets/image 473.png" />
          <div className="giftContainer1Text">Gifts for her</div>
        </div>
        <div className="giftContainer1Div" onClick={()=>{handleGiftHim()}} style={{cursor:"pointer"}}>
          <img className="giftImg1" alt="images" src="/assets/image 475.png" />
          <div className="giftContainer1Text">Gifts for him</div>
          <div className=""></div>
        </div>
      </div>
    </>
  );
};

export default GiftPage;
