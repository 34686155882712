import React, { useState, useEffect } from "react";
import "./AboutUsPage.css";

import { Hidden } from "@mui/material";
import { useNavigate } from "react-router-dom";
const AboutUsPage = (props) => {
  const { data } = props;
  const [compval, setCompval] = useState(false);

  const navigate= useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const dataToDisplay1 = data.find((data) => data.section === 1);
  const dataToDisplay2 = data.find((data) => data.section === 2);
  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay5 = data.find((data) => data.section === 5);
  const dataToDisplay6 = data.find((data) => data.section === 6);
  const dataToDisplay7 = data.find((data) => data.section === 7);

  return (
    <>
      <div className="auContainer">
        {compval ? (
          <div className="au1Div">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay1?.image}
              loading="lazy"
            />
            <div className="au1Div1">
              <h2 className="au1HeadA">{dataToDisplay1?.title}</h2>
            </div>
          </div>
        ) : (
          <div className="au1Div">
            <img
              alt="images"
              width="100%"
              src={dataToDisplay1?.mobile_image}
              loading="lazy"
            />
            <div className="au1Div1">
              <h2 className="au1HeadA">{dataToDisplay1?.title}</h2>
            </div>
          </div>
        )}
      </div>

      <Hidden smDown>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="images" src={dataToDisplay2?.image} width="100%" />
          <div style={{ position: "absolute", width: "80%" }}>
            <div className="auHeading1">{dataToDisplay2?.title}</div>
            <div className="auText">
              {dataToDisplay2?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img alt="images" src={dataToDisplay2?.mobile_image} width="100%" />
          <div style={{ position: "absolute", width: "80%" }}>
            <div className="auHeading1">{dataToDisplay2?.title}</div>
            <div className="auText">
              {dataToDisplay2?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      </Hidden>

      <div className="auContainer2">
        <div className="auContainer2Div">
          <div className="auContainer2Head">{dataToDisplay3?.title}</div>
          <div className="auContainer2Text">
            {dataToDisplay3?.content.replace(/<\/?p>/g, "")}
          </div>
          <button className="auContainer2Btn" onClick={()=>{navigate("/diamond-dna")}} style={{cursor:"pointer"}}>Shop Now</button>
        </div>
        <div className="auContainer2Div">
          <img alt="images" src={dataToDisplay3?.image} width="100%" />
        </div>
      </div>

      {compval ? (
        <div className="au2Div">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay4?.image}
            loading="lazy"
          />
          <div className="au1Div1">
            <h2 className="au1Head">{dataToDisplay4?.title}</h2>
            <p className="au1Text">
              {dataToDisplay4?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="au1Btn" onClick={()=>{navigate("/diamond-dna")}}>Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="au2Div">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay4?.mobile_image}
            loading="lazy"
          />
          <div className="au2Div1">
            <h2 className="au2Head">{dataToDisplay4?.title}</h2>
            <p className="au2Text">
              {dataToDisplay4?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="au2Btn" onClick={()=>{navigate("/diamond-page")}}>Shop Now</button>
          </div>
        </div>
      )}

      <div className="auContainer2">
        <div className="auContainer2Div">
          <img alt="images" src={dataToDisplay5?.image} width="100%" />
        </div>
        <div className="auContainer2Div">
          <div className="auContainer2Head">{dataToDisplay5?.title}</div>
          <div className="auContainer2Text">
            {dataToDisplay5?.content.replace(/<\/?p>/g, "")}
          </div>
          <button className="auContainer2Btn">Shop Now</button>
        </div>
      </div>

      {compval ? (
        <div className="au3Div">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.image}
            loading="lazy"
          />
          <div className="au3Div1">
            <h2 className="au1Head">{dataToDisplay6?.title}</h2>
            <p className="au1Text">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="au1Btn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="au2Div">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay6?.mobile_image}
            loading="lazy"
          />
          <div className="au2Div1">
            <h2 className="au2Head">{dataToDisplay6?.title}</h2>
            <p className="au2Text">
              {dataToDisplay6?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="au2Btn">Shop Now</button>
          </div>
        </div>
      )}

      <div className="auContainer2">
        <div className="auContainer2Div">
          <img alt="images" src={dataToDisplay7?.image} width="100%" />
        </div>
        <div className="auContainer2Div">
          <div className="auContainer2Head">{dataToDisplay7?.title}</div>
          <div className="auContainer2Text">
            {dataToDisplay7?.content.substring(0, 291).replace(/<\/?p>/g, "")}
          </div>
          <div className="auContainer2Text">
          {dataToDisplay7?.content.substring(292, 500).replace(/<\/?p>/g, "")}
          </div>
          <button className="auContainer2Btn">Shop Now</button>
        </div>
      </div>
    </>
  );
};

export default AboutUsPage;
