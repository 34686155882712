import React, { useState, useEffect } from "react";
import "./WeddingRings3.css";

const WeddingRings3 = (props) => {
  const { dataToDisplay4 } = props;
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  return (
    <>
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay4?.image}
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">{dataToDisplay4?.title}</h2>
            <p className="homeText">
              {dataToDisplay4?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay4?.mobile_image}
            loading="lazy"
          />
          <div className="wrDiv12">
            <h2 className="homeHead">{dataToDisplay4?.title}</h2>
            <p className="homeText">
              {dataToDisplay4?.content.replace(/<\/?p>/g, "")}
            </p>
            <button className="homeBtn">Shop Now</button>
          </div>
        </div>
      )}
    </>
  );
};

export default WeddingRings3;
