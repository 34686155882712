import React from "react";
import "./SelectDiamond1C4.css";

const SelectDiamond1C4 = () => {
  const colorArr = [
    "0.25",
    "0.5",
    "0.75",
    "1",
    "1.25",
    "1.5",
    "1.75",
    "2",
    "3"
  ];

  const getColorArr = () => {
    return colorArr.map((item) => {
      return <div className="sd2Divs">{item}</div>;
    });
  };

  return (
    <>
      <div className="sd4Container">
        <div className="sd2Head">Carat</div>
        <div className="sd2AlignDiv">{getColorArr()}</div>
      </div>
    </>
  );
};

export default SelectDiamond1C4;
