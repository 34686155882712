import React, { useState, useEffect, Fragment } from "react";
import { Drawer } from "@mui/material";
import "./HeaderDrawer.css";
import { useNavigate } from "react-router-dom";
const HeaderDrawer1 = (props) => {
  const { handleADrawer, openDrawer, rStatus, jStatus } = props;

  const navigate=useNavigate();

  const [state, setState] = useState({
    top: false,
    left: false,
    right: false,
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleADrawer(open);
    setState((prevState) => ({
      ...prevState,
      right: open,
    }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      right: openDrawer,
    }));
  }, [openDrawer]);

  const handlClose = (item) => {
    setState((prevState) => ({
      ...prevState,
      right: false,
    }));
    handleADrawer(toggleDrawer("right", false));
  };

  const list = (anchor) => {
    return (
      <>
        <div onClick={handlClose} className="headerDText1">
          Back
        </div>
        {rStatus && (
          <div className="hdrawer1">
            <div className="headerContainer-div-div">Rings</div>
            <div className="headerDrawerAlignDiv">
              <div className="headerDialogAlignDiv1">
                <img alt="images" src="/assets/h1.png" width="16px" />
                <div className="headerDText" onClick={()=>{navigate("/select-diamond")}}>Start with an Aura Diamond</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <img alt="images" src="/assets/h2.png" width="16px" />
                <div className="headerDText" onClick={()=>{navigate("/select-setting")}}>Start with a setting</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <img alt="images" src="/assets/h3.png" width="16px" />
                <div className="headerDText" onClick={()=>{navigate("/diamond-dna")}}>Start with a DNA Diamond</div>
              </div>
            </div>
            <div className="headerDrawerAlignDiv">
              <div className="headerDHeading">Shop By Metal</div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{
                    backgroundColor: "#EFEFEF",
                    height: "12px",
                    width: "12px",
                  }}
                ></div>
                <div className="headerDText">White Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{
                    backgroundColor: "#E9D590",
                    height: "12px",
                    width: "12px",
                  }}
                ></div>
                <div className="headerDText">Yellow Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{
                    backgroundColor: "#F5C8A9",
                    height: "12px",
                    width: "12px",
                  }}
                ></div>
                <div className="headerDText">Rose Gold</div>
              </div>
              <div className="headerDialogAlignDiv1">
                <div
                  style={{
                    backgroundColor: "#C8C8C8",
                    height: "12px",
                    width: "12px",
                  }}
                ></div>
                <div className="headerDText">Platinum</div>
              </div>
            </div>
            <div>
              <div className="headerDHeading">Shop By Shape</div>
              <div className="headerDAlignDivx">
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h4.png" width="32px" />
                  <div className="headerDText">Round</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h5.png" width="32px" />
                  <div className="headerDText">Princess</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h6.png" width="32px" />
                  <div className="headerDText">Cushion</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h7.png" width="32px" />
                  <div className="headerDText">Oval</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h8.png" width="32px" />
                  <div className="headerDText">Pear</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h9.png" width="32px" />
                  <div className="headerDText">Emerald</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h10.png" width="32px" />
                  <div className="headerDText">Heart</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h11.png" width="32px" />
                  <div className="headerDText">Raddiant</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h12.png" width="32px" />
                  <div className="headerDText">Asscher</div>
                </div>
                <div className="headerDialogAlignDiv1">
                  <img alt="images" src="/assets/h13.png" width="32px" />
                  <div className="headerDText">Marquise</div>
                </div>
              </div>
            </div>
            <div style={{ padding: "0px 0px 8px 0px" }}>
              <div className="headerDHeading">Shop By Style</div>
              <div className="headerDialogAlignDiv">
                <div className="headerDText">Solitaire</div>
                <div className="headerDText">Halo</div>
                <div className="headerDText">Pave</div>
                <div className="headerDText">Three Stone</div>
                <div className="headerDText">Vintage</div>
                <div className="headerDText">Infinity</div>
                <div className="headerDText">Matching Sets</div>
              </div>
            </div>
          </div>
        )}

        {jStatus && (
          <div className="hdrawer1">
            <div className="headerContainer-div-div">Jewellery</div>
            <div
              style={{
                padding: "8px 0px 0px 0px",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
              }}
            >
              <div className="headerDHeading1" onClick={()=>{navigate("/rings")}}>Rings</div>
              <div className="headerDHeading1" onClick={()=>{navigate("/ear-rings")}}>Earrings</div>
              <div className="headerDHeading1" onClick={()=>{navigate("/necklace")}}>NeckLace</div>
              <div className="headerDHeading1" onClick={()=>{navigate("/bracelete")}}>Braceletes</div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <Fragment key={"right"}>
        <Drawer
          elevation={3}
          PaperProps={{
            sx: {
              width: "75%",
              backgroundColor: "#ffffff",
            },
          }}
          anchor={"right"}
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </Fragment>
    </>
  );
};

export default HeaderDrawer1;
