import React, { useEffect, useState } from "react";
import "./ThankYou.css";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import { postData } from "../HTTPConfig/HTTPConfig";
import { useNavigate } from "react-router-dom";
const ThankYou = () => {
  const navigate = useNavigate();
  const msn = localStorage.getItem("msnArray");
  const sessionId = localStorage.getItem("checkout_session_id");

  const [paymentError, setPaymentError] = useState(null);

  useEffect(() => {
    const handleSuccess = async () => {
      const formData = new FormData();
      formData.append("msnArray", msn);
      formData.append("modeOfPayment", "prepaid");
      formData.append("checkout_session_id", sessionId);
      const response = await postData("order/payment/paymentAdd", formData);
      if(!response || response.error){
        setPaymentError(true);
      }
    };
    if (sessionId) {
      handleSuccess();
    }
  }, [msn, sessionId]);

  const handleHomePage = () => {
    navigate("/");
  };

  return (
    <>
      <Avdert />
      <Header />
      {!paymentError?(<div className="thnkContainer">
        <img
          alt="gif"
          src="/assets/animation.gif"
          width="266px"
          height="353px"
        />
        <div className="thnk1">Your order has been placed successfully!</div>
        <div className="thnk2">
          Thank you for choosing Samasva, Your order will be generated based on
          your delivery request.
        </div>
        <div className="thnk3">the Receipt has been sent to your email.</div>
        <button onClick={handleHomePage} className="thnkBtn">
          Go to Homepage
        </button>
      </div>):<div className="thnkContainer">
      <img
          alt="gif"
          src="/assets/wired-flat-1140-error.gif"
          width="266px"
          height="353px"
        />
        <div className="thnk1">Something went wrong!</div>
        <div className="thnk2">For any further assistance, please contact our customer support.</div>
        </div>}
    </>
  );
};

export default ThankYou;
