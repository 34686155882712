import React from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import ProfilePage from "../../components/ProfilePage/ProfilePage";

const Profile = () => {
  return (
    <>
      <Avdert />
      <Header />
      <ProfilePage />
    </>
  );
};

export default Profile;
