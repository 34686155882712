import React, { Fragment, useState, useEffect } from "react";
import { Drawer, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import "./HeaderDrawer.css";
import HeaderDrawer1 from "./HeaderDrawer1";
import { useNavigate } from "react-router-dom";
const HeaderDrawer = (props) => {
  const navigate=useNavigate();
  const { handleDrawerOpen, drawerOpen } = props;
  const [openDrawer, setOpenDrawer] = useState(false);

  const [rStatus, setRStatus] = useState(false);
  const [jStatus, setJStatus] = useState(false);
  // const [dStatus, setDStatus] = useState(false);
  // const [gStatus, setGStatus] = useState(false);
  // const [aStatus, setAStatus] = useState(false);
  // const [bStatus, setBStatus] = useState(false);

  const [state, setState] = useState({
    top: false,
    left: false,
    right: false,
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    handleDrawerOpen(open);
    setState((prevState) => ({
      ...prevState,
      right: open,
    }));
  };

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      right: drawerOpen,
    }));
  }, [drawerOpen]);

  const handlClose = (item) => {
    setState((prevState) => ({
      ...prevState,
      right: false,
    }));
    handleDrawerOpen(toggleDrawer("right", false));
  };

  const handleADrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleEngagementRing = () => {
    setOpenDrawer(!openDrawer);
    setRStatus(true);
    setJStatus(false);
  };

  const handleJewellery = () => {
    setOpenDrawer(!openDrawer);
    setJStatus(true);
    setRStatus(false);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleCart = () => {
    navigate("/shopping-bag");
  };

  const list = (anchor) => {
    return (
      <>
        <div className="hdrawer">
          <CloseIcon
            onClick={handlClose}
            fontSize="small"
            sx={{ position: "absolute", right: "8px", top: "4px" }}
          />
          <div style={{ padding: "16px 0px 0px 0px" }}>
            <div onClick={handleEngagementRing} className="drawer-align ">
              <div className="headerContainer-div-div">Rings</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div onClick={handleJewellery} className="drawer-align ">
              <div className="headerContainer-div-div">Fine Jewellery</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div className="drawer-align ">
              <div className="headerContainer-div-div" onClick={()=>{navigate("/diamond-page")}}>Aura Diamonds</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div className="drawer-align ">
              <div className="headerContainer-div-div" onClick={()=>{navigate("/gifts")}}>Gifts</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div className="drawer-align ">
              <div className="headerContainer-div-div" onClick={()=>{navigate("/buying-guide")}}>Buying Guide</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div className="drawer-align ">
              <div className="headerContainer-div-div" onClick={()=>{navigate("/about-us")}}>About Us</div>
              <div>
                <KeyboardArrowRightIcon />
              </div>
            </div>
            <Divider />
            <div className="headerAlignIconsHD">
            <img
              alt="images"
              src="/assets/user.svg"
              className="userIcon"
              onClick={()=>{if(sessionStorage.getItem("samsva_token"))
              {navigate("/my-profile")}
            else{
              handleLogin();
            }}}
            />
            <img
              alt="images"
              src="/assets/cart.svg"
              className="userIcon"
              onClick={handleCart}
            />
          </div>
          </div>
        </div>
        <HeaderDrawer1
          handleADrawer={handleADrawer}
          openDrawer={openDrawer}
          rStatus={rStatus}
          jStatus={jStatus}
          // dStatus={dStatus}
          // aStatus={aStatus}
          // bStatus={bStatus}
        />
      </>
    );
  };

  return (
    <>
      <Fragment key={"right"}>
        <Drawer
          elevation={3}
          PaperProps={{
            sx: {
              width: "75%",
              backgroundColor: "#ffffff",
            },
          }}
          anchor={"right"}
          open={state.right}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </Fragment>
    </>
  );
};

export default HeaderDrawer;
