import React, { useState, useEffect } from "react";
import Avdert from "../Avdert/Avdert";
import Header from "../Header/Header";
import MyAddresses from "../../components/MyAddresses/MyAddresses";
import { getData } from "../HTTPConfig/HTTPConfig";

const Addresses = (props) => {
  const userToken = sessionStorage.getItem("samsva_token");
  const [addresses, setAddresses] = useState([]);

  useEffect(() => {
    const fetchAllAddress = async () => {
      const response = await getData("showAddress");
      setAddresses(response.data);
    };
    if (userToken) {
      fetchAllAddress();
    }
  }, [userToken]);
  return (
    <>
      <Avdert />
      <Header />
      <MyAddresses addresses={addresses} />
    </>
  );
};

export default Addresses;
