import React, { useEffect, useState } from "react";
import "./Home6.css";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import image2 from "./Home6Images/Frame 15.png";
import image3 from "./Home6Images/Frame 14.png";
import image4 from "./Home6Images/Frame 13.png";
import image2m from "./Home6Images/Frame 26.png";
import image3m from "./Home6Images/Frame 27.png";
import image4m from "./Home6Images/Frame 28.png";

const Home6 = (props) => {
  const { dataToDisplay } = props;

  const navigate=useNavigate();
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleShopBtn=()=>{
    sessionStorage.removeItem("msn");
    sessionStorage.removeItem("prtid");
    sessionStorage.removeItem("cPage");
    navigate("/select-setting");
  }

  return (
    <>
      <div className="home6Container">
        {compval ? (
          <Slider {...sliderSettings}>
          <div className="hom6Div">
            <img
              alt="images"
              src={dataToDisplay?.image}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1">
              <h1 className="home6Head">{dataToDisplay?.title}</h1>
              <p className="home6Text">{dataToDisplay?.content.replace(/<\/?p>/g, '')}</p>
              <button className="hom6Btn" onClick={()=>{handleShopBtn()}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Div">
            <img
              alt="images"
              src={image2}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1">
              <h1 className="home6Head">DNA Diamonds</h1>
              <p className="home6Text">In every shine of a DNA diamond, a cherished story unfolds—a tribute of love, grace, and irreplaceable moments. Let their essence adorn you, a timeless reminder of love that never fades.</p>
              <button className="hom6Btn" onClick={()=>{navigate("/diamond-dna")}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Div">
            <img
              alt="images"
              src={image3}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1">
              <h1 className="home6Head">Aura Diamonds</h1>
              <p className="home6Text">Elevate your energy with our Cosmic Harmony Gems, thoughtfully aligned with the stars. Embrace positivity and balance with each radiant gem.</p>
              <button className="hom6Btn" onClick={()=>{navigate("/diamond-page")}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Divlast">
            <img
              alt="images"
              src={image4}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1last">
              <h1 className="home6Headlast">Embrace Your Shine</h1>
              <p className="home6Textlast">Discover handcrafted jewellery at Samasva that celebrates your unique brilliance. Our collections inspire you to embrace your inner light & shine in every moment.</p>
              <div className="hom6Btnlast" onClick={()=>{navigate("/about-us")}}>About Us</div>
            </div>
          </div>
          </Slider>
        ) : (
          <Slider {...sliderSettings}>
          <div className="hom6Div">
            <img
              alt="images"
              src={dataToDisplay?.mobile_image}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1">
              <h1 className="home6Head">{dataToDisplay?.title}</h1>
              <p className="home6Text">{dataToDisplay?.content.replace(/<\/?p>/g, '')}</p>
              <button className="hom6Btn" onClick={()=>{navigate("/select-setting")}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Divm">
            <img
              alt="images"
              src={image2m}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1m">
              <h1 className="home6Headm">Aura Diamonds</h1>
              <p className="home6Textm">Elevate your energy with our Cosmic Harmony Gems, thoughtfully aligned with the stars. Embrace positivity and balance with each radiant gem.</p>
              <button className="hom6Btnm" onClick={()=>{navigate("/diamond-page")}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Divm">
            <img
              alt="images"
              src={image4m}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1m">
              <h1 className="home6Headm">DNA Diamonds</h1>
              <p className="home6Textm">In every shine of a DNA diamond, a cherished story unfolds—a tribute of love, grace, and irreplaceable moments. Let their essence adorn you, a timeless reminder of love that never fades.</p>
              <button className="hom6Btnm" onClick={()=>{navigate("/diamond-dna")}}>Shop Now</button>
            </div>
          </div>

          <div className="hom6Divm">
            <img
              alt="images"
              src={image3m}
              className="home6Img"
              loading="lazy"
            />
            <div className="hom6Div1m">
              <h1 className="home6Headm">Embrace Your Shine</h1>
              <p className="home6Textm">Discover handcrafted jewellery at Samasva that celebrates your unique brilliance. Our collections inspire you to embrace your inner light & shine in every moment.</p>
              <button className="hom6Btnm" onClick={()=>{navigate("/about-us")}}>About Us</button>
            </div>
          </div>
          </Slider>
        )}
      </div>
    </>
  );
};

export default Home6;
