import React, { useEffect, useState } from "react";
import "./Gifts.css";
import Header from "../Header/Header";
import GiftPage from "../../components/GiftPage/GiftPage";
import Home7 from "../../components/HomePage/Home7/Home7";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { getData } from "../HTTPConfig/HTTPConfig";
import { Dialog } from "@mui/material";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog";
import { useNavigate } from "react-router-dom";
const Gifts = () => {
  const [compval, setCompval] = useState(false);

  const navigate=useNavigate();

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/8");
      if (response?.data) {
        setData(response?.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const handleShopClick=()=>{
    sessionStorage.setItem("productPage", "allProduct");
    navigate("/all-product");
  }

  const dataToDisplay1 = data.find((data) => data.section === 1);

  return (
    <>
      <Avdert />
      <Header />
      {compval ? (
        <div className="giftContainerAbc">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay1?.static_image}
            loading="lazy"
          />
          <div className="giftContainerAbc1">
            <div className="giftContainerAbcHead">{dataToDisplay1?.title}</div>
            <div className="giftContainerAbcText">
              {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <button className="giftContainerAbcbtn"  onClick={()=>{handleShopClick()}} style={{cursor:"pointer"}}>Shop Now</button>
          </div>
        </div>
      ) : (
        <div className="giftContainerAbc">
          <img
            alt="images"
            width="100%"
            src={dataToDisplay1?.mobile_image}
            loading="lazy"
          />
          <div className="giftContainerAbc1">
            <div className="giftContainerAbcHead">{dataToDisplay1?.title}</div>
            <div className="giftContainerAbcText">
              {dataToDisplay1?.content?.replace(/<\/?p>/g, "")}
            </div>
            <button className="giftContainerAbcbtn" onClick={()=>{handleShopClick()}}>Shop Now</button>
          </div>
        </div>
      )}
      <GiftPage />
      <Home7 />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtn"
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button
              className="homeBtn"
              onClick={() => {
                handleDialog();
              }}>
              Consult
            </button>
          </div>
        </div>
      )}
      <Home8 />
      <Home10 />
      <FooterPage />

      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default Gifts;
