import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import HomePage from "../../components/HomePage/HomePage";
import Avdert from "../Avdert/Avdert";
import FooterPage from "../../components/FooterPage/FooterPage";
import { getData } from "../HTTPConfig/HTTPConfig";
const Home = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/1");
      if (response?.data) {
        setData(response?.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  return (
    <>
      <Avdert />
      <Header />
      <HomePage data={data} />
      <FooterPage />
    </>
  );
};

export default Home;
