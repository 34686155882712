import React, { useEffect, useState } from "react";
import "./DiamondPage3.css";

const DiamondPage3 = (props) => {
  const { dataToDisplay4 } = props;
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  return (
    <>
      <div className="dp3Container1">
        {compval ? (
          <div className="dp3Div">
            <img alt="images" src={dataToDisplay4?.image} className="dp1Img" />
            <div className="dp3Div1">
              <h1 className="dp3Head">{dataToDisplay4?.title}</h1>
              {dataToDisplay4?.subsection.map((item, index) => {
                return (
                  <div key={index}>
                    <h2 className="dp3Head1">{item.title}</h2>
                    <p className="dp3Text1">
                      {item.content.replace(/<\/?p>/g, "")}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className="dp3Div">
            <img
              alt="images"
              src={dataToDisplay4?.mobile_image}
              className="dp1Img"
            />
            <div className="dp3Div1">
              <h1 className="dp3Head">{dataToDisplay4?.title}</h1>
              {dataToDisplay4?.subsection.map((item, index) => {
                return (
                  <div>
                    <h2 className="dp3Head1">{item.title}</h2>
                    <p className="dp3Text1">
                      {item.content.replace(/<\/?p>/g, "")}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DiamondPage3;
