import React, { useEffect, useState } from 'react'
import { postData } from '../HTTPConfig/HTTPConfig';
import { useNavigate } from 'react-router-dom';
import './Register.css';

const Register = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

    const check = sessionStorage.getItem("samasva_checkout");

    const handleRegister = async () => {
        const formData = new FormData();
        formData.append("name", `${firstName} ${lastName}`);
        formData.append("email", email);
        const response = await postData("register", formData);
        console.log("login", response);
        sessionStorage.setItem("samsva_token", response?.data?.token);
        if (check) {
            navigate("/shopping-bag");
        } else {
            navigate("/");
        }

    };
    const [compval, setCompval] = useState(false);

    useEffect(() => {
      if (window.innerWidth > 430) {
        setCompval(true);
      }
    }, []);
    return (
        <>
            <div className="loginContainerR">
                {compval?(<img alt="images" src="/assets/Group 211001912.svg" width="100%" />):(<img alt="images" src="/assets/Frame 15.png" width="100%" />)}
                <div className="loginContainerDivR">
                    <div className="loginHeadR">
                        <img
                            style={{ display: "flex", textAlign: "center" }}
                            alt="images"
                            src="/assets/MainLogo.svg"
                            width="64px"
                        />
                        <div>Register</div>
                    </div>
                    <div>
                            <div className="aliggnTBRR">
                                <input
                                    onChange={(e) => setFirstName(e.target.value)}
                                    className="registerInputR"
                                    type="text"
                                    placeholder='First Name'
                                    value={firstName}
                                    required
                                />
                            </div>
                            <div className="aliggnTBRR">
                                <input
                                    onChange={(e) => setLastName(e.target.value)}
                                    className="registerInputR"
                                    type="text"
                                    placeholder='Last Name'
                                    value={lastName}
                                    required
                                />
                            </div>
                            <div className="aliggnTBRR">
                                <input
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="registerInputR"
                                    type="email"
                                    placeholder='Email'
                                    value={email}
                                    required
                                />
                            </div>
                            <button className="registerBTNR" onClick={()=>{handleRegister()}}>
                                Submit
                            </button>
                        <div className='registerPrivacyTextR'>Your privacy is important to us. By Clicking “Create Account”, you agree to our Terms and Privacy Policy.</div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Register