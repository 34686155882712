import React from "react";
import "./WeddingRings2.css";

const WeddingRings2 = (props) => {
  const { dataToDisplay2, dataToDisplay3 } = props;
  return (
    <>
      <div className="wr2Container">
        <div className="wr2Div">
          <div className="wr2Divs">
            <div className="wr2head">{dataToDisplay2?.title}</div>
            <div className="wr2texts">
              {dataToDisplay2?.content.replace(/<\/?p>/g, "")}
            </div>
            <button className="wr2Btn">Shop Now</button>
          </div>
          <div className="wr2Divs">
            <img
              alt="images"
              src={dataToDisplay2?.image}
              loading="lazy"
              className="wr2Img"
            />
          </div>
        </div>
        <div className="wr2Div">
          <div className="wr2Divs">
            <img
              alt="images"
              src={dataToDisplay3?.image}
              loading="lazy"
              className="wr2Img"
            />
          </div>
          <div className="wr2Divs">
            <div className="wr2head">{dataToDisplay2?.title}</div>
            <div className="wr2texts">
              {dataToDisplay3?.content.replace(/<\/?p>/g, "")}
            </div>
            <button className="wr2Btn">Shop Now</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeddingRings2;
