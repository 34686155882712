import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import EarRingsPage from "../../components/EarRingsPage/EarRingsPage.jsx";
import Home7 from "../../components/HomePage/Home7/Home7";
import Home8 from "../../components/HomePage/Home8/Home8";
import Home10 from "../../components/HomePage/Home10/Home10";
import FooterPage from "../../components/FooterPage/FooterPage";
import Avdert from "../Avdert/Avdert";
import { Dialog, Hidden } from "@mui/material";
import { getData } from "../HTTPConfig/HTTPConfig.jsx";
import ContactUsDialog from "../../components/ContactUsDialog/ContactUsDialog.jsx";
const EarRings = () => {
  const [compval, setCompval] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 430) {
      setCompval(true);
    }
  }, []);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchHomePageData = async () => {
      const response = await getData("page/content/9");
      if (response.data) {
        setData(response.data);
      } else {
        return null;
      }
    };
    fetchHomePageData();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);
  const handleDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    window.location.reload();
  };

  const dataToDisplay3 = data.find((data) => data.section === 3);
  const dataToDisplay4 = data.find((data) => data.section === 4);
  const dataToDisplay10 = data.find((data) => data.section === 5);
  return (
    <>
      <Avdert />
      <Header />
      <EarRingsPage />

      <Hidden smDown>
        <div className="rings1ContainerAb">
          <img
            alt="images"
            src={dataToDisplay3?.static_image}
            loading="lazy"
            width="100%"
          />
          <div className="rings1ContainerAb1">
            <div className="rings1ContainerAb1Head">
              {dataToDisplay3?.title}
            </div>
            <div className="rings1ContainerAb1Text">
              {dataToDisplay3?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      </Hidden>
      <Hidden smUp>
        <div className="rings1ContainerAb">
          <img
            alt="images"
            src="/assets/Group 211001965.png"
            loading="lazy"
            width="100%"
          />
          <div className="rings1ContainerAb1">
            <div className="rings1ContainerAb1Head">
            {dataToDisplay3?.title}
            </div>
            <div className="rings1ContainerAb1Text">
            {dataToDisplay3?.content.replace(/<\/?p>/g, "")}
            </div>
          </div>
        </div>
      </Hidden>
      <Home7 dataToDisplay={dataToDisplay4} />
      {compval ? (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/home3.webp"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      ) : (
        <div className="homeDiv">
          <img
            alt="images"
            width="100%"
            src="/assets/Group 1000002162.png"
            loading="lazy"
          />
          <div className="homeDiv12">
            <h2 className="homeHead">Consult with Our Expert</h2>
            <p className="homeText">
              Seek personalized guidance and curated selections to help you find
              the perfect piece that reflects your unique style and story.
            </p>
            <button className="homeBtn" onClick={() => {
                  handleDialog();
                }}>Consult</button>
          </div>
        </div>
      )}
      <Home8 dataToDisplay={dataToDisplay10} />
      <Home10 />
      <FooterPage />
      {compval ? (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth / 2,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      ) : (
        <Dialog
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                padding: "16px",
                width: "100%",
                maxWidth: window.innerWidth,
              },
            },
          }}
          open={openDialog}>
          <ContactUsDialog handleCloseDialog={handleCloseDialog} />
        </Dialog>
      )}
    </>
  );
};

export default EarRings;
